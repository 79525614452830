export const environment = {
    production: true,
    serverUrl: 'https://bot.micromate.ai',
    learnUrl: 'https://learn.micromate.ai',
    manageUrl: 'https://manage.micromate.ai',
    websocket: 'https://bot.micromate.ai',
    websocketpath: '/websocket/socket.io',
    profileUrl: 'https://learn.micromate.ai',
    zitadelClientId: '185156607838060801@micromate',
    iamCodeExchangeUrl: 'https://learn.micromate.ai/code-exchange',
    iamLogoutUrl: 'https://learn.micromate.ai/learn',
    authErrorUrl: 'https://learn.micromate.ai/auth-error',
    zitadelProjectId: '122147021183311927',
    msTeamsBotId: '4112cc9a-d683-4ffd-b0bc-2013af7c7497',
    profileTabsUrl: 'profile.micromate.ai'
};
