<div [style.background-image]="'url(' + createAdaptiveCardBackendImage() + ')'"
     class="pa-message-content micromate-message-content micromate-message-content-reflection">

    <mm-quiz-question (mediaLoaded)="mediaLoaded()"
                      [additionalMessage]="responseItemData.text.reflectionIntroductionMessage"
                      [questionStaticText]="responseItemData.text"
                      [quizNumber]="responseItemData.learningSessionData.quizNumber"
                      [quizQuestion]="responseItemData.quiz.quiz"
                      [showFeedback]="isDeactivated === true && responseItemData.quiz.id !== undefined"
                      [questionId]="responseItemData.quiz.id"
                      [learningPackageId]="responseItemData.learningPackageTranslationId"
                      class="app-quiz-question app-quiz-question-background-text-margin"></mm-quiz-question>

    <div *ngIf="!isDeactivated">
        <div [innerHTML]="responseItemData.text.hintMessage | emoji" class="micromate-quiz-hint">
        </div>
        <div [innerHTML]="responseItemData.text.lengthHintMessage | emoji" class="micromate-quiz-hint">
        </div>

        <div (click)="skipQuestion()"
             class="micromate-question-button">
            {{responseItemData.text.skipButtonText}}
        </div>
    </div>

</div>
