import {Injectable} from '@angular/core';
import {marked, Renderer, Tokens} from 'marked';

@Injectable({
    providedIn: 'root'
})
export class MarkdownService {

    private readonly renderer: Renderer;

    constructor() {
        this.renderer = new marked.Renderer();
        this.renderer.link = (data: Tokens.Link): string => {
            return `<a href="${data.href}" target="_blank" title="${data.title ?? data.text}">${data.text}</a>`;
        };
    }

    public renderMarkdown(value: string, removeEnclosingParagraph: boolean = false): string {
        if (value === undefined || value.length === 0) {
            return value;
        }

        const rendered = marked(value, {renderer: this.renderer, async: false}) as string;
        if (removeEnclosingParagraph) {
            return rendered.replace('<p>', '').replace('</p>', '').trim();
        }
        return rendered.trim();
    }

}
