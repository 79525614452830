import {Injectable} from '@angular/core';
import {LocaleResource} from './locale-resource.service';
import {LocaleResponseDto} from './localeResponseDto';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    private static all: Promise<LocaleResponseDto[]>;
    private static supported: Promise<LocaleResponseDto[]>;

    constructor(private localeResource: LocaleResource) {
        LocaleService.all = this.localeResource.getAll();
        LocaleService.supported = this.localeResource.getSupported();
    }

    public getAll(): Promise<LocaleResponseDto[]> {
        return LocaleService.all;
    }

    public getSupported(): Promise<LocaleResponseDto[]> {
        return LocaleService.supported;
    }

    public async getBestMatchingForCurrentUser(userLocale: string): Promise<string> {
        const bestMatching = await this.localeResource.getBestMatching(userLocale);
        return bestMatching.locale;
    }
}


