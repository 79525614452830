import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {GlobalErrorHandler} from './core/globalErrorHandler/globalErrorHandler';
import {BotatoAngularLibModule, botatoBaseConfig, BotatoConfig, InputDataService} from 'botato-angular-lib';
import {environment} from '../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LearningComponent} from './learning/learning/learning.component';
import {NavigationComponent} from './learning/components/navigation/navigation.component';
import {ProfileScoreboardComponent} from './profile-pages/profile-scoreboard/profile-scoreboard.component';
import {ProfileStudyProgressComponent} from './profile-pages/profile-study-progress/profile-study-progress.component';
import {ProfileBadgeComponent} from './profile-pages/profile-badge/profile-badge.component';
import {LearningBadgeComponent} from './learning/learning-badge/learning-badge.component';
import {LearningScoreboardComponent} from './learning/learning-scoreboard/learning-scoreboard.component';
import {LearningStudyProgressComponent} from './learning/learning-study-progress/learning-study-progress.component';
import {
    ProfileMediaPresentationComponent
} from './profile-pages/profile-media-presentation/profile-media-presentation.component';
import {ChatbotComponent} from '../../../micromate-learn-lib/src/lib/components/chatbot/chatbot.component';
import {
    LearningProgressVisualizationComponent
} from '../../../micromate-learn-lib/src/lib/components/study-progress/learning-progress-visualization/learning-progress-visualization.component';
import {
    LearningPackageIntroductionComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/learning-package-introduction/learning-package-introduction.component';
import {
    IframeMediaComponent
} from '../../../micromate-learn-lib/src/lib/components/media/iframe-media/iframe-media.component';
import {
    MultipleTextChoiceQuizComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/standard-question/multiple-text-choice-quiz/multiple-text-choice-quiz.component';
import {
    BagdeComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/bagde/bagde.component';
import {
    QuestionsVisualizationComponent
} from '../../../micromate-learn-lib/src/lib/components/study-progress/questions-visualization/questions-visualization.component';
import {
    LearningPackageInfoComponent
} from '../../../micromate-learn-lib/src/lib/components/study-progress/learning-package-info/learning-package-info.component';
import {
    TrueFalseQuizComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/standard-question/true-false-quiz/true-false-quiz.component';
import {
    FreeTextQuizComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/standard-question/free-text-quiz/free-text-quiz.component';
import {RankingComponent} from '../../../micromate-learn-lib/src/lib/components/ranking/ranking.component';
import {
    SingleTextChoiceQuizComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/standard-question/single-text-choice-quiz/single-text-choice-quiz.component';
import {
    StudyProgressComponent
} from '../../../micromate-learn-lib/src/lib/components/study-progress/study-progress.component';
import {ProfileComponent} from '../../../micromate-learn-lib/src/lib/components/profile/profile.component';
import {HelpComponent} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/help/help.component';
import {
    QuizMediaComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/components/quiz-media/quiz-media.component';
import {
    ReflectionFreeTextCarouselExplanationComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/reflection-question/reflection-free-text-catousel-explanation/reflection-free-text-carousel-explanation.component';
import {
    ExplanationCardComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/explanation-card/explanation-card.component';
import {
    QuizQuestionComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/components/quiz-question/quiz-question.component';
import {MediaComponent} from '../../../micromate-learn-lib/src/lib/components/media/media.component';
import {
    ReflectionFreeTextQuizComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/reflection-question/reflection-free-text-quiz/reflection-free-text-quiz.component';
import {EnvironmentService} from '../../../micromate-learn-lib/src/lib/services/EnvironmentService';
import {EmojiPipe} from '../../../micromate-learn-lib/src/lib/components/chatbot/emoji.pipe';
import {
    LearnMicromateMessageResolverServiceService
} from '../../../micromate-learn-lib/src/lib/services/learn-micromate-message-resolver-service.service';
import {LocalstorageService} from '../../../micromate-learn-lib/src/lib/services/localstorage.service';
import {TAB_NAVIGATION_SERVICE} from '../../../micromate-learn-lib/src/lib/services/TabNavigationService';
import {NavigationService} from './core/navigation.service';
import {SafePipe} from '../../../micromate-learn-lib/src/lib/components/safe.pipe';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';
import {LearnAuthService} from './core/authentication/learn-auth.service';
import {MarkdownPipe} from '../../../micromate-learn-lib/src/lib/components/markdown.pipe';
import {LearningContainerComponent} from './learning/components/learning-container/learning-container.component';
import {
    KnowledgeQuestionAnswerCardComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/knowledgeQuestionAnswer/knowledge-question-answer-card.component';
import {UnauthorizedHttpInterceptor} from './unauthorized-http-interceptor.service';
import {
    UnstableInternetConnectionHttpInterceptor
} from '../../../micromate-learn-lib/src/lib/services/unstable-internet-connection-http-interceptor.service';
import {AuthConfig} from '../../../external-libs/angular-oauth2-oidc/auth.config';
import {OAuthStorage} from '../../../external-libs/angular-oauth2-oidc/types';
import {OAuthModule} from '../../../external-libs/angular-oauth2-oidc/angular-oauth-oidc.module';
import {MicromateOAuthStorage} from '../../../micromate-learn-lib/src/lib/authentication/MicromateOAuthStorage';
import {
    MediaWebsitePopupComponent
} from '../../../micromate-learn-lib/src/lib/components/media-website-popup/media-website-popup.component';
import {PopupComponent} from '../../../micromate-learn-lib/src/lib/components/popup/popup.component';
import {
    ImagePopupComponent
} from '../../../micromate-learn-lib/src/lib/components/large-image-popup/image-popup.component';
import {ProfileProfileComponent} from './profile-pages/profile-profile/profile-profile.component';
import {BadgesComponent} from '../../../micromate-learn-lib/src/lib/components/badges/badges.component';
import {LearningProfileComponent} from './learning/learning-profile/learning-profile.component';
import {ButtonComponent} from '../../../micromate-learn-lib/src/lib/components/button/button.component';
import {CodeExchangeComponent} from './core/authentication/components/code-exchange/code-exchange.component';
import {SpinnerComponent} from '../../../micromate-learn-lib/src/lib/components/spinner/spinner.component';
import {AuthErrorComponent} from './core/authentication/components/auth-error/auth-error.component';
import {
    SwitchToggleComponent
} from '../../../micromate-learn-lib/src/lib/components/switch-toggle/switch-toggle.component';
import {IconComponent} from '../../../micromate-learn-lib/src/lib/components/icon/icon.component';
import {
    LearningProgressVisualizationBarComponent
} from '../../../micromate-learn-lib/src/lib/components/study-progress/learning-progress-visualization/learning-progress-visualization-bar/learning-progress-visualization-bar.component';
import {RegistrationComponent} from './registration/registration.component';
import {LogosComponent} from '../../../micromate-learn-lib/src/lib/components/logos/logos.component';
import {MissingRoleComponent} from './core/authentication/components/missing-role/missing-role.component';
import {
    PopupHeaderTitleComponent
} from '../../../micromate-learn-lib/src/lib/components/popup/components/popup-header-title/popup-header-title.component';
import {
    PopupHeaderComponent
} from '../../../micromate-learn-lib/src/lib/components/popup/components/popup-header/popup-header.component';
import {
    PopupFooterButtonsComponent
} from '../../../micromate-learn-lib/src/lib/components/popup/components/popup-footer-buttons/popup-footer-buttons.component';
import {
    PopupFooterComponent
} from '../../../micromate-learn-lib/src/lib/components/popup/components/popup-footer/popup-footer.component';
import {
    PopupContentComponent
} from '../../../micromate-learn-lib/src/lib/components/popup/components/popup-content/popup-content.component';
import {
    PopupContainerComponent
} from '../../../micromate-learn-lib/src/lib/components/popup/components/popup-container/popup-container.component';
import {
    QuizSourceComponent
} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/components/quiz-source/quiz-source.component';
import {FeedbackComponent} from '../../../micromate-learn-lib/src/lib/components/feedback/feedback.component';
import {MessageBoxComponent} from '../../../micromate-learn-lib/src/lib/components/message-box/message-box.component';
import {
    FeedbackResultPopupComponent
} from '../../../micromate-learn-lib/src/lib/components/feedback/feedback-result-popup/feedback-result-popup.component';
import {
    FeedbackCreationPopupComponent
} from '../../../micromate-learn-lib/src/lib/components/feedback/feedback-creation-popup/feedback-creation-popup.component';
import {
    PopupWizardFooterComponent
} from '../../../micromate-learn-lib/src/lib/components/popup-wizard/popup-wizard-footer/popup-wizard-footer.component';
import {
    PopupWizardHeaderComponent
} from '../../../micromate-learn-lib/src/lib/components/popup-wizard/popup-wizard-header/popup-wizard-header.component';
import {FEEDBACK_RESOURCE_TOKEN, FeedbackService} from '../../../micromate-learn-lib/src/lib/services/FeedbackService';
import {ProfileFeedbackService} from './core/rest/profile-feedback.service';
import {LearnFeedbackService} from '../../../micromate-learn-lib/src/lib/services/rest/learn-feedback.service';
import {MicrosoftTeamsRecognitionService} from './profile-pages/MicrosoftTeamsRecognitionService';
import {FeedbackMainTopicPopupComponent} from '../../../micromate-learn-lib/src/lib/components/feedback/feedback-main-topic-popup/feedback-main-topic-popup.component';
import {FeedbackTopicPopupComponent} from '../../../micromate-learn-lib/src/lib/components/feedback/feedback-topic-popup/feedback-topic-popup.component';
import {QuestionPreviewComponent} from '../../../micromate-learn-lib/src/lib/components/question-preview/question-preview.component';
import {ExplanationQuestionPreviewComponent} from '../../../micromate-learn-lib/src/lib/components/question-preview/components/explanation-question-preview/explanation-question-preview.component';
import {SourceQuestionPreviewComponent} from '../../../micromate-learn-lib/src/lib/components/question-preview/components/source-question-preview/source-question-preview.component';
import {FreeTextQuestionPreviewComponent} from '../../../micromate-learn-lib/src/lib/components/question-preview/question-type-preview/standard-question/free-text-question-preview/free-text-question-preview.component';
import {MultipleChoiceQuestionPreviewComponent} from '../../../micromate-learn-lib/src/lib/components/question-preview/question-type-preview/standard-question/multiple-choice-question-preview/multiple-choice-question-preview.component';
import {SingleChoiceQuestionPreviewComponent} from '../../../micromate-learn-lib/src/lib/components/question-preview/question-type-preview/standard-question/single-choice-question-preview/single-choice-question-preview.component';
import {TrueFalseQuestionPreviewComponent} from '../../../micromate-learn-lib/src/lib/components/question-preview/question-type-preview/standard-question/treu-false-question-preview/true-false-question-preview.component';
import {ProfileQuestionFeedbackComponent} from './profile-pages/profile-question-feedback/profile-question-feedback.component';
import {FeedbackTopicComponent} from '../../../micromate-learn-lib/src/lib/components/feedback/feedback-topic/feedback-topic.component';
import {ResourceViewerComponent} from './profile-pages/profile-resources/resource-viewer/resource-viewer.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MonthlyReportSkippedComponent} from '../../../micromate-learn-lib/src/lib/components/chatbot/customItems/monthly-report-skipped/monthly-report-skipped.component';
import {
    PopupActionButtonComponent
} from '../../../micromate-learn-lib/src/lib/components/popup/components/popup-action-button/popup-action-button.component';
import {LocaleSelectComponent} from '../../../micromate-learn-lib/src/lib/components/locale-select/locale-select.component';
import {LocalePipe} from '../../../micromate-learn-lib/src/lib/services/locale/locale.pipe';

const initialAuthConfig: AuthConfig = {
    scope: `openid profile email offline_access urn:zitadel:iam:user:metadata urn:zitadel:iam:org:domain:primary:micromate.ai urn:zitadel:iam:org:project:id:${environment.zitadelProjectId}:aud`,
    responseType: 'code',
    oidc: true,
    clientId: environment.zitadelClientId,
    issuer: 'https://login.micromate.ai',
    redirectUri: environment.iamCodeExchangeUrl,
    postLogoutRedirectUri: environment.iamLogoutUrl,
    userinfoEndpoint: 'https://login.micromate.ai/oidc/v1/userinfo',
    requireHttps: false,
    timeoutFactor: 0.5
};


export function BotatoConfigCreator(): BotatoConfig {
    const botatoConfig = botatoBaseConfig as BotatoConfig;

    botatoConfig.socketPath = environment.websocketpath;
    botatoConfig.socketUrl = environment.websocket;
    botatoConfig.defaultLanguage = 'de';

    return botatoConfig;
}

export function toastClass(): string {
    return MicrosoftTeamsRecognitionService.isRunningWithinMicrosoftTeams() ? 'ngx-toastr' : 'ngx-toastr mm-toast-in-web';
}

@NgModule({
    declarations: [
        SpinnerComponent,
        AuthErrorComponent,
        CodeExchangeComponent,
        AppComponent,
        ProfileMediaPresentationComponent,
        ChatbotComponent,
        MultipleTextChoiceQuizComponent,
        SingleTextChoiceQuizComponent,
        TrueFalseQuizComponent,
        FreeTextQuizComponent,
        LearningPackageIntroductionComponent,
        MonthlyReportSkippedComponent,
        ExplanationCardComponent,
        QuizQuestionComponent,
        EmojiPipe,
        QuizMediaComponent,
        LearningContainerComponent,
        BagdeComponent,
        HelpComponent,
        MediaComponent,
        LearningComponent,
        NavigationComponent,
        ProfileScoreboardComponent,
        ProfileStudyProgressComponent,
        ProfileComponent,
        StudyProgressComponent,
        ProfileBadgeComponent,
        RankingComponent,
        LearningBadgeComponent,
        LearningScoreboardComponent,
        LearningStudyProgressComponent,
        QuestionsVisualizationComponent,
        IframeMediaComponent,
        SafePipe,
        LocalePipe,
        MarkdownPipe,
        ReflectionFreeTextQuizComponent,
        ReflectionFreeTextCarouselExplanationComponent,
        LearningProgressVisualizationComponent,
        LearningPackageInfoComponent,
        KnowledgeQuestionAnswerCardComponent,
        PopupComponent,
        PopupHeaderTitleComponent,
        PopupHeaderComponent,
        PopupFooterButtonsComponent,
        PopupFooterComponent,
        PopupContentComponent,
        PopupContainerComponent,
        MediaWebsitePopupComponent,
        ImagePopupComponent,
        ProfileProfileComponent,
        BadgesComponent,
        LearningProfileComponent,
        ButtonComponent,
        SwitchToggleComponent,
        IconComponent,
        LearningProgressVisualizationBarComponent,
        RegistrationComponent,
        LogosComponent,
        MessageBoxComponent,
        MissingRoleComponent,
        QuizSourceComponent,
        FeedbackComponent,
        FeedbackResultPopupComponent,
        FeedbackCreationPopupComponent,
        PopupWizardFooterComponent,
        PopupWizardHeaderComponent,
        FeedbackMainTopicPopupComponent,
        FeedbackTopicPopupComponent,
        QuestionPreviewComponent,
        ExplanationQuestionPreviewComponent,
        SourceQuestionPreviewComponent,
        FreeTextQuestionPreviewComponent,
        MultipleChoiceQuestionPreviewComponent,
        LocaleSelectComponent,
        SingleChoiceQuestionPreviewComponent,
        TrueFalseQuestionPreviewComponent,
        ProfileQuestionFeedbackComponent,
        FeedbackTopicComponent,
        ResourceViewerComponent,
        PopupActionButtonComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BotatoAngularLibModule.with(LocalstorageService, BotatoConfigCreator, InputDataService, LearnMicromateMessageResolverServiceService),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.serverUrl],
                sendAccessToken: true
            }
        }),
        FormsModule,
        TranslateModule.forRoot(),
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            maxOpened: 3,
            timeOut: 3000,
            positionClass: 'toast-top-center',
            toastClass: toastClass(),
            preventDuplicates: true
        })
    ],
    exports: [TranslateModule, LearningStudyProgressComponent, LocaleSelectComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: LearnAuthService.initialization,
            deps: [Injector],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnstableInternetConnectionHttpInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: EnvironmentService,
            useFactory: (): EnvironmentService => {
                return new EnvironmentService(environment);
            }
        },
        {
            provide: LIB_AUTH_SERVICE_TOKEN,
            useClass: LearnAuthService
        },
        {
            provide: TAB_NAVIGATION_SERVICE,
            useExisting: NavigationService
        },
        {
            provide: OAuthStorage,
            useClass: MicromateOAuthStorage
        },
        {
            provide: AuthConfig,
            useValue: initialAuthConfig
        },
        Title,
        {
            provide: FEEDBACK_RESOURCE_TOKEN,
            useFactory: (injector: Injector): FeedbackService => {
                if (MicrosoftTeamsRecognitionService.isRunningWithinMicrosoftTeams()) {
                    return injector.get(ProfileFeedbackService);
                } else {
                    return injector.get(LearnFeedbackService);
                }
            },
            deps: [Injector]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
