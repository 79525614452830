import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuizQuestion} from '../../type/QuizQuestion';
import {ResponseItemDataTextService} from '../../../response-item-data-text.service';
import {QuizMedia} from '../../type/QuizMedia';
import {QuizQuestionResponseItemDataText} from '../../type/QuizQuestionResponseItemDataText';
import {EnvironmentService} from '../../../../../services/EnvironmentService';
import {PopupService} from '../../../../popup/popup.service';
import {PopupOptions} from '../../../../popup/PopupOptions';
import {FeedbackComponent} from '../../../../feedback/feedback.component';
import {FeedbackPopupData} from '../../../../feedback/FeedbackPopupData';
import {FeedbackPopupService} from '../../../../feedback/feedback-popup.service';

@Component({
    selector: 'mm-quiz-question',
    templateUrl: './quiz-question.component.html'
})
export class QuizQuestionComponent implements OnInit {

    @Input()
    public quizNumber: string;

    @Input()
    public quizQuestion: QuizQuestion;

    @Input()
    public questionStaticText: QuizQuestionResponseItemDataText;

    @Input()
    public additionalMessage?: string;

    @Input()
    public showFeedback: boolean;

    @Input()
    public questionId: string;

    @Input()
    public learningPackageId: string;

    @Output()
    public mediaLoaded: EventEmitter<void> = new EventEmitter<void>();

    public micromateManageUrl: string;
    public question: string;
    public quizMedia: QuizMedia;

    constructor(private responseItemDataTextService: ResponseItemDataTextService,
                private environmentService: EnvironmentService,
                private popupService: PopupService,
                private feedbackPopupService: FeedbackPopupService) {
        this.micromateManageUrl = environmentService.environment.manageUrl;
    }

    public ngOnInit(): void {
        this.question = this.responseItemDataTextService.replaceText(this.quizQuestion.text);
        this.quizMedia = new QuizMedia(this.quizQuestion.mediaType, this.quizQuestion.imageUrl, this.quizQuestion.mediaUrl);
    }

    public emitMediaLoaded(): void {
        this.mediaLoaded.emit();
    }

    public openFeedback(): void {
        this.feedbackPopupService.openIn = 'question';
        this.feedbackPopupService.setQuestionFeedbackLevel();
        const popupOptions = new PopupOptions('normal');
        const feedbackPopupData = new FeedbackPopupData(this.learningPackageId, this.questionId);
        void this.popupService.open<FeedbackPopupData>(FeedbackComponent, feedbackPopupData, popupOptions);
    }
}
