import {Inject, Injectable} from '@angular/core';
import {ChatStartService} from '../components/chatbot/chat-start.service';
import {ConversationRessource} from './rest/conversation-ressource.service';
import {TAB_NAVIGATION_SERVICE, TabNavigationService} from './TabNavigationService';

@Injectable({
    providedIn: 'root'
})
export class LearnLearningPackageService {
    constructor(private chatStartService: ChatStartService,
                private conversationService: ConversationRessource,
                @Inject(TAB_NAVIGATION_SERVICE) private tabNavigationService: TabNavigationService) {
    }

    public async learn(learningPackageId: string): Promise<void> {
        // TODO currently we first have to navigate, as the botato-chat component is not able
        // to receive messages when not visible
        this.chatStartService.isWaitingForNotification = true;
        this.tabNavigationService.navigateToChat(false);
        await this.conversationService.selectLearningPackage(learningPackageId);
    }
}
