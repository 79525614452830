import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {LearnerBadge} from '../../types/LearnerBadge';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'mm-badges',
    templateUrl: './badges.component.html',
    styleUrls: ['./badges.component.scss']
})
export class BadgesComponent {
    @Input() badgesData: Observable<LearnerBadge[]>;
    @Input() darkMode: boolean;

    constructor(private translate: TranslateService) {
    }

    public getBadgeLevelInfo(badge: LearnerBadge): string {
        if (badge.active) {
            if (badge.type === 'noLevel') {
                return this.translate.instant('badge.noLevel') as string;
            }
            return `${this.translate.instant('badge.level')} ${badge.level}`;
        }
        return this.translate.instant('badge.locked') as string;
    }

    public getBadgeProgress(badge: LearnerBadge): number {
        if (badge.actualValue <= 0) {
            return 0;
        }

        const number = (badge.actualValue * 100) / badge.nextValue;
        return number > 100 ? 100 : number;
    }
}
