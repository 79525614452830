import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TaskModuleQuestionFeedbackData} from '../../../../../micromate-learn-lib/src/lib/types/TaskModuleQuestionFeedbackData';
import {FeedbackPopupService} from '../../../../../micromate-learn-lib/src/lib/components/feedback/feedback-popup.service';
import {FeedbackPopupData} from '../../../../../micromate-learn-lib/src/lib/components/feedback/FeedbackPopupData';
import {TeamsContextService} from '../../core/teams-context.service';
import {Subscription} from 'rxjs';
import {Translation} from '../../../../../micromate-learn-lib/src/lib/services/Translation';
import {LearnerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/learner-ressource.service';
import {TranslateService} from '@ngx-translate/core';
import {app} from '@microsoft/teams-js';

@Component({
    selector: 'mm-profile-question-feedback',
    templateUrl: './profile-question-feedback.component.html',
    styleUrls: ['./profile-question-feedback.component.scss']
})
export class ProfileQuestionFeedbackComponent implements OnInit, OnDestroy {
    public feedbackTaskModuleData: TaskModuleQuestionFeedbackData;
    public feedbackPopupData: FeedbackPopupData;
    public teamsContextServiceSubscription = new Subscription();
    public queryParamMapSubscription = new Subscription();

    constructor(public teamsContextService: TeamsContextService,
                private activatedRoute: ActivatedRoute,
                private titleService: Title,
                private feedbackPopupService: FeedbackPopupService,
                private learnerService: LearnerRessource,
                private translate: TranslateService) {
        this.titleService.setTitle('Feedback Profile');
    }

    public ngOnInit(): void {
        this.teamsContextServiceSubscription = this.teamsContextService.teamsContext$.subscribe(async teamsContext => {
            const userId = teamsContext.user.id;
            if (userId === undefined) {
                throw new Error(`UserObjectId is undefined when open ProfileProfile, teamsContext: ${JSON.stringify(teamsContext)}`);
            }
            // eslint-disable-next-line no-null/no-null
            if (userId === null) {
                throw new Error(`UserObjectId is null when open ProfileProfile, teamsContext: ${JSON.stringify(teamsContext)}`);
            }
            if (userId.trim().length === 0) {
                throw new Error(`UserObjectId is empty when open ProfileProfile, teamsContext: ${JSON.stringify(teamsContext)}`);
            }
            await this.getLearnerLanguage(userId, teamsContext);
            this.getTaskModuleData();
        });
    }

    private async getLearnerLanguage(userId: string, teamsContext: app.Context): Promise<void> {
        const languageData = await this.learnerService.getLearnerLanguage(userId, teamsContext);
        this.translate.use(new Translation().getTranslationLanguage(languageData.language));
    }

    private getTaskModuleData(): void {
        this.queryParamMapSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
            const data = params.get('data');
            this.feedbackTaskModuleData = JSON.parse(data) as TaskModuleQuestionFeedbackData;
            this.feedbackPopupService.openIn = 'question';
            this.feedbackPopupService.setQuestionFeedbackLevel();
            this.feedbackPopupData = new FeedbackPopupData(this.feedbackTaskModuleData.learningPackageTranslationId, this.feedbackTaskModuleData.questionTranslationId);
        });
    }

    public ngOnDestroy(): void {
        this.teamsContextServiceSubscription.unsubscribe();
        this.queryParamMapSubscription.unsubscribe();
    }
}
