import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuizResponseItemDataText} from '../../type/QuizReposnseItemDataText';
import {QuizMedia} from '../../type/QuizMedia';
import {MediaType, TaskModuleMediaData} from '../../../../../types/TaskModuleMediaData';
import {MediaWebsitePopupData} from '../../../../media-website-popup/MediaWebsitePopupData';
import {PopupService} from '../../../../popup/popup.service';
import {MediaWebsitePopupComponent} from '../../../../media-website-popup/media-website-popup.component';
import {PopupOptions} from '../../../../popup/PopupOptions';
import {ImagePopupComponent} from '../../../../large-image-popup/image-popup.component';
import {LargeImagePopupData} from '../../../../large-image-popup/LargeImagePopupData';

@Component({
    selector: 'mm-quiz-media',
    templateUrl: './quiz-media.component.html',
    styleUrls: ['./quiz-media.component.scss']
})
export class QuizMediaComponent implements OnInit {

    @Input()
    public quizMedia: QuizMedia;

    @Input()
    public questionStaticText: QuizResponseItemDataText;

    @Input()
    public micromateManageUrl: string;

    @Output()
    public mediaLoaded: EventEmitter<void> = new EventEmitter<void>();

    public mediaData: TaskModuleMediaData;

    constructor(private popupService: PopupService) {
    }

    public ngOnInit(): void {
        if (['movie', 'audio', 'website'].includes(this.quizMedia.mediaType)) {
            this.mediaData = new TaskModuleMediaData(this.quizMedia.mediaType, this.quizMedia.mediaUrl, this.quizMedia.imageUrl);
        }

        if (!this.checkIfQuestionHaveMedia(this.quizMedia)) {
            this.emitMediaLoaded();
        }
    }

    public checkIfQuestionHaveMedia(quiz: QuizMedia): boolean {
        return quiz.mediaType !== undefined && (quiz.imageUrl !== undefined || quiz.mediaUrl !== undefined);
    }

    public createAdaptiveCardImageUrl(mediaType: MediaType, imageUrl: string): string {
        if (imageUrl !== undefined) {
            return imageUrl;
        }
        const url = `${this.micromateManageUrl}/assets/media`;
        switch (mediaType) {
            case 'audio':
                return `${url}/audio-play.png`;
            case 'movie':
                return `${url}/movie-play.png`;
            case 'website':
                return `${url}/media-website-learn.png`;
            default:
                throw new Error(`Unknown media type ${mediaType}`);
        }
    }

    public emitMediaLoaded(): void {
        this.mediaLoaded.emit();
    }

    public showImageInLarge(imageUrl: string): void {
        const popupOptions = new PopupOptions('normal');
        void this.popupService.open<LargeImagePopupData>(ImagePopupComponent, new LargeImagePopupData(imageUrl), popupOptions);
    }

    public openWebsideMedia(): void {
        const popupOptions = new PopupOptions('custom', 'closable', 1000, 600);
        void this.popupService.open<MediaWebsitePopupData>(MediaWebsitePopupComponent, new MediaWebsitePopupData(this.mediaData), popupOptions);
    }
}
