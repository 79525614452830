<div class="mm-spinner-container"
     *ngIf="isInitializing || (isRegistrationDone && !registrationLogic.shouldShowConfirmation)">
    <mm-spinner class="mm-registration-spinner"></mm-spinner>
</div>

<div class="mm-registration-page">

    <div class="mm-registration-done" *ngIf="isRegistrationDone">
        <ng-container *ngIf="registrationLogic.shouldShowConfirmation">
            <app-logos *ngIf="!isInitializing" logo="micromate" class="mm-registration-logo"></app-logos>
            <h1 class="mm-title">{{ 'registration.confirmation.title' | translate }}</h1>

            <app-icon [icon]="'envelope-light'" class="mm-done-icon"></app-icon>
            <p class="mm-description" [innerHTML]="'registration.confirmation.description' | translate:{email: formData.controls.email.value}"></p>
        </ng-container>
    </div>

    <div class="mm-registration-content" *ngIf="!isRegistrationDone">
        <app-logos *ngIf="!isInitializing" logo="micromate" class="mm-registration-logo"></app-logos>
        <h1 class="mm-title"
            *ngIf="!isInitializing  && !hasError && !registrationFailed">{{ registrationLogic.titleTranslationKey | translate }}</h1>
        <form *ngIf="!isInitializing && !hasError && !registrationFailed" class="mm-registration-form"
              [formGroup]="formData"
              [attr.disabled]="registrationRunning" [class.mm-registration-form-disabled]="registrationRunning">
            <div class="mm-form-element mm-registration-form-element"
                 *ngIf="registrationLogic.shouldShowOrganisationInput">
                <label class="mm-form-label">{{ 'registration.organization'| translate }}
                </label>
                <input type="text" formControlName="organization"
                       class="mm-form-input">
            </div>

            <div class="mm-form-element mm-registration-form-element">
                <label class="mm-form-label">{{ 'registration.email'| translate }}
                    <span class="mm-form-label-required">*</span>
                </label>
                <input type="text" formControlName="email"
                       autocomplete="email"
                       class="mm-form-input">
                <div *ngIf="formData.controls.email.touched && formData.controls.email.errors?.['required']"
                     class="mm-form-validation-error">{{ 'registration.emailRequiredError'| translate }}
                </div>
                <div *ngIf="formData.controls.email.touched && formData.controls.email.errors?.['email']"
                     class="mm-form-validation-error">{{ 'registration.emailInvalidError'| translate }}
                </div>
            </div>

            <div class="mm-form-element mm-registration-form-element">
                <label class="mm-form-label">{{ 'registration.firstName'| translate }}
                    <span class="mm-form-label-required">*</span>
                </label>
                <input type="text" formControlName="firstName"
                       class="mm-form-input"
                       autocomplete="first-name"
                       [class.mm-form-input-validation-error]="formData.controls.firstName.touched && formData.controls.firstName.errors?.['required']">
                <div *ngIf="formData.controls.firstName.touched && formData.controls.firstName.errors?.['required']"
                     class="mm-form-validation-error">{{ 'registration.firstNameRequiredError'| translate }}
                </div>
            </div>
            <div class="mm-form-element mm-registration-form-element">
                <label class="mm-form-label">{{ 'registration.lastName'| translate }}
                    <span class="mm-form-label-required">*</span>
                </label>
                <input type="text" formControlName="lastName"
                       class="mm-form-input"
                       autocomplete="last-name"
                       [class.mm-form-input-validation-error]="formData.controls.lastName.touched && formData.controls.lastName.errors?.['required']">
                <div *ngIf="formData.controls.lastName.touched && formData.controls.lastName.errors?.['required']"
                     class="mm-form-validation-error">{{ 'registration.lastNameRequiredError'| translate }}
                </div>
            </div>

            <div class="mm-form-element mm-registration-form-element">
                <label class="mm-form-label">{{ 'registration.language'| translate }}
                    <span class="mm-form-label-required">*</span>
                </label>

                <app-locale-select formControlName="locale"></app-locale-select>
            </div>

            <div class="mm-form-element mm-registration-form-element">
                <label class="mm-form-label">{{ 'registration.password'| translate }}
                    <span class="mm-form-label-required">*</span>
                </label>
                <input type="password" formControlName="password"
                       class="mm-form-input" autocomplete="new-password"
                       [class.mm-form-input-validation-error]="formData.controls.password.touched && formData.controls.password.errors?.['invalidPassword']">
                <div
                    *ngIf="formData.controls.password.touched && formData.controls.password.errors?.['invalidPassword']"
                    class="mm-form-validation-error">{{ 'registration.passwordInvalidError'| translate }}
                </div>
                <ul class="mm-registration-page-form-password-complexity-list"
                    *ngIf="passwordComplexityPolicy !== undefined">
                    <li *ngIf="passwordComplexityPolicy.minLength"
                        class="mm-registration-page-form-password-complexity-element"
                        [innerHTML]="'registration.passwordComplexityPolicyMinLength'| translate:{minLength: passwordComplexityPolicy.minLength}">
                    </li>
                    <li *ngIf="passwordComplexityPolicy.hasNumber"
                        class="mm-registration-page-form-password-complexity-element">
                        {{ 'registration.passwordComplexityPolicyHasNumber'| translate }}
                    </li>
                    <li *ngIf="passwordComplexityPolicy.hasLowercase"
                        class="mm-registration-page-form-password-complexity-element">
                        {{ 'registration.passwordComplexityPolicyHasLowercase'| translate }}
                    </li>
                    <li *ngIf="passwordComplexityPolicy.hasSymbol"
                        class="mm-registration-page-form-password-complexity-element">
                        {{ 'registration.passwordComplexityPolicyHasSymbol'| translate }}
                    </li>
                    <li *ngIf="passwordComplexityPolicy.hasUppercase"
                        class="mm-registration-page-form-password-complexity-element">
                        {{ 'registration.passwordComplexityPolicyHasUppercase'| translate }}
                    </li>
                </ul>
            </div>
            <div class="mm-form-element mm-registration-form-element">
                <label class="mm-form-label"> {{ 'registration.confirmPassword'| translate }}
                    <span class="mm-form-label-required">*</span>
                </label>
                <input type="password" formControlName="confirmPassword"
                       autocomplete="new-password"
                       class="mm-form-input"
                       [class.mm-form-input-validation-error]="formData.controls.confirmPassword.touched &&  formData.controls.confirmPassword.errors?.['invalidConfirmPassword']">
                <div
                    *ngIf="formData.controls.confirmPassword.touched &&  formData.controls.confirmPassword.errors?.['invalidConfirmPassword']"
                    class="mm-form-validation-error">{{ 'registration.confirmPasswordInvalidError'| translate }}
                </div>
            </div>

            <div class="mm-registration-form-element-agreements">
                <div class="mm-form-element mm-registration-form-element-checkbox">
                    <input type="checkbox" formControlName="agb">
                    <label class="mm-form-label mm-form-label-link ">
                        <a class="mm-form-label-link-text" target="_blank"
                           href="https://micromate.ai/allgemeine-geschaeftsbedingungen/">{{ 'registration.agbLinkTitle'| translate }}</a>
                        <div>
                            {{ 'registration.dataAccept'| translate }}
                        </div>
                        <span class="mm-form-label-required">*</span>
                    </label>
                </div>

                <div class="mm-form-element mm-registration-form-element-checkbox">
                    <input type="checkbox" formControlName="dataPrivacy">
                    <label class="mm-form-label mm-form-label-link">
                        <a class="mm-form-label-link-text" target="_blank"
                           href="https://micromate.ai/datenschutzbestimmungen/">{{ 'registration.dataPrivacyLinkTitle'| translate }}</a>
                        <div>
                            {{ 'registration.dataAccept'| translate }}
                        </div>
                        <span class="mm-form-label-required">*</span>
                    </label>
                </div>

            </div>

            <app-button type="primary"
                        [action]="registerLearner"
                        [disabled]="formData.invalid || registrationRunning">{{ 'registration.registrationButton'| translate }}
            </app-button>
            <app-message-box type="error" *ngIf="hasFormError"
                             class="mm-registration-error"
                             [header]="formErrorTitleKey | translate"
                             [content]="formErrorKey | translate:{organizationName: formData.controls.organization.value}"></app-message-box>
        </form>
        <app-message-box type="error" class="mm-registration-error" *ngIf="!isInitializing && registrationFailed"
                         [header]="'registration.registrationFailedErrorTitle'| translate">
            <p class="mm-registration-form-error">
                {{ 'registration.registrationFailedError'| translate }}
                <a href="mailto:hello@micromate.ai">hello&#64;micromate.ai</a>.</p>
        </app-message-box>
        <app-message-box type="error" class="mm-registration-error" *ngIf="!isInitializing && hasError"
                         [header]="errorTitleKey | translate"
                         [content]="errorKey | translate"></app-message-box>

    </div>
</div>
