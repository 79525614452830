import {Component} from '@angular/core';
import {FeedbackPopupService} from '../feedback-popup.service';

@Component({
    selector: 'mm-feedback-main-topic-popup',
    templateUrl: './feedback-main-topic-popup.component.html',
    styleUrls: ['./feedback-main-topic-popup.component.scss'],

})
export class FeedbackMainTopicPopupComponent {

    constructor(private feedbackPopupService: FeedbackPopupService) {
    }

    public openQuestionsFeedbackTopics(): void {
        this.feedbackPopupService.setQuestionFeedbackLevel();
        this.feedbackPopupService.currentStep = 'topic';
    }

    public openLearningPackageFeedbackTopics(): void {
        this.feedbackPopupService.setLearningPackageFeedbackLevel();
        this.feedbackPopupService.currentStep = 'topic';
    }
}
