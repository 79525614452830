<div class="pa-message-content micromate-message-content">
    <div class="micromate-learning-package-introduction" [innerHTML]="responseItemData.text.message | emoji">
    </div>
    <div (click)="navigateToProfile()"
         class="micromate-profile-link">
        <svg class="micromate-profile-link-icon" aria-hidden="true" focusable="false" viewBox="0 0 448 512"
             mmSvg="user-light">
        </svg>
        {{createProfileText(responseItemData.text.buttonText)}}
    </div>
</div>
