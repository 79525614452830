<div class="mm-question-preview-area" *ngIf="questionPreview">
    <p class="mm-question" [innerHTML]="questionPreview.quiz.text | markdown"></p>
    <app-single-choice-question-preview
        [class.mm-question-preview-basic]="type === 'basic'"
        *ngIf="questionPreview.quizType === 'SingleTextChoice'"
        [questionPreview]="questionPreview">
    </app-single-choice-question-preview>
    <app-multiple-choice-question-preview
        [class.mm-question-preview-basic]="type === 'basic'"
        *ngIf="questionPreview.quizType === 'MultipleTextChoice'"
        [questionPreview]="questionPreview">
    </app-multiple-choice-question-preview>
    <app-free-text-question-preview
        [class.mm-question-preview-basic]="type === 'basic'"
        *ngIf="questionPreview.quizType === 'FreeText'"
        [questionPreview]="questionPreview">
    </app-free-text-question-preview>
    <app-true-false-question-preview
        [class.mm-question-preview-basic]="type === 'basic'"
        *ngIf="questionPreview.quizType === 'TrueFalse'"
        [questionPreview]="questionPreview">
    </app-true-false-question-preview>
</div>
