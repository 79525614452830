import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TeamsContextService} from '../../core/teams-context.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {StudyProgress} from '../../../../../micromate-learn-lib/src/lib/types/StudyProgress';
import {Translation} from '../../../../../micromate-learn-lib/src/lib/services/Translation';
import {StudyProgressRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/study-progress-ressource.service';
import {LearnerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/learner-ressource.service';
import {EventRecorderService} from '../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {TabEventType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabEventType';
import {app} from '@microsoft/teams-js';
import {environment} from '../../../environments/environment';
import {ConversationRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/conversation-ressource.service';
import {LoggerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';

@Component({
    selector: 'mm-profile-study-progress',
    templateUrl: './profile-study-progress.component.html',
    styleUrls: ['./profile-study-progress.component.scss']
})
export class ProfileStudyProgressComponent implements OnInit, OnDestroy {
    public teamsContextServiceSubscription: Subscription;
    public studyProgresses: BehaviorSubject<StudyProgress[]> = new BehaviorSubject<StudyProgress[]>(undefined);
    public studyProgresses$ = this.studyProgresses.asObservable();
    public userId: string;

    constructor(public teamsContextService: TeamsContextService,
                public studyProgressService: StudyProgressRessource,
                private learnerService: LearnerRessource,
                private translate: TranslateService,
                private ref: ChangeDetectorRef,
                private titleService: Title,
                private eventRecorderService: EventRecorderService,
                private conversationService: ConversationRessource,
                private loggerService: LoggerRessource) {
        this.titleService.setTitle('Micromate Profile');
    }

    public ngOnInit(): void {
        this.loggerService.logFrontendInfoToBackend('ProfileStudyProgress init');
        this.teamsContextServiceSubscription = this.teamsContextService.teamsContext$.subscribe(async teamsContext => {
            this.userId = teamsContext.user.id;
            this.loggerService.logFrontendInfoToBackend(`ProfileStudyProgress ${TeamsContextService.formatContext(teamsContext)}, userId: ${this.userId}`);
            if (this.userId === undefined) {
                throw new Error(`UserObjectId is undefined when open ProfileStudyProgress, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
            }
            // eslint-disable-next-line no-null/no-null
            if (this.userId === null) {
                throw new Error(`UserObjectId is null when open ProfileStudyProgress, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
            }
            if (this.userId.trim().length === 0) {
                throw new Error(`UserObjectId is empty when open ProfileStudyProgress, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
            }
            await this.getLearnerStudyProgress(this.userId, teamsContext);
            await this.recordTabEvents(this.userId, teamsContext.page.subPageId);
        });
    }

    private async getLearnerStudyProgress(msteamsId: string, teamsContext: app.Context): Promise<void> {
        const languageDataPromise = this.learnerService.getLearnerLanguage(msteamsId, teamsContext);
        const studyProgressesDataPromise = this.studyProgressService.getLearnerStudyProgress(msteamsId);
        const languageData = await languageDataPromise;
        if (languageData === undefined) {
            throw  new Error(`Could not find language in study progress for msteamsId: ${msteamsId}`);
        }
        this.translate.use(new Translation().getTranslationLanguage(languageData.language));
        const studyProgressesData = await studyProgressesDataPromise;
        this.studyProgresses.next(studyProgressesData === undefined ? [] : studyProgressesData);
        this.ref.detectChanges();
    }

    private async recordTabEvents(userId: string, subEntityId: string | { openInConversation: boolean; }): Promise<void> {
        await this.eventRecorderService.recordTabOpenedEvent(userId, TabEventType.TabLearningProgressOpened, this.eventRecorderService.createTabOpenedEventPayload(subEntityId));
    }

    public ngOnDestroy(): void {
        this.loggerService.logFrontendInfoToBackend('ProfileStudyProgress destroyed');
        this.teamsContextServiceSubscription.unsubscribe();
    }

    public async learnPackage(learningPackageId: string): Promise<void> {
        await this.conversationService.selectLearningPackageInProfile(learningPackageId, this.userId);
        this.navigateToConversationInMSTeams();
    }

    private navigateToConversationInMSTeams(): void {
        const msTeamsBotId = environment.msTeamsBotId;
        void app.openLink(`https://teams.microsoft.com/l/entity/${msTeamsBotId}/conversations`);
    }
}
