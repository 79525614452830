import {RegistrationType} from '../registrationType';
import {RegistrationService} from '../registration.service';
import {RegistrationComponentFormDataValues} from '../RegistrationComponentFormDataValues';

import {RegistrationStrategy} from './RegistrationStrategy';
import {Result} from './Result';
import {FormGroup} from '@angular/forms';
import {RegistrationComponentFormData} from '../RegistrationComponentFormData';

export class SelfRegistrationWithoutOrganisationStrategy implements RegistrationStrategy {

    public type: RegistrationType = RegistrationType.SelfRegistrationWithoutOrganisation;
    public shouldShowOrganisationInput: boolean = false;
    public shouldShowConfirmation: boolean = true;
    public titleTranslationKey: string = 'registration.flows.selfregistrationwithoutorganisation.title';


    constructor(private registrationService: RegistrationService) {
    }

    public enableForm(formData: FormGroup<RegistrationComponentFormData>): void {
        formData.controls.email.enable();
    }

    public async initialize(): Promise<Result> {
        // Nothing to prefill
        return new Result(true);
    }

    public async execute(formData: RegistrationComponentFormDataValues): Promise<Result> {
        const registrationResult = await this.registrationService.selfRegisterLearnerWithoutOrganisation(formData);
        if (registrationResult.emailAlreadyTaken) {
            return new Result(false, 'registration.flows.selfregistrationwithoutorganisation.emailAlreadyTakenTitle', 'registration.flows.selfregistrationwithoutorganisation.emailAlreadyTaken');
        }
        return new Result(true);
    }
}
