import {Component, Inject, Input} from '@angular/core';
import {TAB_NAVIGATION_SERVICE, TabNavigationService} from '../../../../../services/TabNavigationService';
import {ResourceToDisplay} from './resource-to-display';
import {ResourceOpenedFromType} from '../../../../../services/event/ResourceOpenEventPayload';

@Component({
    selector: 'mm-quiz-source',
    templateUrl: './quiz-source.component.html',
    styleUrls: ['./quiz-source.component.scss']
})
export class QuizSourceComponent {

    constructor(@Inject(TAB_NAVIGATION_SERVICE) private navigationService: TabNavigationService) {
    }

    @Input()
    public sourceUrl: string;

    @Input()
    public sourceLabel: string;

    @Input()
    public sourceTitle: string;

    public showMicromateResource(): void {

        const match = /resources\/(?<resourceId>.*)\/selections\/(?<selectionId>.*)/.exec(this.sourceUrl);

        const resourceToShow: ResourceToDisplay = {
            resourceId: match.groups['resourceId'],
            selectionId: match.groups['selectionId'],
            openedFrom: ResourceOpenedFromType.QUESTION
        };

        this.navigationService.showResource(resourceToShow);
    }

    public shouldShowAsMicromateResource(): boolean {
        return this.navigationService.isRunningInWidget() && this.sourceUrl !== undefined && this.navigationService.isMicromateResourceUrl(this.sourceUrl);
    }
}

