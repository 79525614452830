import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../micromate-learn-web/src/environments/environment';
import {User} from '../../components/chatbot/User';

@Injectable({
    providedIn: 'root'
})
export class LoggerRessource {

    constructor(private http: HttpClient) {
    }

    public logFrontendInfoToBackend(info: string, authenticatedUser?: User): void {

        const data = {
            localUserId: this.getLocalUserId(),
            sub: authenticatedUser?.sub,
            data: `LEARN: ${info}`
        };

        this.http.post(`${environment.serverUrl}/api/frontend-logs`, data, {responseType: 'text'}).subscribe();
    }

    private getLocalUserId(): string {
        // eslint-disable-next-line no-null/no-null
        if (localStorage.getItem('micromate_logger_user_id') === null) {
            localStorage.setItem('micromate_logger_user_id', Math.random().toString());
        }

        return localStorage.getItem('micromate_logger_user_id');
    }
}
