import {Inject, Injectable} from '@angular/core';
import {LearnAuthService} from './learn-auth.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';
import {UserRoles} from '../../../../../micromate-learn-lib/src/lib/types/roles';
import {User} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

// TODO KILL / Replace by a directove or something?
@Injectable({
    providedIn: 'root'
})
export class AuthUntilUserRoleService {
    public userOrganizationRoles = new BehaviorSubject<string []>(undefined);
    public userOrganizationRoles$ = this.userOrganizationRoles.asObservable();

    constructor(@Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LearnAuthService) {
        this.authService.authentication$.subscribe((authentication: User) => {
            this.userOrganizationRoles.next(authentication?.roles);
        });
    }

    public assertAdmin(): Observable<boolean> {
        return this.userOrganizationRoles$.pipe(
            take(1),
            filter((roles: string []) => {
                return roles !== undefined && roles.length > 0;
            }),
            switchMap((roles: string []) => {
                return of(roles.includes(UserRoles.SUPER_ADMINISTRATOR));
            })
        );
    }

    public assertLearner(): Observable<boolean> {
        return this.userOrganizationRoles$.pipe(
            take(1),
            filter((roles: string []) => {
                return roles !== undefined && roles.length > 0;
            }),
            switchMap((roles: string []) => {
                return of(roles.includes(UserRoles.LEARNER));
            })
        );
    }
}
