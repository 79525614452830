import {Component, Injector, OnInit} from '@angular/core';
import {ImmediatelyRenderedMessageComponent} from 'botato-angular-lib';
import {MonthlyReportSkippedAdaptiveCard} from './MonthlyReportSkippedAdaptiveCard';
import {ResponseItemDataTextService} from '../../response-item-data-text.service';
import {TAB_NAVIGATION_SERVICE, TabNavigationService} from '../../../../services/TabNavigationService';

@Component({
    selector: 'mm-monthly-report-skipped',
    templateUrl: './monthly-report-skipped.component.html',
    styleUrls: ['./monthly-report-skipped.component.scss']
})
export class MonthlyReportSkippedComponent extends ImmediatelyRenderedMessageComponent implements OnInit {

    public static TYPE = 'MonthlyReportSkipped';
    public responseItemData: MonthlyReportSkippedAdaptiveCard;

    private tabNavigationService: TabNavigationService;

    constructor(private responseItemDataTextService: ResponseItemDataTextService, injector: Injector) {
        super();
        this.tabNavigationService = injector.get(TAB_NAVIGATION_SERVICE);
    }

    public ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.responseItemData = this.message.data.responseItemData as MonthlyReportSkippedAdaptiveCard;
    }

    public createProfileText(text: string): string {
        return text.replace('➤  ', '');
    }

    public navigateToProfile(): void {
        this.tabNavigationService.navigateToProfile(false);
    }
}
