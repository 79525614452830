export enum ResourceOpenedFromType {
    QA_SOURCE = 'qa-sources',
    QUESTION = 'question'
}

export class ResourceOpenEventPayload {
    constructor(public openedFrom: ResourceOpenedFromType,
                public resourceId: string,
                public selectionId: string) {
    }
}
