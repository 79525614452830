import {Injectable} from '@angular/core';
import {MarkdownService} from '../../services/markdown.service';

@Injectable({
    providedIn: 'root'
})
export class ResponseItemDataTextService {

    private static NEW_LINE_SEPARATOR = ' | ';

    constructor(private markdownService: MarkdownService) {
    }

    public create<T>(responseItemDataText: T): T {
        const text = responseItemDataText;
        for (const [key, value] of Object.entries(responseItemDataText)) {
            text[key] = this.replaceText(value as string);
        }
        return responseItemDataText;
    }

    public replaceText(value: string): string {
        value = value.split(ResponseItemDataTextService.NEW_LINE_SEPARATOR).join('<br />');
        return this.markdownService.renderMarkdown(value, true);
    }
}
