import {Component, Input} from '@angular/core';
import {Popup} from '../popup/Popup';
import {LargeImagePopupData} from './LargeImagePopupData';

@Component({
    selector: 'mm-image-popup',
    templateUrl: './image-popup.component.html',
    styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent implements Popup<LargeImagePopupData> {
    @Input()
    public data: LargeImagePopupData;
}

