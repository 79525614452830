<div class="mm-question-answer-documents">


    <div *ngIf="sourceLabel !== undefined && sourceLabel.length > 0; else noLabel"
         class="mm-question-answer-documents-text">
        {{sourceTitle}}:
        <ng-container *ngIf="shouldShowAsMicromateResource()">
            <a class="mm-question-answer-documents-text-link" href="#" (click)="showMicromateResource()">{{sourceLabel}}</a>
        </ng-container>
        <ng-container *ngIf="!shouldShowAsMicromateResource()">
            <a class="mm-question-answer-documents-text-link" target="_blank" href="{{sourceUrl}}">{{sourceLabel}}</a>
        </ng-container>
    </div>

    <ng-template #noLabel>
        <div class="mm-question-answer-documents-text">

            <ng-container *ngIf="shouldShowAsMicromateResource()">
                <a class="mm-question-answer-documents-text-link" href="#" (click)="showMicromateResource()">{{sourceTitle}}</a>
            </ng-container>
            <ng-container *ngIf="!shouldShowAsMicromateResource()">
                <a class="mm-question-answer-documents-text-link" target="_blank" href="{{sourceUrl}}">{{sourceTitle}}</a>
            </ng-container>
        </div>
    </ng-template>
</div>
