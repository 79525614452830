import {Injectable} from '@angular/core';
import {LearnerRankingResponseDto} from '../../types/LearnerRankingResponseDto';

@Injectable({
    providedIn: 'root'
})
export class RankingService {

    public isSpaceAfterLeadersVisible(ranking: LearnerRankingResponseDto): boolean {
        if (ranking.numberOfLearnersInOrganization === ranking.leaders.length) {
            return false;
        }
        return ranking.betterLearners.length === 2 ? ranking.betterLearners[0].position > (ranking.leaders.length + 1) : false;
    }

    public isSpaceBeforeLastLearnerPlaceVisible(ranking: LearnerRankingResponseDto): boolean {
        if (ranking.leaders.length === ranking.numberOfLearnersInOrganization || (ranking.leaders.length + 1) === ranking.numberOfLearnersInOrganization) {
            return false;
        }

        if (ranking.worseLearners.length > 0) {
            return ranking.worseLearners[ranking.worseLearners.length - 1].position < ranking.numberOfLearnersInOrganization - 1;
        }

        return (ranking.leaders.length + 1) < ranking.numberOfLearnersInOrganization && (ranking.activeLearner === undefined || ranking.activeLearner.position !== ranking.numberOfLearnersInOrganization);
    }

    public isLastLearnerPlaceVisible(ranking: LearnerRankingResponseDto): boolean {
        if (ranking.numberOfLearnersInOrganization === ranking.leaders.length) {
            return false;
        }
        if (ranking.worseLearners.length === 0) {
            return ranking.activeLearner === undefined || ranking.activeLearner.position !== ranking.numberOfLearnersInOrganization;
        }
        return ranking.worseLearners[ranking.worseLearners.length - 1].position < ranking.numberOfLearnersInOrganization;
    }
}
