import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-logos',
    templateUrl: './logos.component.html',
    styleUrls: ['./logos.component.scss']
})
export class LogosComponent {

    @Input()
    public logo: Logos;

}

export type Logos = 'micromate';
