<mm-navigation [activeNavigation]="'badges'"></mm-navigation>
<div class="micromate-learn-component">
    <div class="micromate-learn-context">
        <mm-badges
            [badgesData]="badges$"
            [darkMode]="false">
        </mm-badges>
    </div>

</div>

