import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BotatoChatManagerService, DelayedRenderedMessageComponent} from 'botato-angular-lib';
import {QuizResponseItemData} from '../../type/QuizResponseItemData';
import {SingleTextChoiceQuiz} from './SingleTextChoiceQuiz';
import {ResponseItemDataTextService} from '../../../response-item-data-text.service';
import {QuizQuestionResponseItemDataText} from '../../type/QuizQuestionResponseItemDataText';
import {DeactivateMessageService} from '../../../deactivate-message.service';
import {Subscription} from 'rxjs';
import {SingleTextChoiceQuizUpdateAnswer} from './SingleTextChoiceQuizUpdateAnswer';
import {QuizQuestion} from '../../type/QuizQuestion';

@Component({
    selector: 'mm-single-text-choice-quiz',
    templateUrl: './single-text-choice-quiz.component.html'
})
export class SingleTextChoiceQuizComponent extends DelayedRenderedMessageComponent implements OnInit, OnDestroy {

    public static TYPE = 'SingleTextChoice';
    public static UPDATE_TYPE = 'updateSingleTextChoiceQuiz';

    public responseItemData: QuizResponseItemData<SingleTextChoiceQuiz, QuizQuestionResponseItemDataText, SingleTextChoiceQuizUpdateAnswer>;
    public question: QuizQuestion;
    public answers: { id: number; letter: string; text: string; isCorrect: boolean; }[];
    public quizNumber: string;
    public isDeactivated: boolean;
    public updatedMessage = false;
    public sourceUrl: string;
    public sourceLabel: string;
    public questionTranslationId: string;
    public learningPackageTranslationId: string;
    private subscription: Subscription;
    public shouldShowMessage: boolean = false;

    constructor(private botatoChatManagerService: BotatoChatManagerService,
                private responseItemDataTextService: ResponseItemDataTextService,
                private deactivateMessageService: DeactivateMessageService,
                private changeDetectorRef: ChangeDetectorRef) {
        super();
    }


    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        if (this.message.type === SingleTextChoiceQuizComponent.TYPE) {
            this.shouldShowMessage = true;
            this.updatedMessage = this.isMessageUpdated();
            if (this.updatedMessage) {
                this.createMessageUpdateData();
            } else {
                this.subscription = this.deactivateMessageService.deactivateOldCustomMessage(this.message.data);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                this.isDeactivated = this.message.data.deactivated !== undefined ? this.message.data.deactivated : false;

                this.createMessageData();
            }

        }

        if (this.message.type === SingleTextChoiceQuizComponent.UPDATE_TYPE) {
            this.subscription = this.deactivateMessageService.setUpdateOfLastMessage(this.message.data, this.changeDetectorRef);
        }
    }

    public getLetterFromAlphabet(index: number): string {
        return String.fromCharCode(65 + index);
    }

    public sendAnswer(answer: string): void {
        this.botatoChatManagerService.sendUserMessage(answer, 'quickreply');
    }

    public isMessageUpdated(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.message.data.updateMessage?.responseItemData !== undefined;
    }

    public mediaLoaded(): void {
        this.componentRenderingCompleted();
    }

    private createMessageData(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        this.responseItemData = this.message.data.responseItemData;
        this.question = this.responseItemData.quiz.quiz;
        this.quizNumber = this.responseItemData.learningSessionData.quizNumber;
        this.answers = this.responseItemData.quiz.answers.map((answer, index: number) => {
            return {
                id: answer.id,
                letter: this.getLetterFromAlphabet(index),
                text: this.responseItemDataTextService.replaceText(answer.answerText),
                isCorrect: answer.isCorrectAnswer
            };
        });
    }

    private createMessageUpdateData(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        this.responseItemData = this.message.data.updateMessage.responseItemData;
        this.question = this.responseItemData.quizAnswerData.quiz;
        this.quizNumber = this.responseItemData.quizAnswerData.quizNumber;
        this.sourceUrl = this.responseItemData.quizAnswerData.sourceUrl;
        this.sourceLabel = this.responseItemData.quizAnswerData.sourceLabel;
        this.questionTranslationId = this.responseItemData.quizAnswerData.id;
        this.learningPackageTranslationId = this.responseItemData.learningPackageTranslationId;
        this.answers = this.responseItemData.quizAnswerData.answers.map((answer, index: number) => {
            return {
                id: answer.id,
                letter: this.getLetterFromAlphabet(index),
                text: this.responseItemDataTextService.replaceText(answer.answerText),
                isCorrect: answer.isCorrectAnswer
            };
        });
    }

    public override onAnyMessageSent(): void {
        if (!this.isDeactivated) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.message.data.deactivated = true;
        }
    }
}
