<div class="pa-message-content micromate-message-content">
    <div class="micromate-learning-package-introduction" [innerHTML]="text.learningPackage | emoji">
    </div>
    <div *ngIf="responseItemData.firstTime && introduction !== undefined"
         class="micromate-learning-package-introduction-learningPackage-info"
         [innerHTML]="introduction | emoji">
    </div>
    <div class="micromate-learning-package-introduction-learning-goals"
         [innerHTML]="getLearningGoalInfo(text.learningGoals, text.learningStatement) | emoji">
    </div>
    <div (click)="navigateToLearningProgress()"
         class="micromate-profile-link">
        <app-icon [icon]="'chart-line-up-light'" class="micromate-profile-link-icon"></app-icon>
        {{createProfileText(text.learningGoalsButton)}}
    </div>
</div>
