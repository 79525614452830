import {Component, Input} from '@angular/core';
import {Quiz} from '../../../types/quiz';
import {QuestionPreviewService} from '../../../question-preview.service';

@Component({
    selector: 'app-free-text-question-preview',
    templateUrl: './free-text-question-preview.component.html',
    styleUrls: ['./free-text-question-preview.component.scss']
})
export class FreeTextQuestionPreviewComponent {
    @Input()
    public questionPreview: Quiz;

    @Input()
    public type: 'full' | 'basic';

    constructor(public questionPreviewService: QuestionPreviewService) {
    }

    public getAnswers(): string[] {
        return this.questionPreview.answers as string[];
    }
}
