import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../EnvironmentService';
import {app} from '@microsoft/teams-js';

@Injectable({
    providedIn: 'root'
})
export class LearnerRessource {

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public getLearnerLanguage(msteamsId: string, context: app.Context): Promise<{ language: string; }> {
        return this.http.post<{ language: string; }>(`${this.environmentService.environment.serverUrl}/api/profile/language?msteamsid=${msteamsId}`, context).toPromise();
    }
}

