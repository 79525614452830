import {Component, Injector, OnInit} from '@angular/core';
import {ImmediatelyRenderedMessageComponent} from 'botato-angular-lib';
import {LearningPackageIntroductionResponseItemData} from './LearningPackageIntroductionResponseItemData';
import {LearningPackageIntroductionResponseItemText} from './LearningPackageIntroductionResponseItemText';
import {ResponseItemDataTextService} from '../../response-item-data-text.service';
import {TAB_NAVIGATION_SERVICE, TabNavigationService} from '../../../../services/TabNavigationService';

@Component({
    selector: 'mm-learning-package-introduction',
    templateUrl: './learning-package-introduction.component.html',
    styleUrls: ['./learning-package-introduction.component.scss']
})
export class LearningPackageIntroductionComponent extends ImmediatelyRenderedMessageComponent implements OnInit {

    public static TYPE = 'LearningPackageIntroduction';
    public responseItemData: LearningPackageIntroductionResponseItemData;
    public text: LearningPackageIntroductionResponseItemText;
    public introduction: string;

    private tabNavigationService: TabNavigationService;

    constructor(private responseItemDataTextService: ResponseItemDataTextService, injector: Injector) {
        super();
        this.tabNavigationService = injector.get(TAB_NAVIGATION_SERVICE);
    }

    public ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.responseItemData = this.message.data.responseItemData as LearningPackageIntroductionResponseItemData;
        this.text = this.responseItemDataTextService.create<LearningPackageIntroductionResponseItemText>(this.responseItemData.text);
        if (this.responseItemData.learningPackageIntroduction !== undefined) {
            this.introduction = this.responseItemDataTextService.replaceText(this.responseItemData.learningPackageIntroduction);
        }
    }

    public createProfileText(text: string): string {
        return text.replace('➤  ', '');
    }

    public getLearningGoalInfo(learningGoalsText: string, learningStatementText: string): string {
        return `${learningGoalsText} ${learningStatementText}`;
    }

    public navigateToLearningProgress(): void {
        this.tabNavigationService.navigateToStudyProgress(false);
    }
}
