import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../EnvironmentService';
import {FeedbackAllowRequestDto} from './api-types/FeedbackAllowRequestDto';
import {Observable} from 'rxjs';
import {CreateFeedbackRequestDto} from './api-types/CreateFeedbackRequestDto';
import {Quiz} from '../../components/question-preview/types/quiz';

@Injectable({
    providedIn: 'root'
})
export class FeedbackRessource {

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public isFeedbackAllow(msteamsId: string, feedbackAllowRequestDto: FeedbackAllowRequestDto): Observable<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/profile/feedback/allowed?msteamsid=${msteamsId}`, feedbackAllowRequestDto, {responseType: 'text'});
    }

    public sendFeedback(msteamsId: string, createFeedbackRequestDto: CreateFeedbackRequestDto): Observable<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/profile/feedback?msteamsid=${msteamsId}`, createFeedbackRequestDto, {responseType: 'text'});
    }

    public getQuestionPreview(msteamsId: string, learningPackageId: string, questionId: string): Observable<Quiz> {
        return this.http.get<Quiz>(`${this.environmentService.environment.serverUrl}/api/profile/feedback/learningPackage/${learningPackageId}/question/${questionId}?msteamsid=${msteamsId}`);
    }


    public isFeedbackAllowForLearn(userId: string, feedbackAllowRequestDto: FeedbackAllowRequestDto): Observable<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/learn/profile/feedback/allowed?zitadelid=${userId}`, feedbackAllowRequestDto, {responseType: 'text'});
    }

    public sendFeedbackForLearn(userId: string, createFeedbackRequestDto: CreateFeedbackRequestDto): Observable<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/learn/profile/feedback?zitadelid=${userId}`, createFeedbackRequestDto, {responseType: 'text'});
    }

    public getQuestionPreviewForLearn(userId: string, learningPackageId: string, questionId: string): Observable<Quiz> {
        return this.http.get<Quiz>(`${this.environmentService.environment.serverUrl}/api/learn/profile/feedback/learningPackage/${learningPackageId}/question/${questionId}?zitadelid=${userId}`);
    }
}
