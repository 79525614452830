import {VideoDisplay, VideoDisplayType, VideoFormat, VideoFormatType} from './VideoFormat';
import {VideoLink} from './VideoLink';

export class DartfishVideo {
    public static TYPE: VideoFormatType = VideoFormat.DARTFISH;
    public static DISPLAY: VideoDisplayType = VideoDisplay.IFRAME;


    public static createVideoLink(): VideoLink {
        return new VideoLink(DartfishVideo.TYPE, DartfishVideo.DISPLAY);
    }

    public static isVideoFormat(url: string): boolean {
        const regex = new RegExp('^(https?\\:\\/\\/)?((www\\.)?dartfish\\.tv)\\/.+$');
        return regex.test(url);
    }
}
