<app-popup-container>
    <app-popup-header class="mm-feedback-popup-header">
        <app-icon class="mm-feedback-popup-icon-title" icon="message-pen-light"></app-icon>
        <app-popup-header-title title="{{'feedback.topicTitle' | translate}}"></app-popup-header-title>
    </app-popup-header>
    <app-popup-content class="mm-feedback-content">
        <mm-feedback-topic *ngFor="let activeTopic of activeTopics"
                           (topicClicked)="setFeedbackCategory(activeTopic.category)"
                           [topicTitle]="activeTopic.translation | translate">
        </mm-feedback-topic>
    </app-popup-content>
    <app-popup-footer *ngIf="feedbackPopupService.openIn === 'question'">
        <app-popup-wizard-footer
            [isBackVisible]="true"
            [hasCustomContent]="false"
            (navigateBackClicked)="navigateBackToMainTopic()"
            [isForwardDisabled]="false"
            [isForwardVisible]="false">
        </app-popup-wizard-footer>
    </app-popup-footer>
</app-popup-container>
