<app-popup-container>
    <app-popup-header class="mm-feedback-popup-header">
        <app-icon class="mm-feedback-popup-icon-title" icon="message-pen-light"></app-icon>
        <app-popup-header-title title="{{'feedback.mainTopicTitle' | translate}}"></app-popup-header-title>
    </app-popup-header>
    <app-popup-content class="mm-feedback-content">
        <mm-feedback-topic
            (topicClicked)="openQuestionsFeedbackTopics()"
            [topicTitle]="'feedback.topicQuestionButton' | translate">
        </mm-feedback-topic>
        <mm-feedback-topic
            (topicClicked)="openLearningPackageFeedbackTopics()"
            [topicTitle]="'feedback.topicLearningPackageButton' | translate">
        </mm-feedback-topic>
    </app-popup-content>
</app-popup-container>
