<mm-navigation [activeNavigation]="'scoreboard'"></mm-navigation>
<div class="micromate-learn-component">
    <div class="micromate-learn-context">
        <mm-ranking
            [darkMode]="false"
            (periodSelected)="periodSelected($event)"
            [rankingData]="scoreboardData$">
        </mm-ranking>
    </div>

</div>


