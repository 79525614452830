import {FormGroup} from '@angular/forms';
import {RegistrationComponentFormData} from '../RegistrationComponentFormData';
import {RegistrationService} from '../registration.service';
import {RegistrationLearnerDataResponseDto} from '../../core/rest/api-types/RegistrationLearnerDataResponseDto';
import {RegistrationComponentFormDataValues} from '../RegistrationComponentFormDataValues';
import {RegistrationType} from '../registrationType';
import {RegistrationStrategy} from './RegistrationStrategy';
import {Result} from './Result';

export class InvitationStrategy implements RegistrationStrategy {

    public type: RegistrationType = RegistrationType.Invitation;
    public shouldShowOrganisationInput: boolean = true;
    public shouldShowConfirmation: boolean = false;
    public titleTranslationKey: string = 'registration.flows.invitation.title';

    private userId: string;

    constructor(private registrationService: RegistrationService) {
    }

    public enableForm(): void {
        // Nothing to enable here
    }

    public async initialize(formData: FormGroup<RegistrationComponentFormData>, userId: string): Promise<Result> {
        const registrationData = await this.registrationService.loadLearnerData(userId);
        if (registrationData.alreadyInitialized) {
            return new Result(false, 'registration.flows.invitation.alreadyInitializedTitle', 'registration.flows.invitation.alreadyInitialized');
        } else {
            this.updateFormData(formData, registrationData);
            this.userId = userId;

            return new Result(true);
        }
    }

    private updateFormData(formData: FormGroup<RegistrationComponentFormData>, registrationCodeExchangeData: RegistrationLearnerDataResponseDto): void {
        formData.controls.email.setValue(registrationCodeExchangeData.email);
        formData.controls.organization.setValue(registrationCodeExchangeData.organizationName);
        formData.controls.email.disable();
        formData.controls.organization.disable();
    }

    public async execute(formData: RegistrationComponentFormDataValues): Promise<Result> {
        await this.registrationService.registerLearner(this.userId, formData);
        window.location.href = '/';
        return new Result(true);
    }
}

