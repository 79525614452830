import {Component, Input} from '@angular/core';
import {Popup} from '../popup/Popup';
import {MediaWebsitePopupData} from './MediaWebsitePopupData';

@Component({
    selector: 'mm-media-website-popup',
    templateUrl: './media-website-popup.component.html',
    styleUrls: ['./media-website-popup.component.scss']
})
export class MediaWebsitePopupComponent implements Popup<MediaWebsitePopupData> {
    @Input()
    public data: MediaWebsitePopupData;
}

