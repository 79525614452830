import {Injectable} from '@angular/core';
import {YouTubeVideo} from './videoLinkFormats/YouTubeVideo';
import {VideoLink} from './videoLinkFormats/VideoLink';
import {GoogleDriveVideo} from './videoLinkFormats/GoogleDriveVideo';
import {OneDriveVideo} from './videoLinkFormats/OneDriveVideo';
import {DefaultVideo} from './videoLinkFormats/DefaultVideo';
import {DartfishVideo} from './videoLinkFormats/DartfishVideo';

@Injectable({
    providedIn: 'root'
})
export class MovieService {
    public getVideoType(url: string): VideoLink {
        if (YouTubeVideo.isVideoFormat(url)) {
            return YouTubeVideo.createVideoLink();
        }

        if (GoogleDriveVideo.isVideoFormat(url)) {
            return GoogleDriveVideo.createVideoLink();
        }

        if (OneDriveVideo.isVideoFormat(url)) {
            return OneDriveVideo.createVideoLink();
        }

        if (DartfishVideo.isVideoFormat(url)) {
            return DartfishVideo.createVideoLink();
        }

        return DefaultVideo.createVideoLink();
    }
}
