<div *ngIf="mediaData !== undefined && ['movie', 'audio', 'website'].includes(mediaData?.mediaType)"
     [class.micromate-media-iframe-full-screen]="showIframe && size === 'fullScreen'"
     [class.micromate-media-iframe]="showIframe && size === 'medium'"
     [class.micromate-media-iframe-full-screen-popup]="showIframe && size === 'insidePopup'">
    <div *ngIf="mediaData?.mediaType === 'movie'" class="micromate-media-element">
        <ng-container *ngIf="videoLink.display === 'video'">
            <video (load)="mediaLoadingFinished()" *ngIf="!invalidMedia" [autoplay]="autoplay"
                   class="micromate-media-video" controls>
                <source [src]="mediaData.mediaUrl">
                Your browser does not support HTML video.
            </video>
            <div *ngIf="invalidMedia" class="micromate-media-error">
                Es ist ein Fehler aufgetreten. Das Video ist derzeit nicht verfügbar.
            </div>
        </ng-container>
        <mm-iframe-media (mediaLoaded)="mediaLoadingFinished()"
                         *ngIf="videoLink.display === 'iframe'"
                         [mediaLink]="mediaData.mediaUrl"
                         class="micromate-media-iframe-component"></mm-iframe-media>
    </div>

    <div *ngIf="mediaData?.mediaType === 'audio'" class="micromate-media-element micromate-audio-element">
        <div *ngIf="!invalidMedia" class="micromate-media-image">
            <img [src]="getAudioImageUrl()" alt="Audio Image" class="micromate-media-image-element"/>
        </div>
        <audio (load)="mediaLoadingFinished()" *ngIf="!invalidMedia" [autoplay]="autoplay" class="micromate-media-audio"
               controls>
            <source [src]="mediaData.mediaUrl">
            Your browser does not support the audio tag.
        </audio>

        <div *ngIf="invalidMedia" class="micromate-media-error">
            Es ist ein Fehler aufgetreten. Audio ist derzeit nicht verfügbar.
        </div>
    </div>

    <div *ngIf="mediaData?.mediaType === 'website'" class="micromate-media-element">
        <mm-iframe-media (mediaLoaded)="mediaLoadingFinished()"
                         [mediaLink]="mediaData.mediaUrl"
                         class="micromate-media-iframe-component"></mm-iframe-media>
    </div>
</div>
