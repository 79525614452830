import * as moment from 'moment';
import {Moment} from 'moment';
import {RankingPeriod} from '../../types/RankingPeriod';

export class RankingHelper {
    public getPeriodDate(period: RankingPeriod): Moment {
        const date = moment();
        switch (period) {
            case 'current-month':
            case 'current-year':
                return date;
            case 'last-month':
                return date.subtract(1, 'months').endOf('month');
            case 'last-year':
                return date.subtract(1, 'years').endOf('year');
            default:
                throw new Error(`Unknown period in getPeriodDate: ${period}`);
        }
    }

    public getPeriod(period: RankingPeriod): string {
        switch (period) {
            case 'current-month':
            case 'last-month':
                return 'monthly';
            case 'current-year':
            case 'last-year':
                return 'yearly';
            default:
                throw new Error(`Unknown period in getPeriod: ${period}`);
        }
    }
}
