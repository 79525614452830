<div class="micromate-profile-study-progress-content-tab-learning-progress">
    <div class="micromate-profile-study-progress-value" *ngIf="learningPackageIsAvailable">
        <div class="micromate-profile-study-progress-value-percentage">
            <div class="micromate-profile-study-progress-value-percentage-value">
                {{studyProgress}}</div>
            <div class="micromate-profile-study-progress-value-percentage-symbol">%</div>
        </div>
        <div
            [class.micromate-profile-study-progress-value-name-negative]="learningGoal !== undefined && studyProgress < learningGoal"
            class="micromate-profile-study-progress-value-name">
            {{('learningGoal.' + studyProgressScaleName) | translate}}
        </div>
    </div>
    <div class="micromate-profile-study-progress-value micromate-profile-study-progress-not-available"
         *ngIf="!learningPackageIsAvailable" [title]="'learningGoal.inactive' | translate">
        <app-icon class="micromate-profile-study-progress-not-available-icon" icon="ban-regular"></app-icon>
    </div>
    <div class="micromate-profile-study-progress-learning-goal"
         [class.micromate-profile-study-progress-not-available-learning-goal]="!learningPackageIsAvailable">
        <div *ngIf="learningGoal !== undefined"
             class="micromate-profile-study-progress-goal">
            <mm-learning-progress-visualization-bar
                [learningGoal]="learningGoal"
                [studyProgressScaleName]="studyProgressScaleName"
                [studyProgress]="studyProgress"
                [learningPackageIsAvailable]="learningPackageIsAvailable"
                [manualActivation]="manualActivation"
                [reachableLearningProgress]="reachableLearningProgress"
            ></mm-learning-progress-visualization-bar>
        </div>
        <div class="micromate-profile-study-progress-no-goal">
            <div *ngIf="learningGoal === undefined"
                 class="micromate-profile-study-progress-bar">
                <div *ngIf="learningPackageIsAvailable && manualActivation && reachableLearningProgress < 100"
                     [ngStyle]="{ 'margin-left': reachableLearningProgress + '%', 'width':  (100 - reachableLearningProgress) + '%'}"
                     class="micromate-profile-study-progress-bar-inactive micromate-profile-study-progress-bar-positive-inactive">
                </div>
                <div *ngIf="learningPackageIsAvailable"
                     [ngStyle]="{ 'margin-left': getStudyProgressPointPosition(studyProgress) }"
                     class="micromate-profile-study-progress-bar-point micromate-profile-study-progress-bar-point-positive">
                </div>
            </div>
        </div>
    </div>
</div>
