import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfilePseudonymService {
    private newPseudonym = new BehaviorSubject<string>(undefined);

    private generatingPseudonym = new BehaviorSubject<boolean>(false);
    public generatingPseudonym$ = this.generatingPseudonym.asObservable();

    public pseudonymGenerationSubscription = new Subscription();

    public setNewPseudonym(newPseudonym: string): void {
        this.newPseudonym.next(newPseudonym);
        this.generatingPseudonym.next(false);
    }

    public getNewPseudonym(): string {
        return this.newPseudonym.getValue();
    }

    public startPseudonymGeneration(): void {
        this.generatingPseudonym.next(true);
    }

    public reset(): void {
        this.newPseudonym.next(undefined);
        this.generatingPseudonym.next(false);
    }

    public cancelPseudonymGeneration(): void {
        this.pseudonymGenerationSubscription.unsubscribe();
    }
}
