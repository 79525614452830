import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Popup} from '../popup/Popup';
import {FeedbackPopupData} from './FeedbackPopupData';
import {FEEDBACK_RESOURCE_TOKEN, FeedbackService} from '../../services/FeedbackService';
import {FeedbackAllowRequestDto} from '../../services/rest/api-types/FeedbackAllowRequestDto';
import {Subscription} from 'rxjs';
import {FeedbackPopupService} from './feedback-popup.service';

@Component({
    selector: 'mm-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements Popup<FeedbackPopupData>, OnInit, OnDestroy {
    @Input()
    public data: FeedbackPopupData;

    private checkIfFeedbackPossibleSubscription = new Subscription();

    constructor(public feedbackPopupService: FeedbackPopupService,
                @Inject(FEEDBACK_RESOURCE_TOKEN) private feedbackResource: FeedbackService) {
    }

    public ngOnInit(): void {
        this.feedbackPopupService.showSpinner();
        const feedbackAllowRequestDto = new FeedbackAllowRequestDto(this.data.learningPackageId, this.data.questionId);
        this.checkIfFeedbackPossibleSubscription = this.feedbackResource.checkIfFeedbackAllow(feedbackAllowRequestDto).subscribe({
                next: () => {
                    this.feedbackPopupService.hideSpinner();
                    this.feedbackPopupService.setTopicPopup();
                },
                error: () => {
                    this.feedbackPopupService.setNotAllow(true);
                    this.feedbackPopupService.hideSpinner();
                    this.feedbackPopupService.currentStep = 'result';
                }
            }
        );
    }

    public ngOnDestroy(): void {
        this.checkIfFeedbackPossibleSubscription.unsubscribe();
        this.feedbackPopupService.clearPopupStates();
    }
}
