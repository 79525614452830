import {InjectionToken} from '@angular/core';
import {ResourceToDisplay} from '../components/chatbot/customItems/components/quiz-source/resource-to-display';

export const TAB_NAVIGATION_SERVICE = new InjectionToken<TabNavigationService>('TabNavigationService');

export abstract class TabNavigationService {

    public abstract isRunningInWidget(): boolean;

    public abstract navigateToChat(addOpenFromNavigationParameter: boolean): void;

    public abstract navigateToBadges(addOpenFromNavigationParameter: boolean): void;

    public abstract navigateToStudyProgress(addOpenFromNavigationParameter: boolean): void;

    public abstract navigateToScoreboard(addOpenFromNavigationParameter: boolean): void;

    public abstract navigateToProfile(addOpenFromNavigationParameter: boolean): void;

    public abstract closeProfile(): void;

    public abstract toggleProfileVisible(): void;

    public abstract shouldNavigateToUrlWhenOpeningResource(): boolean;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public showResource(resourceToShow: ResourceToDisplay): void {
        // nothing to do per default
    }

    public closeResource(): void {
        // nothing to do per default
    }

    public abstract isMicromateResourceUrl(sourceUrl: string): boolean;
}
