import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'mm-feedback-topic',
    templateUrl: './feedback-topic.component.html',
    styleUrls: ['./feedback-topic.component.scss'],

})
export class FeedbackTopicComponent {
    @Input()
    public topicTitle: string;

    @Output()
    public topicClicked: EventEmitter<void> = new EventEmitter<void>();
}
