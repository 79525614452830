<mm-navigation [activeNavigation]="'study-progress'"></mm-navigation>
<div class="micromate-learn-component">
    <div class="micromate-learn-context">
        <mm-study-progress
            [changDetectionAfterTurn]="false"
            [darkMode]="false"
            [studyProgressesData]="studyProgresses$"
            (learnPackageActivated)="learnPackage($event)">
        </mm-study-progress>
    </div>
</div>

