export class CreateFeedbackRequestDto {
    constructor(public feedback: string,
                public feedbackCategory: FeedbackCategoryTypes,
                public learningPackageTranslationId: string,
                public quizTranslationId?: string) {
    }

}

export enum FeedbackCategoryTypes {
    QuestionFaulty = 'questionFaulty',
    QuestionUnintelligible = 'questionUnintelligible',
    QuestionSpelling = 'questionSpelling',
    QuestionIdea = 'questionIdea',
    LearningPackageQuestionSuggestion = 'learningPackageQuestionSuggestion',
    LearningPackageTopicSuggestion = 'learningPackageTopicSuggestion',
    LearningPackageFeedback = 'learningPackageFeedback'
}
