import {Component, Input} from '@angular/core';
import {Quiz} from '../../../types/quiz';
import {Answer} from '../../../types/answer';
import {QuestionPreviewService} from '../../../question-preview.service';

@Component({
    selector: 'app-multiple-choice-question-preview',
    templateUrl: './multiple-choice-question-preview.component.html',
    styleUrls: ['./multiple-choice-question-preview.component.scss']
})
export class MultipleChoiceQuestionPreviewComponent {
    @Input()
    public questionPreview: Quiz;

    @Input()
    public type: 'full' | 'basic';

    constructor(public questionPreviewService: QuestionPreviewService) {
    }

    public getAnswers(): Answer[] {
        return this.questionPreview.answers as Answer[];
    }
}
