import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {RegistrationLearnerDataExchangeRequestDto} from './api-types/RegistrationLearnerDataExchangeRequestDto';
import {RegistrationRequestDto} from './api-types/RegistrationRequestDto';
import {PasswordComplexityPolicyResponseDto} from './api-types/PasswordComplexityPolicyResponseDto';
import {RegistrationLearnerDataResponseDto} from './api-types/RegistrationLearnerDataResponseDto';
import {firstValueFrom} from 'rxjs';

import {OrganizationNameForCodeResponseDto} from '../../registration/organizationNameForCodeResponseDto';
import {SelfRegistrationResponseDto} from './self-registration-response.dto';
import {SelfRegistrationRequestDto} from './api-types/SelfRegistrationRequestDto';

@Injectable({
    providedIn: 'root'
})
export class RegistrationRessource {

    constructor(private http: HttpClient) {
    }

    public loadLearnerData(dto: RegistrationLearnerDataExchangeRequestDto): Promise<RegistrationLearnerDataResponseDto> {
        return firstValueFrom(this.http.post<RegistrationLearnerDataResponseDto>(`${environment.serverUrl}/api/registration/learner-data-exchange`, dto));
    }

    public register(registrationLearner: RegistrationRequestDto): Promise<void> {
        return firstValueFrom(this.http.post<void>(`${environment.serverUrl}/api/registration/registration`, registrationLearner));
    }

    public selfRegister(registrationLearner: SelfRegistrationRequestDto): Promise<SelfRegistrationResponseDto> {
        return firstValueFrom(this.http.post<SelfRegistrationResponseDto>(`${environment.serverUrl}/api/registration/self-registration`, registrationLearner));
    }

    public getPasswordComplexityPolicy(): Promise<PasswordComplexityPolicyResponseDto> {
        return firstValueFrom(this.http.get<PasswordComplexityPolicyResponseDto>(`${environment.serverUrl}/api/registration/password-complexity-policy`));
    }

    public exchangeOrgCodeToOrganizationName(orgCode: string): Promise<OrganizationNameForCodeResponseDto> {
        return firstValueFrom(this.http.get<OrganizationNameForCodeResponseDto>(`${environment.serverUrl}/api/registration/organization-name-for-code`, {
            params: {
                code: orgCode
            }
        }));
    }
}

