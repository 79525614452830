<div *ngIf="checkIfQuestionHaveMedia(quizMedia)" [class.micromate-quiz-media-element]="mediaData !== undefined"
     class="micromate-quiz-media">

    <div *ngIf="mediaData !== undefined; else image">
        <div *ngIf="mediaData.mediaType === 'website'">
            <div class="micromate-quiz-hint"
                 [innerHTML]="questionStaticText.hintMediaWebsiteMessage | emoji">
            </div>
            <div class="micromate-image-container">
                <img (load)="emitMediaLoaded()"
                     [attr.src]="createAdaptiveCardImageUrl(quizMedia.mediaType, quizMedia.imageUrl)"
                     class="pa-image micromate-image"
                     (click)="openWebsideMedia()">
            </div>
        </div>

        <mm-media (mediaLoaded)="emitMediaLoaded()"
                  *ngIf="mediaData.mediaType === 'audio' || mediaData.mediaType === 'movie'"
                  [autoplay]="false"
                  [mediaData]="mediaData" class="pa-image"></mm-media>
    </div>


    <ng-template #image>
        <div class="micromate-image-container">
            <img (load)="emitMediaLoaded()"
                 [attr.src]="createAdaptiveCardImageUrl(quizMedia.mediaType, quizMedia.imageUrl)"
                 class="pa-image micromate-image"
                 (click)="showImageInLarge(createAdaptiveCardImageUrl(quizMedia.mediaType, quizMedia.imageUrl))">

            <div class="micromate-expand-icon">
                <app-icon [icon]="'expand'"
                          (click)="showImageInLarge(createAdaptiveCardImageUrl(quizMedia.mediaType, quizMedia.imageUrl))"></app-icon>
            </div>
        </div>
    </ng-template>

</div>
