<div class="mm-container" *ngIf="resourceViewerService.resourceNotAvailable$ | async">
    <app-message-box class="mm-warning"
                     type="warning"
                     header="Ressource nicht verfügbar"
                     content="Die angefragte Ressource ist leider nicht verfügbar.">
    </app-message-box>
</div>

<div class="mm-container" *ngIf="resourceViewerService.loading$ | async">
    <mm-spinner class="mm-spinner"></mm-spinner>
</div>

<ng-container *ngIf="resourceViewerService.resourceUrl$ | async">
    <iframe class="mm-resource-container" [src]="resourceViewerService.resourceUrl$ | async | safe"></iframe>
</ng-container>

