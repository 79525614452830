import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../EnvironmentService';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConversationRessource {
    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public selectLearningPackage(learningPackageId: string): Promise<void> {
        const url: string = `${this.environmentService.environment.serverUrl}/api/learn/conversation/learning-package/${learningPackageId}/activate`;
        return firstValueFrom(this.http.put<void>(url, undefined, {responseType: 'json'}));
    }

    public selectLearningPackageInProfile(learningPackageId: string, msteamsId: string): Promise<void> {
        const url: string = `${this.environmentService.environment.serverUrl}/api/profile/learning-package/${learningPackageId}/activate?msteamsid=${msteamsId}`;
        return firstValueFrom(this.http.put<void>(url, undefined, {responseType: 'json'}));
    }
}
