import {ResourceEventType, TabEventType} from './TabEventType';
import {TabOpenedEventPayload} from './TabOpenedEventPayload';
import {TabRankingChangedEventPayload} from './TabRankingChangedEventPayload';
import {ResourceOpenEventPayload} from './ResourceOpenEventPayload';

export class EventData {
    constructor(public type: TabEventType | ResourceEventType,
                public payload: TabOpenedEventPayload | TabRankingChangedEventPayload | ResourceOpenEventPayload) {
    }
}
