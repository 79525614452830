import {Injectable, Type} from '@angular/core';
import {PopupComponent} from './popup.component';
import {PopupOptions} from './PopupOptions';
import {Popup} from './Popup';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {PopupClosable} from './PopupClosable';

@Injectable({
    providedIn: 'root'
})
export class PopupService {

    private popupComponent: PopupComponent;
    private popupResolveFunction: (data: unknown) => void;

    private isPopupOpen: Subject<boolean> = new BehaviorSubject(false);
    public isPopupOpen$: Observable<boolean> = this.isPopupOpen.asObservable();

    private isWorkInProgress: Subject<boolean> = new BehaviorSubject(false);
    public isWorkInProgress$: Observable<boolean> = this.isWorkInProgress.asObservable();

    public initialize(popupComponent: PopupComponent): void {
        this.popupComponent = popupComponent;
    }

    public async open<TData, TResult = unknown>(contentComponentType: Type<Popup<TData>>, data?: TData, popupOptions: PopupOptions = new PopupOptions()): Promise<TResult> {
        this.isPopupOpen.next(true);

        return new Promise(resolve => {
            const createdComponent = this.popupComponent.viewContainerRef.createComponent<Popup<TData>>(contentComponentType);
            createdComponent.instance.data = data;

            this.popupComponent.isOpen = true;
            this.popupComponent.setOptions(popupOptions);

            this.popupResolveFunction = resolve;
        });

    }

    public setIsClosable(isClosable: PopupClosable): void {
        this.popupComponent.setIsClosable(isClosable);
    }

    public setWorkInProgress(state: boolean): void {
        this.isWorkInProgress.next(state);
        this.popupComponent.setWorkInProgress(state);
    }

    public close<TResult>(result?: TResult): void {
        this.popupComponent.viewContainerRef.clear();
        this.popupComponent.isOpen = false;
        this.popupResolveFunction(result);
        this.isPopupOpen.next(false);
        this.popupResolveFunction = undefined;
    }
}
