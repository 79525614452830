import {Inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import {AuthUntilUserRoleService} from '../auth-until-user-role.service';
import {LearnAuthService} from '../learn-auth.service';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard  {

    constructor(private authUntilUserRoleService: AuthUntilUserRoleService,
                @Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LearnAuthService,
                private router: Router) {
    }

    public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        const expectedRoles = next.data?.['mustBe'] as string[] ?? [];
        const availableRoles = this.authService.getAuthenticatedUser().roles ?? [];
        if (!this.assertRoles(expectedRoles, availableRoles)) {
            this.authService.removeSelectedUser();
            return this.router.createUrlTree(['/missing-role']);
        }

        return true;
    }

    public assertRoles(expectedRoles: string[], availableRoles: string[]): boolean {
        if (expectedRoles.length > 0) {
            if (availableRoles.length > 0) {
                return availableRoles.some(role => expectedRoles.includes(role));
            }
            return false;
        }
        return true;
    }
}
