<div *ngIf="(authService.authentication$ | async)" class="micromate-learn">
    <div class="micromate-already-connected" *ngIf="alreadyConnected$ | async">
        <span class="micromate-already-connected-message">{{'general.alreadyConnected' | translate}}</span>
        <button class="micromate-already-connected-button"
                [attr.disabled]="actionInProgress ? true : null"
                (click)="reconnect()">{{'general.useMicromateHere' | translate}}</button>
    </div>

    <ng-container *ngIf="!(alreadyConnected$ | async) && isConnected">
        <router-outlet></router-outlet>
    </ng-container>
</div>

