import {Component, Input} from '@angular/core';

@Component({
    selector: 'mm-learning-progress-visualization',
    templateUrl: './learning-progress-visualization.component.html',
    styleUrls: ['./learning-progress-visualization.component.scss']
})
export class LearningProgressVisualizationComponent {
    @Input() studyProgress: number;
    @Input() learningGoal: number;
    @Input() studyProgressScaleName: string;
    @Input() learningPackageIsAvailable: boolean;
    @Input() manualActivation: boolean;
    @Input() reachableLearningProgress: number;

    public getStudyProgressPointPosition(studyProgress: number): string {
        const shift = Math.round((studyProgress * 22) / 100);
        return `calc(${studyProgress}% - ${shift}px)`;
    }
}
