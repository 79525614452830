import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TabNavigationService} from '../../../../micromate-learn-lib/src/lib/services/TabNavigationService';
import {NavigationExtras, Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NavigationService extends TabNavigationService {
    private activeNavigation = new BehaviorSubject<boolean>(false);
    public activeNavigation$ = this.activeNavigation.asObservable();

    private isProfileVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isProfileVisible$: Observable<boolean> = this.isProfileVisible.asObservable();

    constructor(private router: Router) {
        super();
    }

    public closeProfile(): void {
        this.isProfileVisible.next(false);
        this.navigateToChat(true);
    }

    public toggleProfileVisible(): void {
        const isProfileVisible = this.isProfileVisible.getValue();
        this.isProfileVisible.next(!isProfileVisible);
    }

    public setActiveNavigation(status: boolean): void {
        this.activeNavigation.next(status);
    }

    public getActiveNavigation(): boolean {
        return this.activeNavigation.value;
    }

    public navigateToBadges(addOpenFromNavigationParameter: boolean): void {
        this.navigate(['learn', 'badges'], addOpenFromNavigationParameter);
    }

    public navigateToChat(addOpenFromNavigationParameter: boolean): void {
        this.navigate(['/'], addOpenFromNavigationParameter);
    }

    public navigateToScoreboard(addOpenFromNavigationParameter: boolean): void {
        this.navigate(['learn', 'rangliste'], addOpenFromNavigationParameter);
    }

    public navigateToStudyProgress(addOpenFromNavigationParameter: boolean): void {
        this.navigate(['learn', 'lernfortschritt'], addOpenFromNavigationParameter);
    }

    public navigateToProfile(addOpenFromNavigationParameter: boolean): void {
        this.navigate(['learn', 'profil'], addOpenFromNavigationParameter);
    }

    private navigate(path: string[], addOpenFromNavigationParameter: boolean): void {
        const parameters: NavigationExtras = addOpenFromNavigationParameter === true ? this.createNavigationParameters() : undefined;
        void this.router.navigate(path, parameters);
    }

    private createNavigationParameters(): NavigationExtras {
        return {
            queryParams: {openInConversation: 'true'}
        };
    }

    public shouldNavigateToUrlWhenOpeningResource(): boolean {
        return false;
    }

    public isRunningInWidget(): boolean {
        return false;
    }

    public isMicromateResourceUrl(sourceUrl: string): boolean {
        return sourceUrl.startsWith(environment.learnUrl);
    }
}
