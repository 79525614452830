import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LearningComponent} from './learning/learning/learning.component';
import {LearningStudyProgressComponent} from './learning/learning-study-progress/learning-study-progress.component';
import {LearningScoreboardComponent} from './learning/learning-scoreboard/learning-scoreboard.component';
import {LearningBadgeComponent} from './learning/learning-badge/learning-badge.component';
import {ProfileBadgeComponent} from './profile-pages/profile-badge/profile-badge.component';
import {ProfileScoreboardComponent} from './profile-pages/profile-scoreboard/profile-scoreboard.component';
import {ProfileStudyProgressComponent} from './profile-pages/profile-study-progress/profile-study-progress.component';
import {ProfileMediaPresentationComponent} from './profile-pages/profile-media-presentation/profile-media-presentation.component';
import {AuthGuard} from './core/authentication/guards/auth.guard';
import {UserRoles} from '../../../micromate-learn-lib/src/lib/types/roles';
import {LearningContainerComponent} from './learning/components/learning-container/learning-container.component';
import {ProfileProfileComponent} from './profile-pages/profile-profile/profile-profile.component';
import {LearningProfileComponent} from './learning/learning-profile/learning-profile.component';
import {CodeExchangeComponent} from './core/authentication/components/code-exchange/code-exchange.component';
import {AuthErrorComponent} from './core/authentication/components/auth-error/auth-error.component';
import {RoleGuard} from './core/authentication/guards/role.guard';
import {RegistrationComponent} from './registration/registration.component';
import {MissingRoleComponent} from './core/authentication/components/missing-role/missing-role.component';
import {ProfileQuestionFeedbackComponent} from './profile-pages/profile-question-feedback/profile-question-feedback.component';
import {ResourceViewerComponent} from './profile-pages/profile-resources/resource-viewer/resource-viewer.component';

const routes: Routes = [
    {
        path: 'code-exchange',
        component: CodeExchangeComponent,
    },
    {
        path: 'auth-error',
        component: AuthErrorComponent,
    },
    {
        path: 'missing-role',
        component: MissingRoleComponent,
    },
    {
        path: '',
        redirectTo: 'learn',
        pathMatch: 'full'
    },
    {
        path: 'resources/:resourceId',
        component: ResourceViewerComponent
    },
    {
        path: 'resources/:resourceId/selections/:selectionId',
        component: ResourceViewerComponent
    },
    {
        path: 'badges',
        component: ProfileBadgeComponent
    },
    {
        path: 'lernfortschritt',
        component: ProfileStudyProgressComponent
    },
    {
        path: 'rangliste',
        component: ProfileScoreboardComponent
    },
    {
        path: 'profil',
        component: ProfileProfileComponent
    },
    {
        path: 'media',
        component: ProfileMediaPresentationComponent
    },
    {
        path: 'questionFeedback',
        component: ProfileQuestionFeedbackComponent
    },
    {
        path: 'registration',
        component: RegistrationComponent,
    },
    {
        path: 'learn',
        children: [
            {
                path: '',
                component: LearningComponent
            },
            {
                path: 'badges',
                component: LearningBadgeComponent
            },
            {
                path: 'lernfortschritt',
                component: LearningStudyProgressComponent
            },
            {
                path: 'rangliste',
                component: LearningScoreboardComponent
            },
            {
                path: 'profil',
                component: LearningProfileComponent
            }
        ],
        component: LearningContainerComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
            mustBe: [UserRoles.SUPER_ADMINISTRATOR, UserRoles.LEARNER],
        }
    },
    {
        path: '**',
        redirectTo: 'learn',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
