import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, map, mergeMap, Observable, of, range, retryWhen, throwError, timer, zip} from 'rxjs';

@Injectable()
export class UnstableInternetConnectionHttpInterceptor implements HttpInterceptor {
    public static RETRIES: number = 2;
    public static DELAY: number = 250;

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            retryWhen((attempts) =>
                zip(range(1, UnstableInternetConnectionHttpInterceptor.RETRIES + 1), attempts)
                    .pipe(
                        mergeMap(([attempt, error]: [number, HttpErrorResponse]) => this.handleNoInternetConnectionError(attempt, error)),
                        map((attempt: number) => attempt * attempt),
                        mergeMap((increasingDelayValue: number) => timer(increasingDelayValue * UnstableInternetConnectionHttpInterceptor.DELAY))
                    )
            )
        );
    }

    public handleNoInternetConnectionError(attempt: number, error: HttpErrorResponse): Observable<unknown> {
        if (error.status === 0) {
            if (attempt > UnstableInternetConnectionHttpInterceptor.RETRIES) {
                console.warn('No or unstable internet connection at the moment');
                return of(EMPTY);
            } else {
                return of(attempt);
            }
        }
        return throwError(error);
    }
}
