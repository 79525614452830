import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BotatoChatManagerService, DelayedRenderedMessageComponent, DialogViewModelCreatorService} from 'botato-angular-lib';
import {QuizResponseItemData} from '../../type/QuizResponseItemData';
import {TrueFalseQuiz} from './TrueFalseQuiz';
import {TrueFalseQuizResponseItemDataText} from './TrueFalseQuizResponseItemDataText';
import {DeactivateMessageService} from '../../../deactivate-message.service';
import {Subscription} from 'rxjs';
import {TrueFalseQuizUpdateAnswer} from './TrueFalseQuizUpdateAnswer';
import {QuizQuestion} from '../../type/QuizQuestion';

@Component({
    selector: 'mm-true-false-quiz',
    templateUrl: './true-false-quiz.component.html'
})
export class TrueFalseQuizComponent extends DelayedRenderedMessageComponent implements OnInit, OnDestroy {

    public static TYPE = 'TrueFalse';
    public static UPDATE_TYPE = 'updateTrueFalseQuiz';

    public responseItemData: QuizResponseItemData<TrueFalseQuiz, TrueFalseQuizResponseItemDataText, TrueFalseQuizUpdateAnswer>;
    public question: QuizQuestion;
    public negativeAnswer: {
        isCorrectAnswer: boolean;
    };
    public positiveAnswer: {
        isCorrectAnswer: boolean;
    };
    public quizNumber: string;
    public isDeactivated: boolean;
    public updatedMessage = false;
    public sourceUrl: string;
    public sourceLabel: string;
    public questionTranslationId: string;
    public learningPackageTranslationId: string;
    private subscription: Subscription;
    public shouldShowMessage: boolean = false;

    constructor(private botatoChatManagerService: BotatoChatManagerService,
                private deactivateMessageService: DeactivateMessageService,
                private dialogViewModelCreatorService: DialogViewModelCreatorService,
                private changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        if (this.message.type === TrueFalseQuizComponent.TYPE) {
            this.shouldShowMessage = true;
            this.updatedMessage = this.isMessageUpdated();
            if (this.updatedMessage) {
                this.createMessageUpdateData();
            } else {
                this.subscription = this.deactivateMessageService.deactivateOldCustomMessage(this.message.data);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                this.isDeactivated = this.message.data.deactivated !== undefined ? this.message.data.deactivated : false;

                this.createMessageData();
            }
        }

        if (this.message.type === TrueFalseQuizComponent.UPDATE_TYPE) {
            this.subscription = this.deactivateMessageService.setUpdateOfLastMessage(this.message.data, this.changeDetectorRef);
        }
    }

    public sendAnswer(answer: string): void {
        if (!this.isDeactivated) {
            void this.botatoChatManagerService.sendUserMessage(answer, 'quickreply');
        }
    }

    public isMessageUpdated(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.message.data.updateMessage?.responseItemData !== undefined;
    }

    public mediaLoaded(): void {
        this.componentRenderingCompleted();
    }

    private createMessageData(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        this.responseItemData = this.message.data.responseItemData;
        const quiz = this.responseItemData.quiz;
        this.question = quiz.quiz;
        this.quizNumber = this.responseItemData.learningSessionData.quizNumber;
        this.negativeAnswer = quiz.negativeAnswer;
        this.positiveAnswer = quiz.positiveAnswer;
    }

    private createMessageUpdateData(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        this.responseItemData = this.message.data.updateMessage.responseItemData;
        const quizAnswerData = this.responseItemData.quizAnswerData;
        this.sourceUrl = this.responseItemData.quizAnswerData.sourceUrl;
        this.sourceLabel = this.responseItemData.quizAnswerData.sourceLabel;
        this.questionTranslationId = this.responseItemData.quizAnswerData.id;
        this.learningPackageTranslationId = this.responseItemData.learningPackageTranslationId;
        this.question = quizAnswerData.quiz;
        this.quizNumber = quizAnswerData.quizNumber;
        this.negativeAnswer = quizAnswerData.negativeAnswer;
        this.positiveAnswer = quizAnswerData.positiveAnswer;
    }

    public override onAnyMessageSent(): void {
        if (!this.isDeactivated) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.message.data.deactivated = true;
        }
    }
}
