import {Injectable} from '@angular/core';
import {Quiz} from './types/quiz';

@Injectable({
    providedIn: 'root'
})
export class QuestionPreviewService {

    public shouldShowExplanation(questionPreview: Quiz, type: 'full' | 'basic'): boolean {
        if (questionPreview.explanation !== undefined) {
            if (type === 'basic') {
                return questionPreview.explanation.text !== undefined && questionPreview.explanation.text.length > 0;
            }
            if (type === 'full') {
                return questionPreview.explanation.mediaType !== undefined || (questionPreview.explanation.text !== undefined && questionPreview.explanation.text.length > 0);
            }
        }
        return false;
    }
}
