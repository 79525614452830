<h3 class="mm-question-preview-answers-title" *ngIf="type === 'full'">{{'questionPreview.answerTitle' | translate}}</h3>
<div class="mm-answer">
    <div [class.mm-answer-correct]="questionPreview.negativeAnswer.isCorrectAnswer"
         [class.mm-answer-wrong]="!questionPreview.negativeAnswer.isCorrectAnswer"
         class="mm-answer-true-false">
        {{'questionPreview.answerFalse' | translate}}
    </div>
    <div [class.mm-answer-correct]="questionPreview.positiveAnswer.isCorrectAnswer"
         [class.mm-answer-wrong]="!questionPreview.positiveAnswer.isCorrectAnswer"
         class="mm-answer-true-false">
        {{'questionPreview.answerTrue' | translate}}
    </div>
</div>
<app-explanation-question-preview
    *ngIf="questionPreviewService.shouldShowExplanation(questionPreview, type)"
    [explanation]="questionPreview.explanation"></app-explanation-question-preview>
<app-source-question-preview *ngIf="type === 'full' && questionPreview.sourceUrl !== undefined && questionPreview.sourceUrl.length > 0"
                             [sourceUrl]="questionPreview.sourceUrl"
                             [sourceLabel]="questionPreview.sourceLabel"></app-source-question-preview>
