<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 80.01 74.72" *ngIf="logo === 'micromate'">
    <defs>
        <style>.cls-1 {
            fill: url(#linear-gradient);
        }

        .cls-2 {
            fill: #f9ba41;
        }

        .cls-3 {
            fill: #fece4c;
        }

        .cls-4 {
            fill: #fcc445;
        }

        .cls-5 {
            fill: #f3a031;
        }

        .cls-6 {
            fill: #f8b13a;
        }

        .cls-7 {
            fill: #ee9842;
        }

        .cls-8 {
            fill: #f5a836;
        }

        .cls-9 {
            fill: #f4ba5d;
        }</style>
        <linearGradient id="linear-gradient" x1="64.45" y1="53" x2="22.74" y2="35.21" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#fdca2d"/>
            <stop offset="0.43" stop-color="#fbbd29"/>
            <stop offset="1" stop-color="#f7a521"/>
        </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
            <path class="cls-1"
                  d="M79.4,74.68H42.87A.88.88,0,0,1,42,73.49q2.22-6.1,4.45-12.22Q43.24,53.46,40,45.64L33.52,61.26,38,73.48a.88.88,0,0,1-.83,1.19H.61a.61.61,0,0,1-.58-.8l24-72.13L24.51.45a.66.66,0,0,1,1.25,0L40,36,54.25.43a.66.66,0,0,1,1.25,0l.43,1.29L80,73.88A.61.61,0,0,1,79.4,74.68Z"/>
            <path class="cls-2" d="M69,40.93l-30.94,0L54.25.43a.67.67,0,0,1,1.25,0Q62.25,20.7,69,40.93Z"/>
            <path class="cls-3"
                  d="M72.19,50.53Q76.09,62.2,80,73.88a.6.6,0,0,1-.58.8H42.87a.92.92,0,0,1-.66-.29,1,1,0,0,1-.17-.9q2.22-6.1,4.45-12.22Z"/>
            <path class="cls-4"
                  d="M79.08,40.93H38.31a.18.18,0,0,0-.16.25L51.82,74.42a.17.17,0,0,0,.3,0L79.5,41.82A.55.55,0,0,0,79.08,40.93Z"/>
            <path class="cls-5"
                  d="M7.27,52.15Q3.64,63,0,73.88a.72.72,0,0,0,.05.48.84.84,0,0,0,1,.32h36.1a.89.89,0,0,0,.62-.25.93.93,0,0,0,.21-.94q-2.22-6.11-4.45-12.22L8,49.94C7.83,50.49,7.57,51.24,7.27,52.15Z"/>
            <path class="cls-6"
                  d="M11,40.92H41.66a.18.18,0,0,0,.18-.25C36.66,27.46,26.35,1.23,25.76.42A.84.84,0,0,0,25.15,0a.69.69,0,0,0-.64.45Z"/>
            <path class="cls-7" d="M33.52,61.26l.39,1.07L29,74.68H28Z"/>
            <path class="cls-8"
                  d="M1,40.9H41.72a.18.18,0,0,1,.17.24L28.22,74.38a.18.18,0,0,1-.3.05L.54,41.79A.54.54,0,0,1,1,40.9Z"/>
            <path class="cls-9" d="M52,74.68h1.31Q62.88,63,72.48,51.38c-.1-.28-.19-.57-.29-.85Z"/>
        </g>
    </g>
</svg>
