import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TaskModuleMediaData} from '../../../../../micromate-learn-lib/src/lib/types/TaskModuleMediaData';

@Component({
    selector: 'mm-profile-media-presentation',
    templateUrl: './profile-media-presentation.component.html',
    styleUrls: ['./profile-media-presentation.component.scss']
})
export class ProfileMediaPresentationComponent implements OnInit {
    public mediaData: TaskModuleMediaData;

    constructor(private activatedRoute: ActivatedRoute,
                private titleService: Title) {
        this.titleService.setTitle('Micromate Profile');
    }

    public ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe(params => {
            const data = params.get('data');
            this.mediaData = JSON.parse(data) as TaskModuleMediaData;
        });
    }

}
