<app-popup-container>
    <app-popup-header *ngIf="!feedbackPopupService.notAllow" class="mm-feedback-popup-header">
        <app-icon class="mm-feedback-popup-icon-title" icon="message-pen-light"></app-icon>
        <app-popup-header-title title="{{'feedback.resultTitle' | translate}}"></app-popup-header-title>
    </app-popup-header>

    <app-popup-content class="mm-feedback-result-content">
        <div *ngIf="feedbackPopupService.hasNoError()" class="mm-feedback-result-content-success">
            <app-icon [icon]="'circle-check'" class="mm-feedback-result-content-success-icon"></app-icon>
            <app-message-box type="success"
                             [showIcon]="false"
                             content="{{'feedback.resultSuccessMessage' | translate}}"></app-message-box>
        </div>

        <app-message-box type="error" *ngIf="feedbackPopupService.hasError"
                         content="{{'feedback.resultErrorMessage' | translate}}"></app-message-box>
        <app-message-box type="error" *ngIf="feedbackPopupService.notAllow"
                         content="{{'feedback.resultNotAllowFeedbackErrorMessage' | translate}}"></app-message-box>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-wizard-footer
            [isBackVisible]="false"
            [hasCustomContent]="false"
            forwardText="{{'feedback.resultCloseButton' | translate}}"
            (navigateForwardClicked)="closePopup()"
            [isForwardDisabled]="false"
            [isForwardVisible]="true">
        </app-popup-wizard-footer>
    </app-popup-footer>
</app-popup-container>
