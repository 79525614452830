<div class="micromate-profile-study-progress-content-tab-learning-package-info">

    <div *ngIf="introduction !== undefined && introduction.length > 0"
         class="micromate-profile-study-progress-content-tab-learning-package-introduction">
        <div class="mm-lp-info" *ngIf="isAdmin$ | async">
            <p><strong class="mm-info-label">Forgetting factor:</strong> {{formatForgettingFactor(studyProgress.forgettingFactorRawValue)}}%</p>
            <p><strong class="mm-info-label">Stability of memory:</strong> {{formatStabilityOfMemory(studyProgress.stabilityOfMemory)}}</p>
            <p class="mm-info-block-last"><strong  class="mm-info-label">Forgetting Offset:</strong> {{daysToTime(studyProgress.forgettingOffset)}}</p>

            <p><strong [title]="timeFromNowToDate(studyProgress.pointOfForgetting)" class="mm-info-label">Point of forgetting:</strong> {{studyProgress.pointOfForgetting | date:'dd.MM.YY HH:mm:ss'}}</p>
            <p><strong [title]="timeFromNowToDate(studyProgress.pointToLearn)" class="mm-info-label">Point to Learn:</strong> {{studyProgress.pointToLearn | date:'dd.MM.YY HH:mm:ss'}}</p>
            <p><strong [title]="timeFromNowToDate(studyProgress.timeOfProof)" class="mm-info-label">Time of proof:</strong> {{studyProgress.timeOfProof | date:'dd.MM.YY HH:mm:ss'}}</p>
        </div>

        <div [innerHTML]="introduction | markdown"></div>
    </div>
    <div *ngIf="introduction === undefined || introduction.length === 0"
         class="micromate-profile-study-progress-content-tab-learning-package-introduction mm-profile-study-progress-no-introduction">

        <div class="mm-lp-info" *ngIf="isAdmin$ | async">
            <p><strong class="mm-info-label">Forgetting factor:</strong> {{formatForgettingFactor(studyProgress.forgettingFactorRawValue)}}%</p>
            <p><strong class="mm-info-label">Stability of memory:</strong> {{formatStabilityOfMemory(studyProgress.stabilityOfMemory)}}</p>
            <p class="mm-info-block-last"><strong class="mm-info-label">Forgetting Offset:</strong> {{daysToTime(studyProgress.forgettingOffset)}}</p>

            <p><strong class="mm-info-label" [title]="timeFromNowToDate(studyProgress.pointOfForgetting)">Point of forgetting:</strong> {{studyProgress.pointOfForgetting | date:'dd.MM.YY HH:mm:ss'}}</p>
            <p><strong class="mm-info-label" [title]="timeFromNowToDate(studyProgress.pointToLearn)">Point to Learn:</strong> {{studyProgress.pointToLearn | date:'dd.MM.YY HH:mm:ss'}}</p>
            <p><strong class="mm-info-label" [title]="timeFromNowToDate(studyProgress.timeOfProof)">Time of proof:</strong> {{studyProgress.timeOfProof | date:'dd.MM.YY HH:mm:ss'}}</p>
        </div>

        {{'learningGoal.noIntroduction' | translate}}
    </div>
    <button class="mm-profile-study-progress-content-tab-learning-package-feedback"
            (click)="openFeedback()" title="{{'feedback.buttonTitle'| translate}}">
        <app-icon icon="message-pen-light"
                  class="mm-profile-study-progress-content-tab-learning-package-feedback-icon"></app-icon>
        {{'learningGoal.feedback' | translate}}
    </button>
    <div class="micromate-profile-study-progress-content-tab-learning-package-releaseDate">
        {{'learningGoal.releaseDate' | translate}}
        <strong>{{releaseDate | date:'dd.MM.yyyy'}}</strong>
    </div>
</div>
