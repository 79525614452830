<div class="pa-message-content micromate-message-content micromate-message-content-reflection-explanation">

    <div *ngIf="reflections !== undefined && reflections.length === 1"
         class="micromate-message-reflection-single-item">
        <div
            class="micromate-message-reflection-single-item-content-text micromate-message-reflection-carousel-item-content-text">
            {{reflections[0]}}
        </div>
    </div>

    <div *ngIf="reflections !== undefined && reflections.length > 1" class="micromate-message-reflection-carousel"
         name="carousel">
        <button (click)="showPreviousReflection()" *ngIf="activeReflection > 1"
                class="micromate-message-reflection-carousel-button">
            <app-icon [icon]="'angle-left-light'" class="micromate-message-reflection-carousel-button-icon"></app-icon>
        </button>
        <div>
            <div *ngFor="let reflection of reflections; let i = index"
                 [class.micromate-message-reflection-carousel-item-active]="activeReflection == i+1"
                 class="micromate-message-reflection-carousel-item">
                <div class="micromate-message-reflection-carousel-item-content-text">
                    <div [id]="'reflection' + i"> {{reflection}}</div>
                </div>
            </div>
            <div class="micromate-message-reflection-carousel-item-resize-element">{{longestReflectionIndex}}</div>
        </div>
        <button (click)="showNextReflection()"
                *ngIf="activeReflection < reflections.length" class="micromate-message-reflection-carousel-button micromate-message-reflection-carousel-button-next">
            <app-icon [icon]="'angle-right-light'" class="micromate-message-reflection-carousel-button-icon"></app-icon>
        </button>
    </div>

</div>
