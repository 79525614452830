<div *ngIf="shouldShowMessage" class="pa-message-content micromate-message-content">
    <mm-quiz-question (mediaLoaded)="mediaLoaded()"
                       [questionStaticText]="responseItemData.text"
                       [quizNumber]="quizNumber"
                       [quizQuestion]="question"
                      [showFeedback]="updatedMessage === true && questionTranslationId !== undefined"
                      [questionId]="questionTranslationId"
                      [learningPackageId]="learningPackageTranslationId"
                       class="app-quiz-question"></mm-quiz-question>
    <div *ngIf="!updatedMessage; else updateMessageAnswers">
        <div class="micromate-true-false-quiz-answers">
            <div (click)="sendAnswer(responseItemData.text.incorrectAnswerText)"
                 [class.micromate-true-false-quiz-answer-letter-deactivated]="isDeactivated"
                 class="micromate-true-false-quiz-answer micromate-true-false-quiz-answer-false">
                {{responseItemData.text.incorrectAnswerText}}
            </div>
            <div (click)="sendAnswer(responseItemData.text.correctAnswerText)"
                 [class.micromate-true-false-quiz-answer-letter-deactivated]="isDeactivated"
                 class="micromate-true-false-quiz-answer micromate-true-false-quiz-answer-true">
                {{responseItemData.text.correctAnswerText}}
            </div>
        </div>
    </div>

    <ng-template #updateMessageAnswers>
        <div class="micromate-true-false-quiz-answers">
            <div [class.micromate-true-false-quiz-answer-answered]="responseItemData.quizAnswerData?.answersId === 1"
                 [class.micromate-true-false-quiz-answer-correct]="negativeAnswer.isCorrectAnswer"
                 [class.micromate-true-false-quiz-answer-incorrect]="!negativeAnswer.isCorrectAnswer && responseItemData.quizAnswerData?.answersId === 1"
                 class="micromate-true-false-quiz-answer micromate-true-false-quiz-answer-false
                micromate-true-false-quiz-answer-letter-deactivated">
                {{responseItemData.text.incorrectAnswerText}}
            </div>
            <div [class.micromate-true-false-quiz-answer-answered]="responseItemData.quizAnswerData?.answersId === 2"
                 [class.micromate-true-false-quiz-answer-correct]="positiveAnswer.isCorrectAnswer"
                 [class.micromate-true-false-quiz-answer-incorrect]="!positiveAnswer.isCorrectAnswer && responseItemData.quizAnswerData?.answersId === 2"
                 class="micromate-true-false-quiz-answer micromate-true-false-quiz-answer-true
                micromate-true-false-quiz-answer-letter-deactivated">
                {{responseItemData.text.correctAnswerText}}
            </div>
        </div>
    </ng-template>

    <mm-quiz-source *ngIf="updatedMessage && sourceUrl !== undefined && sourceUrl.length > 0"
                    [sourceUrl]="sourceUrl"
                    [sourceLabel]="sourceLabel"
                    [sourceTitle]="responseItemData.text.sourceTitle"></mm-quiz-source>
</div>
