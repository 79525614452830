import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FeedbackCategoryTypes} from '../../services/rest/api-types/CreateFeedbackRequestDto';

export type FeedbackSteps = 'creation' | 'result' | 'mainTopic' | 'topic';

@Injectable({
    providedIn: 'root'
})

export class FeedbackPopupService {
    public currentStep: FeedbackSteps;
    public notAllow: boolean = false;
    public hasError: boolean = false;

    public openIn: 'question' | 'learningPackage';
    public feedbackLevel: 'question' | 'learningPackage';

    public feedbackCategory: FeedbackCategoryTypes;

    public spinnerVisibleSubject = new BehaviorSubject<boolean>(false);
    public spinnerVisible$: Observable<boolean> = this.spinnerVisibleSubject.asObservable();

    public showSpinner(): void {
        this.spinnerVisibleSubject.next(true);
    }

    public hideSpinner(): void {
        this.spinnerVisibleSubject.next(false);
    }

    public setHasError(status: boolean): void {
        this.hasError = status;
    }

    public setNotAllow(status: boolean): void {
        this.notAllow = status;
    }

    public hasNoError(): boolean {
        return !this.notAllow && !this.hasError;
    }

    public clearPopupStates(): void {
        this.hasError = false;
        this.notAllow = false;
        this.feedbackLevel = undefined;
        this.openIn = undefined;
    }

    public setQuestionFeedbackLevel(): void {
        this.feedbackLevel = 'question';
    }

    public setLearningPackageFeedbackLevel(): void {
        this.feedbackLevel = 'learningPackage';
    }

    public setTopicPopup(): void {
        if (this.feedbackLevel === 'question') {
            this.currentStep = 'mainTopic';
        } else {
            this.currentStep = 'topic';
        }
    }
}
