import {Component, Input} from '@angular/core';
import {QuizExplanation} from '../../types/QuizExplanation';

@Component({
    selector: 'app-explanation-question-preview',
    templateUrl: './explanation-question-preview.component.html',
    styleUrls: ['./explanation-question-preview.component.scss']
})
export class ExplanationQuestionPreviewComponent {
    @Input()
    public explanation: QuizExplanation;

    @Input()
    public showExplanationText: boolean = true;
}
