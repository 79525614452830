import {Component, Inject} from '@angular/core';
import {LearnAuthService} from '../../learn-auth.service';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Component({
    selector: 'mm-missing-role',
    templateUrl: './missing-role.component.html',
    styleUrls: ['./missing-role.component.scss']
})
export class MissingRoleComponent {
    constructor(@Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LearnAuthService) {
    }

    public logout(): void {
        void this.authService.startLogoutFlow();
    }
}
