<div *ngIf="shouldShowMessage" class="pa-message-content micromate-message-content">
    <mm-quiz-question [questionStaticText]="responseItemData.text"
                      [quizNumber]="quizNumber"
                      [quizQuestion]="question"
                      [showFeedback]="updatedMessage === true && questionTranslationId !== undefined"
                      [questionId]="questionTranslationId"
                      [learningPackageId]="learningPackageTranslationId"
                      (mediaLoaded)="mediaLoaded()"
                      class="app-quiz-question"></mm-quiz-question>

    <div *ngIf="!isDeactivated" [innerHTML]="responseItemData.text.hintMessage | emoji" class="micromate-quiz-hint">
    </div>

    <mm-quiz-source *ngIf="updatedMessage && sourceUrl !== undefined && sourceUrl.length > 0"
                    [sourceUrl]="sourceUrl"
                    [sourceLabel]="sourceLabel"
                    [sourceTitle]="responseItemData.text.sourceTitle"></mm-quiz-source>
</div>
