<div class="micromate-profile-page">
    <div class="mm-profile-page-data">
        <div *ngIf="data !== undefined && data.learnerPoints !== undefined" class="micromate-profile-scoreboard">
            <div [class.micromate-profile-study-progress-dark-mode]="darkMode"
                 class="micromate-profile-points">
                <div class="micromate-profile-point">
                    <div class="micromate-profile-point-title-text">
                        <div> {{formatPoints(data.learnerPoints.monthlyPoints) }}</div>
                        <div class="micromate-profile-point-title-text-points">
                            {{'ranking.points' | translate}}
                        </div>
                    </div>
                    <div class="micromate-profile-point-title-subtext">
                        <div
                            class="micromate-profile-point-title-subtext-data micromate-profile-point-title-subtext-data-points">
                            <app-icon [icon]="'ranking-star-solid'"
                                      class="micromate-profile-scoreboard-points-icon"></app-icon>
                            <div class="micromate-profile-point-title-subtext-data-text">
                                {{data.learnerPoints.monthlyRankingPlace !== undefined ? data.learnerPoints.monthlyRankingPlace : '-'}}</div>
                        </div>
                        <div class="micromate-profile-point-title-subtext-data">
                            <div class="micromate-profile-point-title-subtext-data-text-info">
                                {{'ranking.currentMonth' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div [class.micromate-profile-study-progress-dark-mode]="darkMode"
                 class="micromate-profile-points">
                <div class="micromate-profile-point">
                    <div class="micromate-profile-point-title-text">
                        <div>{{formatPoints(data.learnerPoints.yearlyPoints) }}</div>
                        <div class="micromate-profile-point-title-text-points">
                            {{'ranking.points' | translate}}
                        </div>
                    </div>
                    <div class="micromate-profile-point-title-subtext">
                        <div
                            class="micromate-profile-point-title-subtext-data micromate-profile-point-title-subtext-data-points">
                            <app-icon [icon]="'ranking-star-solid'"
                                      class="micromate-profile-scoreboard-points-icon"></app-icon>
                            <div class="micromate-profile-point-title-subtext-data-text">
                                {{data.learnerPoints.yearlyRankingPlace !== undefined ? data.learnerPoints.yearlyRankingPlace : '-'}}</div>
                        </div>
                        <div class="micromate-profile-point-title-subtext-data">
                            <div class="micromate-profile-point-title-subtext-data-text-info">
                                {{'ranking.currentYear' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div [class.micromate-profile-study-progress-dark-mode]="darkMode"
                 class="micromate-profile-points">
                <div class="micromate-profile-point">
                    <div class="micromate-profile-point-title-text">
                        <div>{{formatPoints(data.learnerPoints.allPoints) }}</div>
                        <div class="micromate-profile-point-title-text-points">
                            {{'ranking.points' | translate}}
                        </div>
                    </div>
                    <div class="micromate-profile-point-title-subtext">
                        <div class="micromate-profile-point-title-subtext-data-text-info">
                            {{'ranking.allTime' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="data !== undefined && data.rankingNotActiveInLearnerOrganization"
             class="micromate-profile-scoreboard-table">
            <div class="micromate-profile-scoreboard-periods">
                <button (click)="switch('current-month')"
                        [disabled]="showSpinner || activePeriod === 'current-month'"
                        [class.micromate-profile-scoreboard-period-button-active]="activePeriod === 'current-month'"
                        class="micromate-profile-scoreboard-period-button">{{'ranking.currentMonth' | translate}}
                </button>
                <button (click)="switch('last-month')"
                        [disabled]="showSpinner || activePeriod === 'last-month'"
                        [class.micromate-profile-scoreboard-period-button-active]="activePeriod === 'last-month'"
                        class="micromate-profile-scoreboard-period-button">{{'ranking.lastMonth' | translate}}
                </button>
                <button (click)="switch('current-year')"
                        [disabled]="showSpinner || activePeriod === 'current-year'"
                        [class.micromate-profile-scoreboard-period-button-active]="activePeriod === 'current-year'"
                        class="micromate-profile-scoreboard-period-button">{{'ranking.currentYear' | translate}}
                </button>
                <button (click)="switch('last-year')"
                        [disabled]="showSpinner || activePeriod === 'last-year'"
                        [class.micromate-profile-scoreboard-period-button-active]="activePeriod === 'last-year'"
                        class="micromate-profile-scoreboard-period-button">{{'ranking.lastYear' | translate}}
                </button>
            </div>

            <div *ngIf="showSpinner" class="mm-spinner-block">
                <mm-spinner class="mm-spinner"></mm-spinner>
            </div>


            <div *ngIf="!showSpinner && !data.organizationExistPeriod"
                 class="micromate-profile-scoreboard-no-period-data">{{'ranking.noOrganizationRanking' | translate}}</div>

            <div
                *ngIf="!showSpinner && data.organizationExistPeriod && !data.scoreboard?.numberOfLearnersInOrganization"
                class="micromate-profile-scoreboard-no-period-data">{{'ranking.noRankingData' | translate}}</div>

            <div class="micromate-table"
                 *ngIf="!showSpinner && data.organizationExistPeriod && data.scoreboard?.numberOfLearnersInOrganization > 0">
                <div class="table-container-data">
                    <div
                        [class.micromate-profile-scoreboard-active-first]="data.scoreboard.activeLearner !== undefined && data.scoreboard.activeLearner.position === 1"
                        [class.micromate-profile-scoreboard-active-third]="data.scoreboard.activeLearner !== undefined && data.scoreboard.activeLearner.position === 3"
                        [class.micromate-profile-scoreboard-with-active]="data.scoreboard.activeLearner !== undefined && data.scoreboard.activeLearner.position <= 3"
                        class="micromate-profile-scoreboard-top-learners">
                        <div *ngFor="let leader of data.scoreboard.leaders.slice(0, 3); let i = index"
                             [class.micromate-profile-scoreboard-active-learner]="leader.learnerId === data.scoreboard.activeLearner?.learnerId"
                             class="table-container">
                            <div class="row row-place">
                                <div [class.micromate-profile-scoreboard-medal-brown]="i === 2"
                                     [class.micromate-profile-scoreboard-medal-gold]="i === 0"
                                     [class.micromate-profile-scoreboard-medal-silver]="i === 1"
                                     class="micromate-profile-scoreboard-medal">
                                    {{leader.position}}</div>
                            </div>
                            <div class="row ">{{leader.displayName}}</div>
                            <div class="row row-points">
                                {{formatPoints(leader.points) }}
                                <div class="row-points-text">{{'ranking.points' | translate}}</div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let leader of data.scoreboard.leaders.slice(3, data.scoreboard.leaders.length)"
                         [class.micromate-profile-scoreboard-active-learner]="leader.learnerId === data.scoreboard.activeLearner?.learnerId"
                         class="table-container">
                        <div class="row row-place">{{leader.position}}</div>
                        <div class="row ">{{leader.displayName}}</div>
                        <div class="row row-points">
                            {{formatPoints(leader.points) }}
                            <div class="row-points-text">{{'ranking.points' | translate}}</div>
                        </div>
                    </div>


                    <div *ngIf="scoreboardService.isSpaceAfterLeadersVisible(data.scoreboard)"
                         class="micromate-profile-scoreboard-dots">
                        <div *ngFor="let i of [0,1, 2]" class="micromate-profile-scoreboard-dot">
                            <app-icon class="micromate-profile-scoreboard-dot-icon" [icon]="'circle-solid'"></app-icon>
                        </div>
                    </div>

                    <div *ngFor="let leader of data.scoreboard.betterLearners" class="table-container ">
                        <div class="row row-place">{{leader.position}}</div>
                        <div class="row ">{{leader.displayName}}</div>
                        <div class="row row-points">
                            {{formatPoints(leader.points) }}
                            <div class="row-points-text">{{'ranking.points' | translate}}</div>
                        </div>
                    </div>

                    <div *ngIf="data.scoreboard.activeLearner !== undefined && isActiveLearnerInLeaders(data.scoreboard)"
                         class="table-container micromate-profile-scoreboard-active-learner">
                        <div class="row row-place">{{data.scoreboard.activeLearner.position}}</div>
                        <div class="row ">{{data.scoreboard.activeLearner.displayName}}</div>
                        <div class="row row-points">
                            {{data.scoreboard.activeLearner.points}}
                            <div class="row-points-text">{{'ranking.points' | translate}}</div>
                        </div>
                    </div>

                    <div *ngFor="let leader of data.scoreboard.worseLearners" class="table-container ">
                        <div class="row row-place">{{leader.position}}</div>
                        <div class="row ">{{leader.displayName}}</div>
                        <div class="row row-points">
                            {{formatPoints(leader.points) }}
                            <div class="row-points-text">{{'ranking.points' | translate}}</div>
                        </div>
                    </div>

                    <div
                        *ngIf="scoreboardService.isSpaceBeforeLastLearnerPlaceVisible(data.scoreboard)"
                        class="micromate-profile-scoreboard-dots">
                        <div *ngFor="let i of [0,1, 2]" class="micromate-profile-scoreboard-dot">
                            <app-icon class="micromate-profile-scoreboard-dot-icon" [icon]="'circle-solid'"></app-icon>
                        </div>
                    </div>

                    <div
                        *ngIf="scoreboardService.isLastLearnerPlaceVisible(data.scoreboard)"
                        class="table-container">
                        <div class="row row-place">{{data.scoreboard.numberOfLearnersInOrganization}}</div>
                        <div class="row ">...</div>
                        <div class="row "></div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
