import {Component} from '@angular/core';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';

@Component({
    selector: 'mm-learning',
    templateUrl: './learning.component.html',
    styleUrls: ['./learning.component.scss']
})
export class LearningComponent {

    constructor(public authUntilUserRoleService: AuthUntilUserRoleService) {
    }
}
