<app-popup-container>
    <app-popup-header class="mm-feedback-popup-header">
        <app-icon class="mm-feedback-popup-icon-title" icon="message-pen-light"></app-icon>
        <app-popup-header-title title="{{'feedback.title' | translate}}"></app-popup-header-title>
    </app-popup-header>
    <app-popup-content class="mm-feedback-content">
        <button class="mm-feedback-question-preview-activation"
                *ngIf="isFeedbackAboutQuestion()"
                (click)="changeQuestionPreview()">
            <app-icon class="mm-feedback-question-preview-activation-icon" icon="ballot-check-light"></app-icon>
            <div *ngIf="!questionPreviewOpen">{{'feedback.questionPreviewShow' | translate}}</div>
            <div *ngIf="questionPreviewOpen">{{'feedback.questionPreviewHide' | translate}}</div>
        </button>
        <app-question-preview *ngIf="questionPreviewOpen && (questionPreview$ | async) !== null"
                              [questionPreview]="questionPreview$ | async"></app-question-preview>
        <textarea class="mm-form-textarea"
                  [formControl]="feedbackTextFormControl"></textarea>
        <div class="mm-form-textarea-length"
             [class.mm-form-textarea-length-error]="feedbackTextFormControl.errors !== null && feedbackTextFormControl.errors['maxlength']">
            {{feedbackTextFormControl.getRawValue().length}}/3000
        </div>
        <app-message-box type="info"
                         content="{{'feedback.infoBox' | translate}}">
        </app-message-box>
    </app-popup-content>
    <app-popup-footer>
        <app-popup-wizard-footer
            [isBackVisible]="true"
            [hasCustomContent]="false"
            (navigateBackClicked)="comeBackToTopic()"
            forwardText="{{'feedback.sendButton' | translate}}"
            (navigateForwardClicked)="sendFeedback()"
            [isForwardDisabled]="feedbackTextFormControl.invalid"
            [isForwardVisible]="true">
        </app-popup-wizard-footer>
    </app-popup-footer>
</app-popup-container>
