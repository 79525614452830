<div class="micromate-profile-study-progress-goal-bar">
    <div
        [ngStyle]="{ 'width': 'calc(' + (100 - learningGoal) + '% + 22px)' }"
        class="micromate-profile-study-progress-bar micromate-profile-study-progress-goal-bar-positive">
        <div
            *ngIf="studyProgress >= learningGoal && learningPackageIsAvailable"
            [ngStyle]="{ 'margin-left': getStudyProgressPositivePointPosition(studyProgress, learningGoal) }"
            class="micromate-profile-study-progress-bar-point micromate-profile-study-progress-bar-point-positive">
        </div>
        <div
            *ngIf="manualActivation && reachableLearningProgress < 100 && learningPackageIsAvailable"
            [ngStyle]="getUnreachableLearningProgressPositionForPositiveBar()"
            [class.micromate-profile-study-progress-bar-inactive-full]="reachableLearningProgress <= learningGoal"
            class="micromate-profile-study-progress-bar-inactive micromate-profile-study-progress-bar-positive-inactive">
        </div>
    </div>
    <div
        [ngStyle]="{ 'width': 'calc(' + learningGoal + '% - 20px)' }"
        class="micromate-profile-study-progress-bar micromate-profile-study-progress-goal-bar-negative">
        <div
            *ngIf="studyProgress < learningGoal && learningPackageIsAvailable"
            [ngStyle]="{ 'margin-left': getStudyProgressNegativePointPosition(studyProgress, learningGoal) }"
            class="micromate-profile-study-progress-bar-point micromate-profile-study-progress-bar-point-negative">
        </div>
        <div
            *ngIf="manualActivation && reachableLearningProgress < 100 && reachableLearningProgress < learningGoal && learningPackageIsAvailable"
            class="micromate-profile-study-progress-bar-inactive micromate-profile-study-progress-bar-negative-inactive"
            [ngStyle]="getUnreachableLearningProgressPositionForNegativeBar()">
        </div>
    </div>
</div>
<div [ngStyle]="{ 'margin-left': 'calc(' + learningGoal + '% - 28px)' }"
     class="micromate-profile-study-progress-goal-value">
    <div class="micromate-profile-study-progress-goal-value-symbol">
    </div>
    <div class="micromate-profile-study-progress-goal-info"
         title="{{ 'learningGoal.learningGoal' | translate }}">
        <div class="micromate-profile-study-progress-goal-info-flag">
            <app-icon icon="flag-checkered-light-new"></app-icon>
        </div>
        {{learningGoal}}
        <div class="micromate-profile-study-progress-goal-info-symbol">%</div>
    </div>
</div>
