import {OAuthStorage} from '../../../../external-libs/angular-oauth2-oidc/types';

export class MicromateOAuthStorage extends OAuthStorage {

    /**
     * We have to use the sessionstorage for nonce and PKCE_verifier, because these
     * two values are used for the login flow and are valid only once per session/tab
     * In case we store them in localstorage, we would run into login issues when having
     * multiple tabs open and doing a simultaneous login in each tab, as the tabs would
     * override the values of each other and therefore break the validation!
     */
    private static readonly SESSION_STORAGE_KEYS: string[] = ['nonce', 'PKCE_verifier'];

    public getItem(key: string): string | undefined {
        if (MicromateOAuthStorage.SESSION_STORAGE_KEYS.includes(key)) {
            return sessionStorage.getItem(key);
        }
        return localStorage.getItem(key) ?? undefined;
    }

    public removeItem(key: string): void {
        if (MicromateOAuthStorage.SESSION_STORAGE_KEYS.includes(key)) {
            sessionStorage.removeItem(key);
        } else {
            localStorage.removeItem(key);
        }
    }

    public setItem(key: string, data: string): void {
        if (MicromateOAuthStorage.SESSION_STORAGE_KEYS.includes(key)) {
            sessionStorage.setItem(key, data);
        } else {
            localStorage.setItem(key, data);
        }
    }
}
