export enum TabEventType {
    TabBadgeOpened = 'TabBadgeOpened',
    TabLearningProgressOpened = 'TabLearningProgressOpened',
    TabRankingOpened = 'TabRankingOpened',
    TabRankingChanged = 'TabRankingChanged',
    TabProfileOpened = 'TabProfileOpened'
}

export enum ResourceEventType {
    ResourceOpened = 'ResourceOpened'
}
