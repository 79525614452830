import {Pipe, PipeTransform} from '@angular/core';

// TODO This one does also exist in the lib??!?
@Pipe({name: 'emoji'})
export class EmojiPipe implements PipeTransform {
    public transform(value: string): string {

        if (value === undefined) {
            return value;
        }

        return value.replace(/\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/, '<span class="pa-emoji">$&</span>');
    }
}
