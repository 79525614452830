import {Component, Input} from '@angular/core';
import {Quiz} from '../../../types/quiz';
import {QuestionPreviewService} from '../../../question-preview.service';

@Component({
    selector: 'app-true-false-question-preview',
    templateUrl: './true-false-question-preview.component.html',
    styleUrls: ['./true-false-question-preview.component.scss']
})
export class TrueFalseQuestionPreviewComponent {
    @Input()
    public questionPreview: Quiz;

    @Input()
    public type: 'full' | 'basic';

    constructor(public questionPreviewService: QuestionPreviewService) {
    }
}
