import {Injectable, Type} from '@angular/core';
import {BotatoChatMessageResolverService, DelayedRenderedMessageComponent, ImmediatelyRenderedMessageComponent, ResponseDtoType} from 'botato-angular-lib';
import {LearningPackageIntroductionComponent} from '../components/chatbot/customItems/learning-package-introduction/learning-package-introduction.component';
import {HelpComponent} from '../components/chatbot/customItems/help/help.component';
import {ReflectionFreeTextCarouselExplanationComponent} from '../components/chatbot/customItems/reflection-question/reflection-free-text-catousel-explanation/reflection-free-text-carousel-explanation.component';
import {ExplanationCardComponent} from '../components/chatbot/customItems/explanation-card/explanation-card.component';
import {MultipleTextChoiceQuizComponent} from '../components/chatbot/customItems/standard-question/multiple-text-choice-quiz/multiple-text-choice-quiz.component';
import {BagdeComponent} from '../components/chatbot/customItems/bagde/bagde.component';
import {TrueFalseQuizComponent} from '../components/chatbot/customItems/standard-question/true-false-quiz/true-false-quiz.component';
import {FreeTextQuizComponent} from '../components/chatbot/customItems/standard-question/free-text-quiz/free-text-quiz.component';
import {ReflectionFreeTextQuizComponent} from '../components/chatbot/customItems/reflection-question/reflection-free-text-quiz/reflection-free-text-quiz.component';
import {SingleTextChoiceQuizComponent} from '../components/chatbot/customItems/standard-question/single-text-choice-quiz/single-text-choice-quiz.component';
import {KnowledgeQuestionAnswerCardComponent} from '../components/chatbot/customItems/knowledgeQuestionAnswer/knowledge-question-answer-card.component';
import {MonthlyReportSkippedComponent} from '../components/chatbot/customItems/monthly-report-skipped/monthly-report-skipped.component';

@Injectable({
    providedIn: 'root'
})
export class LearnMicromateMessageResolverServiceService extends BotatoChatMessageResolverService {

    constructor() {
        super();
        this.messageMapping.set(LearningPackageIntroductionComponent.TYPE, LearningPackageIntroductionComponent);
        this.messageMapping.set(MultipleTextChoiceQuizComponent.TYPE, MultipleTextChoiceQuizComponent);
        this.messageMapping.set(SingleTextChoiceQuizComponent.TYPE, SingleTextChoiceQuizComponent);
        this.messageMapping.set(TrueFalseQuizComponent.TYPE, TrueFalseQuizComponent);
        this.messageMapping.set(FreeTextQuizComponent.TYPE, FreeTextQuizComponent);
        this.messageMapping.set(ReflectionFreeTextQuizComponent.TYPE, ReflectionFreeTextQuizComponent);
        this.messageMapping.set(ReflectionFreeTextCarouselExplanationComponent.TYPE_SINGLE, ReflectionFreeTextCarouselExplanationComponent);
        this.messageMapping.set(ReflectionFreeTextCarouselExplanationComponent.TYPE_CAROUSEL, ReflectionFreeTextCarouselExplanationComponent);
        this.messageMapping.set(ExplanationCardComponent.TYPE, ExplanationCardComponent);
        this.messageMapping.set(BagdeComponent.TYPE, BagdeComponent);
        this.messageMapping.set(HelpComponent.TYPE, HelpComponent);
        this.messageMapping.set(MultipleTextChoiceQuizComponent.UPDATE_TYPE, MultipleTextChoiceQuizComponent);
        this.messageMapping.set(SingleTextChoiceQuizComponent.UPDATE_TYPE, SingleTextChoiceQuizComponent);
        this.messageMapping.set(TrueFalseQuizComponent.UPDATE_TYPE, TrueFalseQuizComponent);
        this.messageMapping.set(FreeTextQuizComponent.UPDATE_TYPE, FreeTextQuizComponent);
        this.messageMapping.set(KnowledgeQuestionAnswerCardComponent.TYPE, KnowledgeQuestionAnswerCardComponent);
        this.messageMapping.set(MonthlyReportSkippedComponent.TYPE, MonthlyReportSkippedComponent);
    }

    public override resolve(messageType: ResponseDtoType): Type<ImmediatelyRenderedMessageComponent | DelayedRenderedMessageComponent> {
        return super.resolve(messageType);
    }
}
