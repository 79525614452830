import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {LearnerInfoResponseDto} from './dtos/learnerInfoResponseDto';
import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {LearnerUpdateRequestDto} from './dtos/learner-update-request.dto';

@Injectable({
    providedIn: 'root'
})
export class LearnerInfoResource {
    constructor(private http: HttpClient) {
    }

    public loadLearnerData(): Promise<LearnerInfoResponseDto> {
        return firstValueFrom(this.http.get<LearnerInfoResponseDto>(`${environment.serverUrl}/api/learner`));
    }

    public saveLearnerData(data: LearnerUpdateRequestDto): Promise<void> {
        return firstValueFrom(this.http.post<void>(`${environment.serverUrl}/api/learner`, data));
    }
}

