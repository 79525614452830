import {LearnAuthService} from '../learn-auth.service';

import {Inject, Injectable} from '@angular/core';
import {User} from '../../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(@Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LearnAuthService) {
    }

    public async canActivate(): Promise<boolean> {
        const authenticatedUser: User = this.authService.getAuthenticatedUser();
        if (authenticatedUser === undefined) {
            this.authService.startLoginFlow();
            return false;
        }

        return true;
    }
}

