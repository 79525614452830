import {Observable} from 'rxjs';
import {InjectionToken} from '@angular/core';
import {User} from './User';

export const LIB_AUTH_SERVICE_TOKEN = new InjectionToken<LibAuthService>('LIB_AUTH_SERVICE');

export interface LibAuthService {
    authentication$: Observable<User>;

    getAccessToken(): string;
}
