import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, EMPTY, finalize, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {LearnerResourceSelectionResponseDto} from './learner-resource-selection-response.dto';
import {AiRessource} from './ai-ressource.service';

@Injectable({providedIn: 'root'})
export class ResourceViewerService {

    private resourceNotAvailable: Subject<boolean> = new BehaviorSubject(false);
    public resourceNotAvailable$: Observable<boolean> = this.resourceNotAvailable.asObservable();
    public resourceUrl$: Observable<string>;

    private loading: Subject<boolean> = new BehaviorSubject(true);
    public loading$: Observable<boolean> = this.loading.asObservable();

    constructor(private aiResource: AiRessource) {
    }

    public loadResource(resourceId: string, selectionId: string, openAsMicromateHtml: boolean): void {
        this.loading.next(true);
        const resource$ = this.loadResourceFromApi(resourceId, selectionId, openAsMicromateHtml)
            .pipe(finalize(() => this.loading.next(false)));

        this.resourceUrl$ = resource$.pipe(
            catchError(() => {
                this.resourceNotAvailable.next(true);
                return EMPTY;
            }),
            map(response => this.handleResponse(response)));
    }

    private loadResourceFromApi(resourceId: string, selectionId: string, openAsMicromateHtml: boolean): Observable<LearnerResourceSelectionResponseDto> {
        if (selectionId === undefined) {
            return this.aiResource.getResource(resourceId, openAsMicromateHtml);
        }

        return this.aiResource.getResourceWithSelection(resourceId, selectionId, openAsMicromateHtml);
    }

    private handleResponse(response: LearnerResourceSelectionResponseDto): string {
        if (response.resourceUrl !== undefined) {
            return response.resourceUrl;
        }

        if (response.documentMimeType === 'application/pdf') {
            const decodedContent = atob(response.documentContent);
            const array = new Uint8Array(decodedContent.length);
            for (let i = 0; i < decodedContent.length; i++) {
                array[i] = decodedContent.charCodeAt(i);
            }
            const blob = new Blob([array], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);

            if (response.documentPageNumber !== undefined) {
                return `${url}#page=${response.documentPageNumber}`;
            }

            return url;
        } else {
            const blob = new Blob([response.documentContent], {type: response.documentMimeType});
            return URL.createObjectURL(blob);
        }
    }

}
