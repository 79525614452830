export class FrontendError {
    public readonly frontend = 'LEARN';

    constructor(public date: Date,
                public message: string,
                public stack: string,
                public innerError: FrontendError,
                public url: string,
                public browser: string,
                public userId: string) {
    }
}
