import {VideoDisplay, VideoDisplayType, VideoFormat, VideoFormatType} from './VideoFormat';
import {VideoLink} from './VideoLink';

export class OneDriveVideo {
    public static TYPE: VideoFormatType = VideoFormat.ONE_DRIVE;
    public static DISPLAY: VideoDisplayType = VideoDisplay.VIDEO;


    public static createVideoLink(): VideoLink {
        return new VideoLink(OneDriveVideo.TYPE, OneDriveVideo.DISPLAY);
    }

    public static isVideoFormat(url: string): boolean {
        const regex = new RegExp('^(https?\\:\\/\\/)?((www\\.)?onedrive\\.live\\.com)\\/.+$');
        return regex.test(url);
    }
}
