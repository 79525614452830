<select class="mm-single-select"
        [class.mm-dark]="style === 'dark'"
        [value]="value"
        (blur)="onTouched()"
        (change)="valueChanged($event)"
        [disabled]="disabled">
    <option *ngFor="let locale of availableLocals"
            [class.mm-not-supported-locale]="!locale.isSupported"
            [value]="locale.locale">{{locale.name }}
    </option>
</select>
