<h3 class="mm-question-preview-answers-title" *ngIf="type === 'full'">{{'questionPreview.answerTitle' | translate}}</h3>
<div *ngFor="let answer of getAnswers()" class="mm-answer mm-single-question-answers"
     [class.mm-answer-correct]="answer.isCorrectAnswer"
     [class.mm-answer-wrong]="!answer.isCorrectAnswer">
    <div class="mm-single-question-answer" [innerHTML]="answer.answerText | markdown"></div>
    <div *ngIf="answer.explanation !== undefined && answer.explanation.length > 0"
         class="mm-single-question-answer-explanation">
        <div class="mm-single-question-answer-explanation-icon">
            <svg aria-hidden="true" focusable="false" viewBox="0 0 640 512"
                 class="mm-single-question-answer-explanation-icon-svg">
                <use href="./assets/icons.svg#lightbulb-exclamation-light"></use>
            </svg>
        </div>
        <div class="mm-single-question-answer-explanation-text"
             [innerHTML]="answer.explanation | markdown"></div>
    </div>
</div>
<app-explanation-question-preview
    *ngIf="shouldShowExplanation()"
    [explanation]="questionPreview.explanation"
    [showExplanationText]="false"></app-explanation-question-preview>
<app-source-question-preview *ngIf="type === 'full' && questionPreview.sourceUrl !== undefined && questionPreview.sourceUrl.length > 0"
                             [sourceUrl]="questionPreview.sourceUrl"
                             [sourceLabel]="questionPreview.sourceLabel"></app-source-question-preview>
