<div class="micromate-quiz-info">
    <div [innerHTML]="questionStaticText.learningPackageInfo | emoji" class="micromate-quiz-learning-package-name">
    </div>
    <div class="mm-quiz-question-info">
        <div class="mm-quiz-question-in-session">
            {{quizNumber}}/5
        </div>
        <app-icon *ngIf="showFeedback"
                  [icon]="'message-pen-thin'"
                  (click)="openFeedback()"
                  class="mm-quiz-question-info-feedback-icon"
                  title="{{'feedback.buttonTitle'| translate}}"></app-icon>
    </div>
</div>

<div *ngIf="additionalMessage !== undefined" [innerHTML]="additionalMessage | emoji"
     class="micromate-quiz-question-additional-message"></div>

<div [innerHTML]="question | emoji" class="micromate-quiz-question"></div>
<mm-quiz-media (mediaLoaded)="emitMediaLoaded()" [micromateManageUrl]="micromateManageUrl"
               [questionStaticText]="questionStaticText" [quizMedia]="quizMedia"></mm-quiz-media>
