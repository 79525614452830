import {Injectable} from '@angular/core';
import {FeedbackService} from '../../../../../micromate-learn-lib/src/lib/services/FeedbackService';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CreateFeedbackRequestDto} from '../../../../../micromate-learn-lib/src/lib/services/rest/api-types/CreateFeedbackRequestDto';
import {EnvironmentService} from '../../../../../micromate-learn-lib/src/lib/services/EnvironmentService';
import {TeamsContextService} from '../teams-context.service';
import {switchMap, take} from 'rxjs/operators';
import {app, dialog} from '@microsoft/teams-js';
import {FeedbackAllowRequestDto} from '../../../../../micromate-learn-lib/src/lib/services/rest/api-types/FeedbackAllowRequestDto';
import {Quiz} from '../../../../../micromate-learn-lib/src/lib/components/question-preview/types/quiz';
import {FeedbackPopupService} from '../../../../../micromate-learn-lib/src/lib/components/feedback/feedback-popup.service';
import {PopupService} from '../../../../../micromate-learn-lib/src/lib/components/popup/popup.service';
import {FeedbackRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/feedback-ressource.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileFeedbackService implements FeedbackService {
    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private teamsContextService: TeamsContextService,
                private feedbackPopupService: FeedbackPopupService,
                private popupService: PopupService,
                private feedbackRessource: FeedbackRessource) {
    }

    public closeFeedback(): void {
        if (this.feedbackPopupService.openIn === 'question') {
            dialog.url.submit();
        } else {
            this.popupService.close();
        }
    }

    public checkIfFeedbackAllow(feedbackAllowRequestDto: FeedbackAllowRequestDto): Observable<string> {
        return this.teamsContextService.teamsContext$.pipe(
            take(1),
            switchMap((teamsContext: app.Context) => {
                const userId = teamsContext.user.id;
                return this.feedbackRessource.isFeedbackAllow(userId, feedbackAllowRequestDto);
            })
        );
    }

    public sendFeedback(createFeedbackRequestDto: CreateFeedbackRequestDto): Observable<string> {
        return this.teamsContextService.teamsContext$.pipe(
            take(1),
            switchMap((teamsContext: app.Context) => {
                const userId = teamsContext.user.id;
                return this.feedbackRessource.sendFeedback(userId, createFeedbackRequestDto);
            })
        );
    }

    public getQuestionPreview(learningPackageId: string, questionId: string): Observable<Quiz> {
        return this.teamsContextService.teamsContext$.pipe(
            take(1),
            switchMap((teamsContext: app.Context) => {
                const userId = teamsContext.user.id;
                return this.feedbackRessource.getQuestionPreview(userId, learningPackageId, questionId);
            })
        );
    }
}
