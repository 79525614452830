import {BehaviorSubject, Observable} from 'rxjs';
import {LocalStorageService} from '../storage/local-storage.service';
import {Router} from '@angular/router';
import {AuthenticationError} from './authenticationError';
import {ZitadelUserMapper} from './zitadelUserMapper';
import {User} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {OAuthService} from '../../../../../external-libs/angular-oauth2-oidc/oauth-service';
import {AuthConfig} from '../../../../../external-libs/angular-oauth2-oidc/auth.config';
import {HttpErrorResponse} from '@angular/common/http';
import {LoggerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';
import {Injectable, Injector} from '@angular/core';
import {LearnerInfoResource} from './resources/learner-info.resource';


@Injectable()
export class LearnAuthService {

    private readonly authenticationSubject: BehaviorSubject<User> = new BehaviorSubject(undefined as User);
    public authentication$: Observable<User> = this.authenticationSubject.asObservable();

    constructor(private authLibService: OAuthService,
                private authConfig: AuthConfig,
                private router: Router,
                private learnerInfoResource: LearnerInfoResource,
                private localStorageService: LocalStorageService,
                private loggerRessource: LoggerRessource) {
    }

    public static initialization(injector: Injector): () => Promise<void> {
        const authService = injector.get<LearnAuthService>(LIB_AUTH_SERVICE_TOKEN);
        return async () => {
            await authService.initializeService();
            await authService.updateCurrentUser();
        };
    }

    public getAccessToken(): string {
        return this.authLibService.getAccessToken();
    }

    public getAuthenticatedUser(): User {
        return this.authenticationSubject.getValue();
    }

    public hasValidTokens(): boolean {
        return this.authLibService.hasValidIdToken() && this.authLibService.hasValidAccessToken();
    }

    public startLogoutFlow(continueToLogin: boolean = true, shouldInvalidateValidTokens: boolean = true): void {
        this.loggerRessource.logFrontendInfoToBackend(`Start logout flow: ${continueToLogin} / ${shouldInvalidateValidTokens}`);

        const hasValidTokens = this.hasValidTokens();
        this.authenticationSubject.next(undefined);

        if (hasValidTokens) {
            this.authLibService.logOut(!shouldInvalidateValidTokens);
        } else {
            this.authLibService.logOut(true);
        }

        if (!hasValidTokens && continueToLogin) {
            // We do want to redirect to the login page after the logout. Normal use case is, that
            // we call logout and after invalidating the tokens we get directed back and automatically
            // end up on the login page. But in cas ewe do not have valid tokens, we do not invalidate
            // them on zitadel and therefore we are not redirected to the login. So we have to start
            // the login flow manually. Calling Zitadel with invalid tokens will end on a zitadel error page.
            this.startLoginFlow();
        }
    }

    public startLoginFlow(): void {
        let activeUserName: string = undefined;
        // at this point we are not fully authenticated yet so we work with the saved username if he didnt logout explicitely
        const authenticatedUser = this.getActiveUser();
        if (authenticatedUser !== undefined) {
            activeUserName = authenticatedUser;
            this.authLibService.customQueryParams = {
                login_hint: activeUserName
            };
        }
        this.storeUrl();
        this.authLibService.initCodeFlow(undefined);
    }

    public async startCodeExchangeFlow(): Promise<void> {
        // We do not want to exchange any tokens if we already have valid ones
        // This can be the case when the user logs-in again after already having
        // logged-in in another tab
        if (!this.hasValidTokens()) {
            try {
                await this.authLibService.tryLogin();
                if (!this.hasValidTokens()) {
                    throw new AuthenticationError('No token after code exchange', undefined);
                }
            } catch (e) {
                if (this.isChromeBug() && !this.isInRetryLoop()) {
                    this.startRetryLoop();
                    // Send the user back to the login
                    this.startLogoutFlow(true, false);
                    return;
                } else {
                    this.loggerRessource.logFrontendInfoToBackend(`Before "Could not exchange code to token": ${this.isChromeBug()} / ${this.hasValidTokens()}`);
                    // This can happen when for whatever reason the code is invalid, or we
                    // could not reach zitadel to exchange the code to a token
                    this.startLogoutFlow(false, false);
                    throw new AuthenticationError('Could not exchange code to token', e as Error);
                }
            }
        }

        this.resetRetryLoop();
        await this.updateCurrentUser();
        await this.restoreUrl();
    }

    private storeUrl(): void {
        sessionStorage.setItem(
            'requested_route',
            window.location.pathname + window.location.search
        );
    }

    private async restoreUrl(): Promise<void> {
        const requestedRoute = sessionStorage.getItem('requested_route') ?? undefined;
        if (requestedRoute !== undefined) {
            sessionStorage.removeItem('requested_route');
            window.location.href = window.location.origin + requestedRoute;
        } else {
            await this.router.navigate(['/']);
        }
    }

    private async initializeService(): Promise<void> {
        this.authLibService.configure(this.authConfig);
        this.authLibService.strictDiscoveryDocumentValidation = false;
        await this.authLibService.loadDiscoveryDocument();
        this.authLibService.setupAutomaticSilentRefresh();
        this.authLibService.events.subscribe(events => this.loggerRessource.logFrontendInfoToBackend(JSON.stringify(events)));
    }

    public async updateCurrentUser(): Promise<void> {
        if (this.hasValidTokens()) {
            const user = await this.loadUser();
            this.authenticationSubject.next(user);

            if (user !== undefined) {
                this.setActiveUser(user.preferred_username.split('@')[0]);
            }
        }
    }

    private async loadUser(): Promise<User> {
        try {
            const learnerInfo = await this.learnerInfoResource.loadLearnerData();
            const userInfo = (await this.authLibService.loadUserProfile()) as { info: User; },
                user: object = userInfo.info;
            return ZitadelUserMapper.map(user, learnerInfo);
        } catch (e) {
            const error = e as HttpErrorResponse;
            this.startLogoutFlow(true, false);
            if (error.status === 403) {
                return undefined;
            }

            throw new AuthenticationError('Could not load user from IAM', error);
        }
    }

    public removeSelectedUser(): void {
        this.removeActiveUser();
        this.authLibService.customQueryParams = {};
    }

    private isChromeBug(): boolean {
        // Chrome bug: https://paixon.atlassian.net/browse/MIC-1900
        // When after the redirect the nonce is "null", it is for some reason gone, so we see the chrome bug
        // eslint-disable-next-line no-null/no-null
        return sessionStorage.getItem('nonce') === null;
    }

    private isInRetryLoop(): boolean {
        // eslint-disable-next-line no-null/no-null
        return localStorage.getItem('login_retry_done') !== null;
    }

    private startRetryLoop(): void {
        this.loggerRessource.logFrontendInfoToBackend(`Start retry loop: ${this.isChromeBug()} / ${this.hasValidTokens()}`);
        localStorage.setItem('login_retry_done', 'YES');
    }

    private resetRetryLoop(): void {
        this.loggerRessource.logFrontendInfoToBackend(`Reset retry loop: ${this.isChromeBug()} / ${this.hasValidTokens()}`);
        localStorage.removeItem('login_retry_done');
    }

    public setActiveUser(activeUserName: string): void {
        return this.localStorageService.setItem('activeUserName', activeUserName);
    }

    public getActiveUser(): string {
        return this.localStorageService.getItem('activeUserName');
    }

    public removeActiveUser(): void {
        return this.localStorageService.removeItem('activeUserName');
    }
}

