import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {PasswordComplexityPolicyResponseDto} from './rest/api-types/PasswordComplexityPolicyResponseDto';

export class FormCustomValidation {

    public static passwordValidator(passwordComplexityPolicy: () => PasswordComplexityPolicyResponseDto): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            const password = control.value;

            if (!this.validatePassword(password, passwordComplexityPolicy())) {
                return {invalidPassword: true};
            }
            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }

    public static passwordMatch(password: () => string): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            const confirmPassword = control.value;
            if (confirmPassword !== password()) {
                return {invalidConfirmPassword: true};
            }
            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }

    private static validatePassword(password: string, passwordComplexityPolicy: PasswordComplexityPolicyResponseDto): boolean {
        if (passwordComplexityPolicy === undefined) {
            return false;
        }

        if (password === undefined) {
            return false;
        }

        const passwordToCheck = password.trim();

        if (passwordToCheck.length < passwordComplexityPolicy.minLength) {
            return false;
        }

        if (passwordComplexityPolicy.hasLowercase) {
            if (passwordToCheck.toUpperCase() === passwordToCheck) {
                return false;
            }
        }

        if (passwordComplexityPolicy.hasNumber) {
            if (!/\d/.test(passwordToCheck)) {
                return false;
            }
        }

        if (passwordComplexityPolicy.hasSymbol) {
            if (/^[a-zA-Z0-9\s]+$/.test(passwordToCheck)) {
                // If the string only contains letters, spaces or numbers
                // then we don't have a symbol -> policy not matched
                return false;
            }
        }

        if (passwordComplexityPolicy.hasUppercase) {
            if (passwordToCheck.toLowerCase() === passwordToCheck) {
                return false;
            }
        }

        return true;
    }
}
