import {Component, Input} from '@angular/core';
import {Quiz} from '../../../types/quiz';
import {Answer} from '../../../types/answer';

@Component({
    selector: 'app-single-choice-question-preview',
    templateUrl: './single-choice-question-preview.component.html',
    styleUrls: ['./single-choice-question-preview.component.scss']
})
export class SingleChoiceQuestionPreviewComponent {
    @Input()
    public questionPreview: Quiz;

    @Input()
    public type: 'full' | 'basic';

    public getAnswers(): Answer[] {
        return this.questionPreview.answers as Answer[];
    }

    public shouldShowExplanation(): boolean {
        if (this.questionPreview.explanation !== undefined) {
            if (this.type === 'basic') {
                return this.questionPreview.explanation.text !== undefined && this.questionPreview.explanation.text.length > 0;
            }
            if (this.type === 'full') {
                return this.questionPreview.explanation.mediaType !== undefined;
            }
        }
        return false;
    }

}
