import {Component, Input} from '@angular/core';

@Component({
    selector: 'mm-learning-progress-visualization-bar',
    templateUrl: './learning-progress-visualization-bar.component.html',
    styleUrls: ['./learning-progress-visualization-bar.component.scss']
})
export class LearningProgressVisualizationBarComponent {
    @Input() studyProgress: number;
    @Input() learningGoal: number;
    @Input() studyProgressScaleName: string;
    @Input() learningPackageIsAvailable: boolean;
    @Input() manualActivation: boolean;
    @Input() reachableLearningProgress: number;

    public getStudyProgressPositivePointPosition(studyProgress: number, learningGoal: number): string {
        const positiveSize = 100 - learningGoal;
        const position = ((studyProgress - learningGoal) / positiveSize) * 100;

        const shift = Math.round((position * 22) / 100);
        return `calc(${position}% - ${shift}px)`;
    }

    public getStudyProgressNegativePointPosition(studyProgress: number, learningGoal: number): string {
        const position = (studyProgress * 100) / (learningGoal - 1);

        const shift = Math.round((position * 22) / 100);
        return `calc(${position}% - ${shift}px)`;
    }


    public getUnreachableLearningProgressPositionForPositiveBar(): { [key: string]: number | string; } {
        if (this.reachableLearningProgress > this.learningGoal) {
            const percentage = ((100 - this.reachableLearningProgress) * 100) / (100 - this.learningGoal);
            const marginLeft = 100 - percentage;
            if (marginLeft > 2) {
                return {'margin-left.%': marginLeft, 'width.%': percentage};
            }
            if (marginLeft >= 98) {
                return {'width': '0', 'visibility': 'hidden'};
            }
        }
        return {'margin-left': '0', 'width.%': '100', 'border-radius': '15px', 'border-left': 'unset'};
    }

    public getUnreachableLearningProgressPositionForNegativeBar(): { [key: string]: number | string; } {
        const percentage = (this.learningGoal * ((100 - this.reachableLearningProgress) - (100 - this.learningGoal))) / 100;
        const marginLeft = 100 - percentage;
        if (marginLeft >= 98) {
            return {'width': '0', 'visibility': 'hidden'};
        }
        return {'margin-left.%': marginLeft, 'width.%': percentage};
    }
}
