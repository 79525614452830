import {Component, OnInit} from '@angular/core';
import {app} from '@microsoft/teams-js';
import {TeamsContextService} from './core/teams-context.service';
import {TranslationLoaderService} from '../../../micromate-learn-lib/src/lib/services/translation-loader.service';
import {LoggerRessource} from '../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';
import {MicrosoftTeamsRecognitionService} from './profile-pages/MicrosoftTeamsRecognitionService';

@Component({
    selector: 'mm-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private teamsContextService: TeamsContextService,
                public translationLoaderService: TranslationLoaderService,
                private loggerService: LoggerRessource) {
        this.translationLoaderService.loadTranslations();
    }

    public async ngOnInit(): Promise<void> {
        if (MicrosoftTeamsRecognitionService.isRunningWithinMicrosoftTeams()) {
            this.loggerService.logFrontendInfoToBackend('Initialization of Teams');
            try {
                await this.initializeTeams();
            } catch (e) {
                this.reportTeamsInitializationError(e as Error);
                throw e;
            }
            const context = await app.getContext();
            this.teamsContextService.set(context);
        }
    }

    public async initializeTeams(isRetry: boolean = false): Promise<void> {
        try {
            await app.initialize();
        } catch (e) {
            this.reportTeamsInitializationError(e as Error, isRetry);
            if (!isRetry) {
                await this.initializeTeams(true);
            } else {
                throw e;
            }
        }
    }

    private reportTeamsInitializationError(error: Error, isRetry: boolean = false): void {
        const isParentSameAsWindow = window.parent === window;
        const isParentSameAsSelf = window.parent === self;
        const isParentSameAsTop = window.parent === top;
        const isSelfSameAsTop = window.self === window.top;
        // eslint-disable-next-line eqeqeq
        const isParentUndefinded = window.parent == undefined;
        const language = navigator.language;
        const origin = window.origin;

        // eslint-disable-next-line eqeqeq,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        const isWebkitUndefined = (window as any).webkit == undefined;

        // eslint-disable-next-line eqeqeq,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        const isMessageHandlerUndefined = (window as any).webkit?.messageHandlers == undefined;

        // eslint-disable-next-line eqeqeq,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        const isNativeInterfaceUndefined = (window as any).nativeInterface == undefined;

        this.loggerService.logFrontendInfoToBackend(`Hit teams error: ${error.message} / ${isParentSameAsWindow} / ${isParentSameAsSelf} / ${isParentSameAsTop} / ${isSelfSameAsTop} / ${isParentUndefinded} / ${language} / isWebkitUndefined: ${isWebkitUndefined} / isMessageHandlerUndefined: ${isMessageHandlerUndefined} / isNativeInterfaceUndefined: ${isNativeInterfaceUndefined} / origin: ${origin} / name: ${window.name} / isRetry: ${isRetry}`);
    }
}
