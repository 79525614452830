<div class="pa-message-content micromate-message-content micromate-message-content-corner-fix">
    <div [innerHTML]="text.statement | emoji" class="micromate-question-explanation-statement">
    </div>

    <div (click)="openExplanation()"
         *ngIf="responseItemData.message.answerIsCorrect && hasExplanation && isExplanationOpen !== true"
         class="micromate-profile-link">
        <app-icon [icon]="'lightbulb-exclamation-light'" class="micromate-profile-link-icon"></app-icon>
        {{text.explanationButtonText}}
    </div>

    <div *ngIf="shouldShowDetails()">
        <mm-quiz-media (mediaLoaded)="mediaLoaded()"
                        *ngIf="hasMedia"
                        [micromateManageUrl]="micromateManageUrl"
                        [questionStaticText]="text"
                        [quizMedia]="responseItemData.message.media"
                        class="app-quiz-media-explanation"></mm-quiz-media>
        <div *ngFor="let explanation of createExplanationTextMessage()" [innerHTML]="explanation | emoji"
             class="micromate-question-explanation-text">
        </div>
    </div>

    <div (click)="sendNextQuestion()"
         *ngIf="!isDeactivated && responseItemData.showNextQuizButton" class="micromate-question-button">
        {{responseItemData.text.nextQuizButtonText}}
    </div>
</div>
