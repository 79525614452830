<div *ngIf="isDataLoaded" class="micromate-profile-page">
    <div *ngIf="studyProgresses !== undefined && studyProgresses.length > 0"
         [class.micromate-profile-study-progresses-justify-space-evenly]="studyProgresses.length> 3"
         class="micromate-profile-study-progresses">

        <div *ngFor="let studyProgress of studyProgresses"
             class="micromate-profile-study-progress"
             [class.micromate-profile-study-progress-not-available]="!studyProgress.learningPackageIsAvailable">

            <div
                [class.micromate-profile-study-progress-gole-not-done]="studyProgress?.learningGoal!== undefined && studyProgress.studyProgress < studyProgress.learningGoal"
                class="micromate-profile-study-progress-card">

                <div class="micromate-profile-study-progress-markers">
                    <div class="micromate-profile-study-progress-markers-stickers">

                        <div
                            *ngIf="studyProgress.shouldShowAmountOfForgettingIndicator"
                            class="micromate-profile-study-progress-sticker mm-profile-progress-amount-of-forgetting-sticker"
                            title="{{ 'learningGoal.forgettingApplied' | translate }}">
                            {{ studyProgress.amountOfForgetting }}<span
                            class="mm-profile-progress-amount-of-forgetting-sticker-percent">%</span>
                        </div>

                        <div *ngIf="studyProgress.shouldShowLearnIndicator"
                             class="micromate-profile-study-progress-sticker micromate-profile-study-progress-forgetting-sticker"
                             title="{{ 'learningGoal.forgettingApplied' | translate }}">

                            <app-icon icon="user-clock-solid"
                                      class="micromate-profile-study-progress-forgetting-sticker-icon"></app-icon>
                        </div>

                        <div
                            *ngIf="studyProgress?.learningGoal!== undefined && studyProgress.studyProgress >= studyProgress.learningGoal"
                            class="micromate-profile-study-progress-sticker
                         micromate-profile-study-progress-check-sticker"
                            title="{{ 'learningGoal.checkSticker' | translate }}">
                            <app-icon [icon]="'check-light'"
                                      class="micromate-profile-study-progress-check-sticker-icon"></app-icon>
                        </div>
                    </div>

                </div>
                <div class="micromate-profile-study-progress-card-content">

                    <div class="micromate-profile-study-progress-learning-package-name">
                        {{ studyProgress.learningPackageName }}
                    </div>

                    <div class="micromate-profile-study-progress-content-tab">
                        <mm-learning-progress-visualization
                            *ngIf="studyProgress.activeTab === 'learningProgress'"
                            [learningGoal]="studyProgress?.learningGoal"
                            [studyProgressScaleName]="studyProgress?.studyProgressScaleName"
                            [studyProgress]="studyProgress.studyProgress"
                            [learningPackageIsAvailable]="studyProgress.learningPackageIsAvailable"
                            [manualActivation]="studyProgress.manualActivation"
                            [reachableLearningProgress]="studyProgress.reachableLearningProgress">
                        </mm-learning-progress-visualization>
                        <mm-questions-visualization
                            *ngIf="studyProgress.activeTab === 'quizzesOverview'"
                            [levels]="studyProgress.quizzesVisualization.levels"
                            [neverAnsweredQuestions]="studyProgress.quizzesVisualization.neverAnsweredQuestions"
                            [numberOfQuestion]="studyProgress.quizzesVisualization.numberOfActiveQuestions"
                            [numberOfInactiveQuestions]="studyProgress.quizzesVisualization.numberOfInactiveQuestions"></mm-questions-visualization>
                        <mm-learning-package-info
                            *ngIf="studyProgress.activeTab === 'learningPackageInfo'"
                            [introduction]="studyProgress.introduction"
                            [studyProgress]="studyProgress"
                            [ownerOrganization]="studyProgress.ownerOrganization"
                            [releaseDate]="studyProgress.releaseDate"
                            [learningPackageId]="studyProgress.learningPackageTranslationId"></mm-learning-package-info>
                    </div>

                </div>
                <div class="micromate-profile-study-progress-tabs-navigation"
                     [class.micromate-profile-study-progress-tabs-navigation-hidden]="!studyProgress.learningPackageIsAvailable">
                    <button type="button"
                            (click)="learnPackage(studyProgress.learningPackageId)"
                            [attr.disabled]="isStartingLearningSessionInProgress ? true : null"
                            class="micromate-profile-study-progress-learn-button"
                            title="{{ 'learningGoal.learnButton' | translate }}">
                        <app-icon [icon]="'graduation-play'"
                                  class="micromate-profile-study-progress-tabs-learn-button-cap"></app-icon>
                    </button>

                    <div class="micromate-profile-study-progress-tabs-navigation-panel">
                        <button type="button"
                                (click)="changeTab(studyProgress,'learningProgress')"
                                [class.micromate-profile-study-progress-tabs-navigation-button-active]="studyProgress.activeTab === 'learningProgress'"
                                class="micromate-profile-study-progress-tabs-navigation-button micromate-profile-study-progress-tabs-navigation-button-first"
                                title="{{ 'learningGoal.navLearningProgress' | translate }}">
                            <app-icon [icon]="'bullseye-arrow-light'"
                                      class="micromate-profile-study-progress-tabs-navigation-button-icon"></app-icon>
                        </button>
                        <div class="micromate-profile-study-progress-tabs-navigation-button-separator"></div>
                        <button type="button"
                                (click)="changeTab(studyProgress,'quizzesOverview')"
                                [class.micromate-profile-study-progress-tabs-navigation-button-active]="studyProgress.activeTab === 'quizzesOverview'"
                                class="micromate-profile-study-progress-tabs-navigation-button"
                                title="{{ 'learningGoal.navQuizzesOverview' | translate }}">
                            <app-icon [icon]="'goal-net-light'"
                                      class="micromate-profile-study-progress-tabs-navigation-button-icon micromate-profile-study-progress-tabs-navigation-button-goal-net-icon"></app-icon>
                        </button>
                        <div class="micromate-profile-study-progress-tabs-navigation-button-separator"></div>
                        <button type="button"
                                (click)="changeTab(studyProgress,'learningPackageInfo')"
                                [class.micromate-profile-study-progress-tabs-navigation-button-active]="studyProgress.activeTab === 'learningPackageInfo'"
                                class="micromate-profile-study-progress-tabs-navigation-button micromate-profile-study-progress-tabs-navigation-button-last"
                                title="{{ 'learningGoal.navLearningPackageInfo' | translate }}">
                            <app-icon [icon]="'circle-info-light'"
                                      class="micromate-profile-study-progress-tabs-navigation-button-icon"></app-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="studyProgresses === undefined || studyProgresses.length === 0"
         [class.micromate-profile-study-no-data-dark-mode]="darkMode"
         class="micromate-profile-study-no-data">
        {{ 'learningGoal.noData' | translate }}
    </div>
</div>
