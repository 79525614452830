<div class="micromate-profile-study-progresses-back-questions-visualization">
    <div class="micromate-profile-study-progresses-back-questions-visualization-info">
        <div
            [innerHTML]="'learningGoal.numberOfQuestion' | translate: { numberOfQuestion: numberOfQuestion + numberOfInactiveQuestions } "
            class="micromate-profile-study-progresses-back-questions-visualization-numberOfQuestion"></div>
        <div
            *ngIf="neverAnsweredQuestions > 0 && numberOfInactiveQuestions === 0"
            [innerHTML]="'learningGoal.unansweredQuestions' | translate: { neverAnsweredQuestions: neverAnsweredQuestions }"
            class="micromate-profile-study-progresses-back-questions-visualization-neverAnsweredQuestions"></div>
        <div
            *ngIf="neverAnsweredQuestions > 0 && numberOfInactiveQuestions > 0"
            [innerHTML]="'learningGoal.unansweredAndInactiveQuestions' | translate: { neverAnsweredQuestions: neverAnsweredQuestions, numberOfInactiveQuestions:numberOfInactiveQuestions }"
            class="micromate-profile-study-progresses-back-questions-visualization-neverAnsweredQuestions"></div>
        <div
            *ngIf="neverAnsweredQuestions === 0 && numberOfInactiveQuestions > 0"
            [innerHTML]="'learningGoal.inactiveQuestions' | translate: { numberOfInactiveQuestions: numberOfInactiveQuestions }"
            class="micromate-profile-study-progresses-back-questions-visualization-neverAnsweredQuestions"></div>
    </div>
    <div class="micromate-profile-study-progresses-back-questions-visualization-chart">
        <svg [attr.height]="size" [attr.width]="size" class="chess-field-svg">
            <rect
                *ngFor="let item of [].constructor(elementsPerLine*elementsPerLine); let i = index"
                [attr.height]="squareWidth"
                [attr.width]="squareWidth"
                [attr.x]="calculateXPosition(i)"
                [attr.y]="calculateYPosition(i)"
                [style.fill]="calculateBackgroundColor(i)"
                [style.stroke-width]="strokeWidth"
                [style.stroke]="calculateBackgroundColor(i)"
                class="chess-field"

            />
        </svg>
    </div>
</div>
