import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {catchError, Observable, of, throwError} from 'rxjs';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';
import {LearnAuthService} from './core/authentication/learn-auth.service';

@Injectable()
export class UnauthorizedHttpInterceptor implements HttpInterceptor {

    constructor(@Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LearnAuthService) {
    }

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // Logout when unauthorised error from backend
                if (error.status === 401) {
                    this.authService.startLogoutFlow();
                    return of(undefined);
                }

                // Do not throw error as we won't retry if unstable or no internet connection
                if (error.status === 0) {
                    return of(undefined);
                }

                return throwError(() => error);
            })
        );
    }

}
