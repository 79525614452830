import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LearnerIdQueryPrams} from './LearnerIdQueryPrams';
import {LearnerPointsResponseDto} from '../../types/LearnerPointsResponseDto';
import {LearnerRankingResponseDto} from '../../types/LearnerRankingResponseDto';
import {EnvironmentService} from '../EnvironmentService';

@Injectable({
    providedIn: 'root'
})
export class ScoreboardRessource {

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public getLearnerPoints(msteamsId: string): Promise<LearnerPointsResponseDto> {
        return this.http.get<LearnerPointsResponseDto>(`${this.environmentService.environment.serverUrl}/api/profile/scoreboard/points?msteamsid=${msteamsId}`).toPromise();
    }

    public getLearnerPointsForLearn(learnerId: string, zitadelId: string): Promise<LearnerPointsResponseDto> {
        return this.http.get<LearnerPointsResponseDto>(`${this.environmentService.environment.serverUrl}/api/learn/profile/scoreboard/points?${LearnerIdQueryPrams.create(learnerId, zitadelId)}`).toPromise();
    }

    public getRankingActivatedStatus(msteamsId: string): Promise<boolean> {
        return this.http.get<boolean>(`${this.environmentService.environment.serverUrl}/api/profile/scoreboard/is-ranking-active?msteamsid=${msteamsId}`).toPromise();
    }

    public getRankingActivatedStatusForLearn(learnerId: string, zitadelId: string): Promise<boolean> {
        return this.http.get<boolean>(`${this.environmentService.environment.serverUrl}/api/learn/profile/scoreboard/is-ranking-active?${LearnerIdQueryPrams.create(learnerId, zitadelId)}`).toPromise();
    }

    public getScoreboard(msteamsId: string, period: string, date: string): Promise<LearnerRankingResponseDto> {
        return this.http.get<LearnerRankingResponseDto>(`${this.environmentService.environment.serverUrl}/api/profile/scoreboard/ranking?msteamsid=${msteamsId}&period=${period}&date=${date}`).toPromise();
    }

    public getScoreboardForLearn(learnerId: string, zitadelId: string, period: string, date: string): Promise<LearnerRankingResponseDto> {
        return this.http.get<LearnerRankingResponseDto>(`${this.environmentService.environment.serverUrl}/api/learn/profile/scoreboard/ranking?${LearnerIdQueryPrams.create(learnerId, zitadelId)}&period=${period}&date=${date}`).toPromise();
    }
}
