import {Component, OnInit} from '@angular/core';
import {FeedbackPopupService} from '../feedback-popup.service';
import {FeedbackCategoryTypes} from '../../../services/rest/api-types/CreateFeedbackRequestDto';

@Component({
    selector: 'mm-feedback-topic-popup',
    templateUrl: './feedback-topic-popup.component.html',
    styleUrls: ['./feedback-topic-popup.component.scss'],

})
export class FeedbackTopicPopupComponent implements OnInit {

    private questionTopics: { translation: string; category: FeedbackCategoryTypes; }[] = [
        {translation: 'feedback.questionFaultyTopic', category: FeedbackCategoryTypes.QuestionFaulty},
        {translation: 'feedback.questionUnintelligibleTopic', category: FeedbackCategoryTypes.QuestionUnintelligible},
        {translation: 'feedback.questionSpellingTopic', category: FeedbackCategoryTypes.QuestionSpelling},
        {translation: 'feedback.questionIdeaTopic', category: FeedbackCategoryTypes.QuestionIdea}
    ];

    private learningPackageTopics: { translation: string; category: FeedbackCategoryTypes; }[] = [
        {
            translation: 'feedback.learningPackageQuestionSuggestionTopic',
            category: FeedbackCategoryTypes.LearningPackageQuestionSuggestion
        },
        {
            translation: 'feedback.learningPackageTopicSuggestionTopic',
            category: FeedbackCategoryTypes.LearningPackageTopicSuggestion
        },
        {translation: 'feedback.learningPackageFeedbackTopic', category: FeedbackCategoryTypes.LearningPackageFeedback}
    ];


    public activeTopics: { translation: string; category: FeedbackCategoryTypes; }[] = [];

    constructor(public feedbackPopupService: FeedbackPopupService) {
    }

    public ngOnInit(): void {
        if (this.feedbackPopupService.feedbackLevel === 'question') {
            this.activeTopics = this.questionTopics;
        } else {
            this.activeTopics = this.learningPackageTopics;
        }
    }

    public setFeedbackCategory(category: FeedbackCategoryTypes): void {
        this.feedbackPopupService.feedbackCategory = category;
        this.feedbackPopupService.currentStep = 'creation';
    }

    public navigateBackToMainTopic(): void {
        this.feedbackPopupService.currentStep = 'mainTopic';
    }
}
