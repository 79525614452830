import {Injectable} from '@angular/core';
import {shareReplay, Subject} from 'rxjs';
import {app} from '@microsoft/teams-js';
import {LoggerRessource} from '../../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TeamsContextService {
    public teamsContext = new Subject<app.Context>();
    public teamsContext$ = this.teamsContext.asObservable().pipe(
        filter(teamsContext => teamsContext !== undefined),
        shareReplay(1));

    constructor(private loggerService: LoggerRessource) {
    }

    public set(value: app.Context): void {
        this.loggerService.logFrontendInfoToBackend(`Get context of Teams ${TeamsContextService.formatContext(value)}`);
        this.teamsContext.next(value);
    }

    public static formatContext(context: app.Context): string {
        return JSON.stringify({
            locale: context.app.locale,
            isFullScreen: context.page.isFullScreen,
            tid: context.user.tenant.id,
            userLicenseType: context.user.licenseType,
            userClickTime: context.app.userClickTime,
            sessionId: context.app.sessionId,
            appSessionId: context.app.sessionId,
            entityId: context.page.id,
            osLocaleInfo: context.app.osLocaleInfo,
            chatId: context.chat?.id
        } as Partial<app.Context>);
    }
}
