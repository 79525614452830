import {Component, Inject} from '@angular/core';
import {PopupService} from '../../popup/popup.service';
import {FeedbackPopupService} from '../feedback-popup.service';
import {FEEDBACK_RESOURCE_TOKEN, FeedbackService} from '../../../services/FeedbackService';

@Component({
    selector: 'mm-feedback-result-popup',
    templateUrl: './feedback-result-popup.component.html',
    styleUrls: ['./feedback-result-popup.component.scss']
})
export class FeedbackResultPopupComponent {

    constructor(public popupService: PopupService,
                public feedbackPopupService: FeedbackPopupService,
                @Inject(FEEDBACK_RESOURCE_TOKEN) private feedbackResource: FeedbackService) {
    }

    public closePopup(): void {
        this.feedbackResource.closeFeedback();
    }

}
