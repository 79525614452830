import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent {

    @Input()
    public icon: Icons;

}


export type Icons =
    'pencil-light'
    | 'arrows-rotate-light'
    | 'ban-regular'
    | 'flag-checkered-light-new'
    | 'circle-exclamation-light'
    | 'times-light'
    | 'message-pen-light'
    | 'circle-info-light'
    | 'circle-check-light'
    | 'message-pen-thin'
    | 'ballot-check-light'
    | 'envelope-light'
    | 'circle-check'
    | 'circle-solid'
    | 'ranking-star-solid'
    | 'graduation-play'
    | 'bullseye-arrow-light'
    | 'goal-net-light'
    | 'user-clock-solid'
    | 'angle-left-light'
    | 'medal-light'
    | 'expand'
    | 'lightbulb-exclamation-light'
    | 'chart-line-up-light'
    | 'angle-right-light'
    | 'mm-spinner'
    | 'check-light'
    | 'user-light'
    | 'sign-out-light'
    | 'bars-regular'
    | 'mm-logo'
    | 'user-regular'
    | 'mm-logo-dark'
    | 'exclamation-triangle-light';
