import {Injectable} from '@angular/core';
import {BotatoChatStorageService} from 'botato-angular-lib';

@Injectable({
    providedIn: 'root'
})
export class LocalstorageService extends BotatoChatStorageService {
    public setItem(key: string, value: string): Promise<void> {
        localStorage.setItem(key, value);
        return Promise.resolve();
    }

    public getItem(key: string): Promise<string> {
        return Promise.resolve(localStorage.getItem(key));
    }
}
