<div class="micromate-navigation-header">
    <div class="micromate-header-element">
        <div class="micromate-header">
            <div [routerLink]="['/learn']" class="micromate-header-logo-link">
                <img alt="Micromate Logo" src="../assets/logo-without-text.svg">
            </div>
            <div class="micromate-header-buttons">
                <div [queryParams]="getLernerIdParamForTabs()"
                     [routerLink]="(navigationService.isProfileVisible$ | async) ? ['/learn'] : ['/learn/profil']"
                     (click)="navigationService.toggleProfileVisible()"
                     class="micromate-header-button micromate-header-button-profile">
                    <app-icon [icon]="'user-light'"
                              class="micromate-header-button-icon"></app-icon>
                </div>
                <div (click)="logout()" *ngIf="isAuthenticated "
                     class="micromate-header-button micromate-header-button-login">
                    <app-icon [icon]="'sign-out-light'"
                              class="micromate-header-button-icon"></app-icon>
                </div>
            </div>

            <div (click)="navigationOpen = !navigationOpen"
                 class="micromate-header-button micromate-header-button-navigation">
                <app-icon [icon]="'bars-regular'"
                          class="micromate-header-button-icon"></app-icon>
            </div>
        </div>
    </div>
    <div *ngIf="navigationOpen" class="micormate-mobile-navigation">
        <button (click)="navigationOpen = false"
                *ngIf="learnerId === undefined"
                [class.micormate-mobile-navigation-active]="activeNavigation === 'chat'"
                [queryParams]="getLernerIdParam()"
                [routerLink]="['/learn']"
                class="micormate-mobile-navigation-element">
            {{'navigation.chat'| translate}}
        </button>
        <button (click)="navigationOpen = false"
                *ngIf="(authUntilUserRoleService.assertAdmin() | async) || (navigationService.activeNavigation$ | async)"
                [class.micormate-mobile-navigation-active]="activeNavigation === 'badges'"
                [queryParams]="getLernerIdParamForTabs()"
                [routerLink]="['/learn/badges']"
                class="micormate-mobile-navigation-element">
            {{ ('navigation.badges'| translate)}}</button>
        <button (click)="navigationOpen = false"
                *ngIf="(authUntilUserRoleService.assertAdmin() | async) || (navigationService.activeNavigation$ | async)"
                [class.micormate-mobile-navigation-active]="activeNavigation === 'study-progress'"
                [queryParams]="getLernerIdParamForTabs()"
                [routerLink]="['/learn/lernfortschritt']"
                class="micormate-mobile-navigation-element">
            {{  ('navigation.studyProgress'| translate)}}</button>
        <button (click)="navigationOpen = false"
                *ngIf="(authUntilUserRoleService.assertAdmin() | async) || (navigationService.activeNavigation$ | async)"
                [class.micormate-mobile-navigation-active]="activeNavigation === 'scoreboard'"
                [queryParams]="getLernerIdParamForTabs()"
                [routerLink]="['/learn/rangliste']"
                class="micormate-mobile-navigation-element">
            {{  ('navigation.scoreboard'| translate)}}</button>
        <button (click)="navigationOpen = false; navigationService.toggleProfileVisible()"
                [class.micormate-mobile-navigation-active]="(navigationService.isProfileVisible$ | async)"
                [queryParams]="getLernerIdParamForTabs()"
                [routerLink]="['/learn/profil']"
                class="micormate-mobile-navigation-element micormate-mobile-navigation-element-with-button">
            <app-icon [icon]="'user-light'"
                      class="micromate-header-logout-icon"></app-icon>
            {{  ('navigation.profile'| translate)}}
        </button>
        <button (click)="logout(); navigationOpen = false"
                class="micormate-mobile-navigation-element micormate-mobile-navigation-element-with-button">
            <app-icon [icon]="'sign-out-light'"
                      class="micromate-header-logout-icon"></app-icon>
            Logout
        </button>
    </div>
    <div class="micromate-navigation-element" *ngIf="activeNavigation !== undefined">
        <div class="micromate-navigation">
            <div [class.micromate-navigation-button-active]="activeNavigation === 'chat'"
                 [class.micromate-navigation-button-disable]="learnerId !== undefined"
                 [queryParams]="getLernerIdParam()"
                 [routerLink]="learnerId !== undefined ? undefined : ['/learn']"
                 class="micromate-navigation-button">
                {{'navigation.chat'| translate}}
            </div>
            <div [class.micromate-navigation-button-active]="activeNavigation === 'badges'"
                 [class.micromate-navigation-button-disable]="!(authUntilUserRoleService.assertAdmin() | async) && !(navigationService.activeNavigation$ | async)"
                 [queryParams]="getLernerIdParamForTabs()"
                 [routerLink]="['/learn/badges']"
                 class="micromate-navigation-button">
                {{ ('navigation.badges'| translate)}}
            </div>
            <div [class.micromate-navigation-button-active]="activeNavigation === 'study-progress'"
                 [class.micromate-navigation-button-disable]="!(authUntilUserRoleService.assertAdmin() | async) && !(navigationService.activeNavigation$ | async)"
                 [queryParams]="getLernerIdParamForTabs()"
                 [routerLink]="['/learn/lernfortschritt']"
                 class="micromate-navigation-button">
                {{  ('navigation.studyProgress'| translate)}}
            </div>
            <div [class.micromate-navigation-button-active]="activeNavigation === 'scoreboard'"
                 [class.micromate-navigation-button-disable]="!(authUntilUserRoleService.assertAdmin() | async) && !(navigationService.activeNavigation$ | async)"
                 [queryParams]="getLernerIdParamForTabs()"
                 [routerLink]="['/learn/rangliste']"
                 class="micromate-navigation-button micromate-navigation-button-last">
                {{  ('navigation.scoreboard'| translate)}}
            </div>
        </div>

    </div>
</div>
