import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ChatStartService {
    public hasBeenOpened = new BehaviorSubject(false);

    public setOpenState(state: boolean): void {
        this.hasBeenOpened.next(state);
    }

    public getOpenState(): boolean {
        return this.hasBeenOpened.value;
    }

    public isWaitingForNotification: boolean = false;
}
