<div *ngIf="shouldShowMessage" class="pa-message-content micromate-message-content">
    <mm-quiz-question (mediaLoaded)="mediaLoaded()"
                      [questionStaticText]="responseItemData.text"
                      [quizNumber]="quizNumber"
                      [quizQuestion]="question"
                      [showFeedback]="updatedMessage === true && questionTranslationId !== undefined"
                      [questionId]="questionTranslationId"
                      [learningPackageId]="learningPackageTranslationId"
                      class="app-quiz-question"></mm-quiz-question>

    <div *ngIf="!updatedMessage; else updateMessageAnswers">
        <div *ngIf="!isDeactivated; else deactivatedAnswers">
            <ul>
                <li *ngFor="let answer of answers" class="micromate-multiple-choice-quiz-answer">
                    <label class="micromate-multiple-choice-quiz-answer-data">
                        <div [innerHTML]="answer.letter + ') ' +answer.text | emoji"></div>
                        <input [(ngModel)]="answer.checked" checked="checked"
                               class="micromate-multiple-choice-quiz-answer-checkbox" type="checkbox">
                        <span class="micromate-checkmark"></span>
                    </label>
                </li>
            </ul>
            <div (click)="sendAnswers()"
                 [class.micromate-question-button-deactivated]="!isAnyAnswerMarked()"
                 class="micromate-question-button">
                {{responseItemData.text.sendButtonText}}
            </div>
        </div>

        <ng-template #deactivatedAnswers>
            <div *ngFor="let answer of answers"
                 class="micromate-choice-quiz-answers micromate-choice-quiz-answers-update">
                <div class="micromate-choice-quiz-answers-letter">
                    {{answer.letter}})
                </div>
                <div [innerHTML]="answer.text | emoji"
                     class="micromate-choice-quiz-answers-text">
                </div>
            </div>
        </ng-template>
    </div>

    <ng-template #updateMessageAnswers>
        <div *ngFor="let answer of answers"
             [class.micromate-choice-quiz-answers-correct]="answer.isCorrect"
             [class.micromate-choice-quiz-answers-incorrect]="!answer.isCorrect && responseItemData.quizAnswerData?.answersId.includes(answer.id)"
             class="micromate-choice-quiz-answers micromate-choice-quiz-answers-update">
            <div
                [class.micromate-choice-quiz-answers-answered]="responseItemData.quizAnswerData?.answersId.includes(answer.id)"
                class="micromate-choice-quiz-answers-letter">
                {{answer.letter}})
            </div>
            <div
                [class.micromate-choice-quiz-answers-answered]="responseItemData.quizAnswerData?.answersId.includes(answer.id)"
                [innerHTML]="answer.text | emoji"
                class="micromate-choice-quiz-answers-text">
            </div>
        </div>
    </ng-template>

    <mm-quiz-source *ngIf="updatedMessage && sourceUrl !== undefined && sourceUrl.length > 0"
                    [sourceUrl]="sourceUrl"
                    [sourceLabel]="sourceLabel"
                    [sourceTitle]="responseItemData.text.sourceTitle"></mm-quiz-source>
</div>
