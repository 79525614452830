import {Inject, Injectable} from '@angular/core';
import {FeedbackService} from '../FeedbackService';
import {HttpClient} from '@angular/common/http';
import {EnvironmentService} from '../EnvironmentService';
import {CreateFeedbackRequestDto} from './api-types/CreateFeedbackRequestDto';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {User} from '../../components/chatbot/User';
import {FeedbackAllowRequestDto} from './api-types/FeedbackAllowRequestDto';
import {Quiz} from '../../components/question-preview/types/quiz';
import {PopupService} from '../../components/popup/popup.service';
import {FeedbackRessource} from './feedback-ressource.service';
import {LIB_AUTH_SERVICE_TOKEN, LibAuthService} from '../../components/chatbot/libAuthService';

@Injectable({
    providedIn: 'root'
})
export class LearnFeedbackService implements FeedbackService {

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private popupService: PopupService,
                private feedbackRessource: FeedbackRessource,
                @Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LibAuthService) {
    }

    public closeFeedback(): void {
        this.popupService.close();
    }

    public checkIfFeedbackAllow(feedbackAllowRequestDto: FeedbackAllowRequestDto): Observable<string> {
        return this.authService.authentication$.pipe(
            take(1),
            switchMap((authentication: User) => {
                const userId = authentication.sub;
                return this.feedbackRessource.isFeedbackAllowForLearn(userId, feedbackAllowRequestDto);
            })
        );
    }

    public sendFeedback(createFeedbackRequestDto: CreateFeedbackRequestDto): Observable<string> {
        return this.authService.authentication$.pipe(
            take(1),
            switchMap((authentication: User) => {
                const userId = authentication.sub;
                return this.feedbackRessource.sendFeedbackForLearn(userId, createFeedbackRequestDto);
            })
        );
    }

    public getQuestionPreview(learningPackageId: string, questionId: string): Observable<Quiz> {
        return this.authService.authentication$.pipe(
            take(1),
            switchMap((authentication: User) => {
                const userId = authentication.sub;
                return this.feedbackRessource.getQuestionPreviewForLearn(userId, learningPackageId, questionId);
            })
        );
    }
}
