<div class="pa-message-content micromate-message-content micromate-message-content-corner-fix">
    <div [innerHTML]="responseItemData.qaAnswer | emoji">
    </div>

    <div *ngIf="sources !== undefined" class="qa-answer-documents">

        <ng-container *ngIf="sources.length === 1">
            <ng-container *ngIf="isRunningInWidget">
                <div class="qa-answer-documents-text">
                    {{text.source}}:
                    <a href="#" class="qa-source-widget" (click)="showResource(sources[0])">{{sources[0].resourceName}}</a>
                </div>
            </ng-container>

            <ng-container *ngIf="!isRunningInWidget">
                <div class="qa-answer-documents-text"
                     [innerHTML]="getResource() | markdown">
                </div>
            </ng-container>
        </ng-container>

        <div *ngIf="!isDeactivated && sources.length > 1">
            <div class="qa-answer-documents-text">{{text.sources}}:
            </div>
            <div class="qa-answer-documents-multiple">
                <select [(ngModel)]="selectedDocument" class="qa-answer-documents-selection">
                    <option *ngFor="let source of sources"
                            [ngValue]="source.resourceName">{{source.resourceName}}</option>
                </select>
                <div (click)="sendDocumentKey()" class="micromate-document-selection-button">
                    {{text.sendButton}}
                </div>
            </div>
        </div>


        <ng-container *ngIf="isDeactivated && sources.length > 1">
            <ng-container *ngIf="isRunningInWidget">
                <div class="qa-answer-documents-text">
                    {{text.sources}}:
                    <ng-container *ngFor="let source of sources; let isLast=last">
                        <a href="#" class="qa-source-widget" (click)="showResource(source)">{{source.resourceName}}</a>{{isLast ? '' : ', '}}
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="!isRunningInWidget">
                <div class="qa-answer-documents-text"
                     [innerHTML]="getResourcesList() | markdown">
                </div>
            </ng-container>
        </ng-container>

    </div>

</div>
