import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FeedbackPopupData} from '../../feedback/FeedbackPopupData';
import {FeedbackComponent} from '../../feedback/feedback.component';
import {PopupOptions} from '../../popup/PopupOptions';
import {PopupService} from '../../popup/popup.service';
import {FeedbackPopupService} from '../../feedback/feedback-popup.service';
import {StudyProgress} from '../../../types/StudyProgress';
import {differenceInSeconds, intervalToDuration, isBefore} from 'date-fns';
import {AuthUntilUserRoleService} from '../../../../../../micromate-learn-web/src/app/core/authentication/auth-until-user-role.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'mm-learning-package-info',
    templateUrl: './learning-package-info.component.html',
    styleUrls: ['./learning-package-info.component.scss']
})
export class LearningPackageInfoComponent {
    @Input()
    public introduction: string;

    @Input()
    public studyProgress: StudyProgress;

    @Input()
    public releaseDate: Date;
    @Input()
    public ownerOrganization: string;
    @Input()
    public learningPackageId: string;

    public isAdmin$: Observable<boolean>;

    constructor(public translate: TranslateService,
                public popupService: PopupService,
                roleService: AuthUntilUserRoleService,
                private feedbackPopupService: FeedbackPopupService) {
        this.isAdmin$ = roleService.assertAdmin();
    }

    public openFeedback(): void {
        this.feedbackPopupService.openIn = 'learningPackage';
        this.feedbackPopupService.setLearningPackageFeedbackLevel();
        const feedbackPopupData = new FeedbackPopupData(this.learningPackageId);
        const popupOptions = new PopupOptions('normal');
        void this.popupService.open<FeedbackPopupData>(FeedbackComponent, feedbackPopupData, popupOptions);
    }

    public timeFromNowToDate(otherDateRaw: string): string {
        if (otherDateRaw === undefined) {
            return '-';
        }

        const otherDate = new Date(otherDateRaw);
        const seconds = differenceInSeconds(new Date(), otherDate);
        const duration = intervalToDuration({start: 0, end: seconds * 1000});
        return `${isBefore(new Date(), otherDate) ? '' : '-'}${duration.months}m ${duration.days}d ${duration.hours}h`;
    }

    public daysToTime(days: number): string {
        const seconds = days * 24 * 60 * 60;
        const duration = intervalToDuration({start: 0, end: seconds * 1000});
        return `${duration.months}m ${duration.days}d ${duration.hours}h`;
    }

    public formatStabilityOfMemory(stabilityOfMemory: number): string {
        return stabilityOfMemory.toFixed(4);
    }

    public formatForgettingFactor(factor: number): string {
        return factor.toFixed(4);
    }
}
