import {Component, Inject, OnInit} from '@angular/core';
import {LearnAuthService} from '../../learn-auth.service';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Component({
    templateUrl: './code-exchange.component.html',
    styleUrls: ['./code-exchange.component.scss']
})
export class CodeExchangeComponent implements OnInit {
    constructor(@Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LearnAuthService) {
    }

    public ngOnInit(): void {
        void this.authService.startCodeExchangeFlow();
    }
}
