import {Component, Input} from '@angular/core';
import {Quiz} from './types/quiz';

@Component({
    selector: 'app-question-preview',
    templateUrl: './question-preview.component.html',
    styleUrls: ['./question-preview.component.scss']
})
export class QuestionPreviewComponent {
    @Input()
    public questionPreview: Quiz;

    @Input()
    public type: 'full' | 'basic' = 'basic';

    public selectedAvailableTags = [];
    public availableTags = [];

}
