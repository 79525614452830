import {Pipe, PipeTransform} from '@angular/core';
import {LocaleService} from './locale.service';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'locale'
})
export class LocalePipe implements PipeTransform {

    constructor(private localService: LocaleService, private translateService: TranslateService) {
    }

    public async transform(value: string, format: 'long' | 'short' = 'long'): Promise<string> {
        // eslint-disable-next-line no-null/no-null
        if (value === undefined || value === null) {
            return '';
        }

        if (format === 'long') {
            return this.translateService.instant(`shared.locals.${value.toLowerCase()}`) as string;
        } else {
            const allAvailableLocals = await this.localService.getAll();
            const languageData = value.split('-');
            const availableLanguage = allAvailableLocals.filter(languageInfo => languageInfo.language === languageData[0]);
            if (availableLanguage.length === 1) {
                return availableLanguage[0].language.toUpperCase();
            }
            const languageLocalInfo = availableLanguage.find(languageInfo => languageInfo.region === languageData[1]);
            return languageLocalInfo !== undefined ? `${languageLocalInfo.language.toUpperCase()} (${languageLocalInfo.region})` : availableLanguage[0].language.toUpperCase();
        }
    }
}
