import {RegistrationType} from '../registrationType';
import {RegistrationService} from '../registration.service';
import {FormGroup} from '@angular/forms';
import {RegistrationComponentFormData} from '../RegistrationComponentFormData';
import {RegistrationComponentFormDataValues} from '../RegistrationComponentFormDataValues';

import {RegistrationStrategy} from './RegistrationStrategy';
import {Result} from './Result';

export class SelfRegistrationWithOrganisationStrategy implements RegistrationStrategy {

    private orgCode: string;
    public type: RegistrationType = RegistrationType.SelfRegistrationWithOrganisation;
    public shouldShowOrganisationInput: boolean = true;
    public shouldShowConfirmation: boolean = true;
    public titleTranslationKey: string = 'registration.flows.selfregistrationwithorganisation.title';

    constructor(private registrationService: RegistrationService) {
    }

    public enableForm(formData: FormGroup<RegistrationComponentFormData>): void {
        formData.controls.email.enable();
    }

    public async initialize(formData: FormGroup<RegistrationComponentFormData>, userId: string, orgCode: string): Promise<Result> {
        const organizationNameForCodeResponseDto = await this.registrationService.exchangeOrgCodeToOrganizationName(orgCode);
        if (!organizationNameForCodeResponseDto.isValid) {
            return new Result(false, 'registration.flows.selfregistrationwithorganisation.invalidOrgCodeTitle', 'registration.flows.selfregistrationwithorganisation.invalidOrgCode');
        }

        formData.controls.organization.setValue(organizationNameForCodeResponseDto.organizationName);
        formData.controls.organization.disable();

        this.orgCode = orgCode;

        return new Result(true);
    }

    public async execute(formData: RegistrationComponentFormDataValues): Promise<Result> {
        const registrationResult = await this.registrationService.selfRegisterLearnerWithOrganisation(formData, this.orgCode);
        if (registrationResult.emailAlreadyTaken) {
            return new Result(false, 'registration.flows.selfregistrationwithorganisation.emailAlreadyTakenTitle', 'registration.flows.selfregistrationwithorganisation.emailAlreadyTaken');
        }
        if (registrationResult.domainValidationFailed) {
            return new Result(false, 'registration.flows.selfregistrationwithorganisation.domainValidationFailedTitle', 'registration.flows.selfregistrationwithorganisation.domainValidationFailed');
        }
        if (registrationResult.joinByOrgCodeDisabled) {
            return new Result(false, 'registration.flows.selfregistrationwithorganisation.joinByOrgCodeDisabledTitle', 'registration.flows.selfregistrationwithorganisation.joinByOrgCodeDisabled', true);
        }
        return new Result(true);
    }
}
