import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'mm-questions-visualization',
    templateUrl: './questions-visualization.component.html',
    styleUrls: ['./questions-visualization.component.scss']
})
export class QuestionsVisualizationComponent implements OnInit {
    @Input() levels: number[];
    @Input() neverAnsweredQuestions: number;
    @Input() numberOfQuestion: number;
    @Input() numberOfInactiveQuestions: number;

    public size = 175;
    public strokeWidth = 1.5;
    public answeredQuestions: number;
    public numberOfAvailableQuestion: number;
    public totalElements: number;
    public elementsPerLine: number;
    public squareWidth: number;

    public ngOnInit(): void {
        this.answeredQuestions = this.calculateAllElements(this.levels);
        this.numberOfAvailableQuestion = this.answeredQuestions + this.neverAnsweredQuestions;
        this.totalElements = this.numberOfAvailableQuestion + this.numberOfInactiveQuestions;
        this.elementsPerLine = this.calculateNextSquareNumber(this.totalElements);
        this.squareWidth = this.calculateSquareWidth(
            this.size,
            this.elementsPerLine,
            this.strokeWidth
        );
    }


    public calculateNextSquareNumber(n: number): number {
        return Math.floor(Math.sqrt(n)) + 1;
    }

    public calculateSquareWidth(
        fullSize: number,
        elementsPerLine: number,
        strokeWidth: number
    ): number {
        const realStrokeWidth = strokeWidth / 2;
        return (
            (fullSize - elementsPerLine * realStrokeWidth - realStrokeWidth) / elementsPerLine
        );
    }

    public calculateAllElements(levels: number[]): number {
        return levels.reduce((a, b) => a + b, 0);
    }

    public calculateXPosition(index): number {
        return this.strokeWidth / 2 + (this.squareWidth + this.strokeWidth / 2) * (index % this.elementsPerLine);
    }

    public calculateYPosition(index): number {
        return (
            this.strokeWidth / 2 + (this.squareWidth + this.strokeWidth / 2) *
            ((index - (index % this.elementsPerLine)) / this.elementsPerLine)
        );
    }

    public calculateBackgroundColor(index: number): string {
        if (this.isForInactiveQuestion(index)) {
            return '#929292';
        }
        const level = this.calculateLevel(index);
        switch (level) {
            case 0:
                return 'transparent';
            case -1:
                return '#EBEBEB';
            default:
                return `hsl(${((level / this.levels.length) * 120)}, 75%, 50%)`;
        }
    }

    private isForInactiveQuestion(index: number): boolean {
        return index >= this.numberOfAvailableQuestion && index < this.totalElements;
    }

    public calculateStrokeColor(index: number): string {
        const level = this.calculateLevel(index);
        return level === 0 ? 'transparent' : 'white';
    }

    public calculateLevel(index: number): number {
        if (index + 1 > this.answeredQuestions) {
            return index + 1 > this.totalElements ? 0 : -1;
        }
        let i = 0;
        let rest = -1 * (index + 1);
        do {
            rest = this.levels[i] + rest;
            i++;
        } while (rest < 0);
        return i;
    }
}
