import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'mm-iframe-media',
    templateUrl: './iframe-media.component.html',
    styleUrls: ['./iframe-media.component.scss']
})
export class IframeMediaComponent {
    @Input() mediaLink: string;

    @Output()
    public mediaLoaded: EventEmitter<void> = new EventEmitter<void>();

    public iframeReady(): void {
        this.mediaLoaded.emit();
    }
}
