<div class="micromate-profile-page">
    <div *ngIf="(badgesData | async) !== undefined" class="micromate-profile-badges">
        <div *ngFor="let badge of (badgesData | async)" [class.micromate-profile-badge-active]="badge.active"
             [class.micromate-profile-badge-dark-mode]="darkMode"
             class="micromate-profile-badge">
            <div class="micromate-profile-badge-card">
                <div class="micromate-profile-badge-card-photo">
                    <img alt="Badge {{badge.id}} image"
                         class="micromate-profile-badge-card-photo-img" src="{{badge.photo}}"></div>
                <div class="micromate-profile-badge-card-content">
                    <div [class.micromate-profile-badge-inactive]="!badge.active"
                         class="micromate-profile-badge-card-level">
                        <div class="micromate-profile-badge-card-level-info">
                            {{getBadgeLevelInfo(badge)}}
                        </div>
                    </div>
                    <div [class.micromate-profile-badge-inactive]="!badge.active"
                         class="micromate-profile-badge-card-description">
                        <div
                            class="micromate-profile-badge-card-description-info"> {{badge.statement}}</div>
                    </div>
                </div>
                <div
                    *ngIf="(badge.type === 'noLevel' && badge.level === 0) || (badge.type === 'multiLevel' && badge.level !== 10)"
                    class="micromate-profile-badge-card-progress">
                    <div class="micromate-profile-badge-card-progress-numbers">
                        {{badge.actualValue < 0 ? 0 : badge.actualValue}} / {{badge.nextValue}}
                    </div>
                    <div class="micromate-profile-badge-card-progress-bar-data">
                        <div class="micromate-profile-badge-card-progress-bar">
                            <div [ngStyle]="{ 'width': getBadgeProgress(badge) + '%' }"
                                 class="micromate-profile-badge-card-progress-bar-value">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
