import {Component, Inject, Input, OnInit} from '@angular/core';
import {FeedbackPopupData} from '../FeedbackPopupData';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FeedbackPopupService} from '../feedback-popup.service';
import {FEEDBACK_RESOURCE_TOKEN, FeedbackService} from '../../../services/FeedbackService';
import {CreateFeedbackRequestDto} from '../../../services/rest/api-types/CreateFeedbackRequestDto';
import {Quiz} from '../../question-preview/types/quiz';
import {Observable} from 'rxjs';

@Component({
    selector: 'mm-feedback-creation-popup',
    templateUrl: './feedback-creation-popup.component.html',
    styleUrls: ['./feedback-creation-popup.component.scss'],

})
export class FeedbackCreationPopupComponent implements OnInit {
    @Input()
    public data: FeedbackPopupData;

    public feedbackTextFormControl: FormControl<string>;
    public questionPreview$: Observable<Quiz>;

    public questionPreviewOpen: boolean = false;

    constructor(public feedbackPopupService: FeedbackPopupService,
                @Inject(FEEDBACK_RESOURCE_TOKEN) private feedbackResource: FeedbackService,
                private formBuilder: FormBuilder) {
        this.feedbackTextFormControl = this.formBuilder.control<string>('', {
            validators: [Validators.required, Validators.minLength(10), Validators.maxLength(3000)],
            nonNullable: true
        });
    }

    public ngOnInit(): void {
        if (this.isFeedbackAboutQuestion()) {
            this.questionPreview$ = this.feedbackResource.getQuestionPreview(this.data.learningPackageId, this.data.questionId);
        }
    }

    public changeQuestionPreview(): void {
        this.questionPreviewOpen = !this.questionPreviewOpen;
    }

    public async sendFeedback(): Promise<void> {
        this.feedbackPopupService.showSpinner();
        const createFeedbackRequestDto = new CreateFeedbackRequestDto(
            this.feedbackTextFormControl.getRawValue().trim(),
            this.feedbackPopupService.feedbackCategory,
            this.data.learningPackageId,
            this.isFeedbackAboutQuestion() ? this.data.questionId : undefined);
        this.feedbackResource.sendFeedback(createFeedbackRequestDto).subscribe(
            () => {
                this.feedbackPopupService.hideSpinner();
                this.feedbackPopupService.currentStep = 'result';
            },
            () => {
                this.feedbackPopupService.hideSpinner();
                this.feedbackPopupService.setHasError(true);
                this.feedbackPopupService.currentStep = 'result';
            }
        );
    }

    public comeBackToTopic(): void {
        this.feedbackPopupService.currentStep = 'topic';
    }

    public isFeedbackAboutQuestion(): boolean {
        return this.data.questionId !== undefined && this.feedbackPopupService.feedbackLevel === 'question';
    }
}
