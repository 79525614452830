<div class="mm-feedback">
    <div class="mm-feedback-spinner-element" *ngIf="feedbackPopupService.spinnerVisible$ | async">
        <mm-spinner class="mm-feedback-spinner"></mm-spinner>
    </div>

    <mm-feedback-main-topic-popup *ngIf="feedbackPopupService.currentStep === 'mainTopic'"
    ></mm-feedback-main-topic-popup>
    <mm-feedback-topic-popup *ngIf="feedbackPopupService.currentStep === 'topic'"
    ></mm-feedback-topic-popup>
    <mm-feedback-creation-popup [data]="data"
                                *ngIf="feedbackPopupService.currentStep === 'creation'">
    </mm-feedback-creation-popup>
    <mm-feedback-result-popup *ngIf="feedbackPopupService.currentStep === 'result'"
    ></mm-feedback-result-popup>
</div>
