import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TeamsContextService} from '../../core/teams-context.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {LearnerRankingResponseDto} from '../../../../../micromate-learn-lib/src/lib/types/LearnerRankingResponseDto';
import {RankingHelper} from '../../../../../micromate-learn-lib/src/lib/components/ranking/rankingHelper';
import {ScoreboardData} from '../../../../../micromate-learn-lib/src/lib/types/ScoreboardData';
import {RankingPeriod} from '../../../../../micromate-learn-lib/src/lib/types/RankingPeriod';
import {LearnerPointsResponseDto} from '../../../../../micromate-learn-lib/src/lib/types/LearnerPointsResponseDto';
import {Translation} from '../../../../../micromate-learn-lib/src/lib/services/Translation';
import {EventRecorderService} from '../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {LearnerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/learner-ressource.service';
import {ScoreboardRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/scoreboard-ressource.service';
import {TabEventType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabEventType';
import {TabRankingChangedPeriodType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabRankingChangedPeriodType';
import {app} from '@microsoft/teams-js';
import {LoggerRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/logger-ressource.service';

@Component({
    selector: 'mm-profile-scoreboard',
    templateUrl: './profile-scoreboard.component.html',
    styleUrls: ['./profile-scoreboard.component.scss']
})
export class ProfileScoreboardComponent implements OnInit, OnDestroy {
    public scoreboardData: BehaviorSubject<ScoreboardData> = new BehaviorSubject<ScoreboardData>(undefined);
    public scoreboardData$ = this.scoreboardData.asObservable();

    public organizationExistPeriod = true;
    public rankingNotActiveInLearnerOrganization = false;
    public learnerId: string;
    public activePeriod: RankingPeriod;

    public learnerPoints: LearnerPointsResponseDto;
    public scoreboard: LearnerRankingResponseDto;
    public paramsSubscription: Subscription;

    constructor(private activatedRoute: ActivatedRoute,
                public teamsContextService: TeamsContextService,
                public scoreboardService: ScoreboardRessource,
                private learnerService: LearnerRessource,
                private translate: TranslateService,
                private router: Router,
                private ref: ChangeDetectorRef,
                private titleService: Title,
                private eventRecorderService: EventRecorderService,
                private loggerService: LoggerRessource) {
        this.titleService.setTitle('Micromate Profile');
    }

    public ngOnInit(): void {
        this.loggerService.logFrontendInfoToBackend('ProfileScoreboard init');
        this.paramsSubscription = this.activatedRoute.queryParamMap.subscribe(async params => {
            if (this.learnerId !== undefined) {
                await this.subscribeOnActivePeriod(this.learnerId, params);
                await this.recordTabRankingChangedEvent(params);
            } else {
                this.teamsContextService.teamsContext$.subscribe(async teamsContext => {
                    this.learnerId = teamsContext.user.id;
                    this.loggerService.logFrontendInfoToBackend(`ProfileScoreboard ${TeamsContextService.formatContext(teamsContext)}, userId: ${this.learnerId}`);
                    if (this.learnerId === undefined) {
                        throw new Error(`UserObjectId is undefined when open ProfileScoreboard, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
                    }
                    // eslint-disable-next-line no-null/no-null
                    if (this.learnerId === null) {
                        throw new Error(`UserObjectId is null when open ProfileScoreboard, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
                    }
                    if (this.learnerId.trim().length === 0) {
                        throw new Error(`UserObjectId is empty when open ProfileScoreboard, teamsContext: ${TeamsContextService.formatContext(teamsContext)}`);
                    }
                    await this.getLearnerScoreboard(this.learnerId, params, teamsContext);
                    await this.recordTabOpenedEvent(teamsContext.page.subPageId);

                });
            }
        });
    }

    private async getLearnerScoreboard(msteamsId: string, params: ParamMap, teamsContext: app.Context): Promise<void> {
        const languageDataPromise = this.learnerService.getLearnerLanguage(msteamsId, teamsContext);
        const learnerPointsPromise = this.scoreboardService.getLearnerPoints(msteamsId);
        const languageData = await languageDataPromise;
        if (languageData === undefined) {
            throw  new Error(`Could not find language in scoreboard for msteamsId: ${msteamsId}`);
        }
        this.translate.use(new Translation().getTranslationLanguage(languageData.language));
        this.learnerPoints = await learnerPointsPromise;

        await this.subscribeOnActivePeriod(msteamsId, params);
        this.ref.detectChanges();
    }

    private async subscribeOnActivePeriod(msteamsId: string, params: ParamMap): Promise<void> {
        this.rankingNotActiveInLearnerOrganization = await this.scoreboardService.getRankingActivatedStatus(msteamsId);
        const spreadElements: ScoreboardData = {
            learnerPoints: this.learnerPoints,
            rankingNotActiveInLearnerOrganization: this.rankingNotActiveInLearnerOrganization,
            learnerId: this.learnerId
        };

        if (this.rankingNotActiveInLearnerOrganization) {
            const activePeriodInfo = params.get('period') ?? undefined;
            this.activePeriod = activePeriodInfo === undefined ? 'current-month' : activePeriodInfo as RankingPeriod;
            const scoreboardHelper = new RankingHelper();
            const period = scoreboardHelper.getPeriod(this.activePeriod);
            const date = scoreboardHelper.getPeriodDate(this.activePeriod).format('YYYY-MM-DD');
            this.scoreboard = await this.scoreboardService.getScoreboard(msteamsId, period, date);
            this.organizationExistPeriod = this.scoreboard !== undefined;


            spreadElements.activePeriod = this.activePeriod;
            spreadElements.organizationExistPeriod = this.organizationExistPeriod;
            spreadElements.scoreboard = this.scoreboard;
        }
        this.scoreboardData.next(spreadElements);
        this.ref.detectChanges();
    }

    private async recordTabOpenedEvent(subEntityId: string | { openInConversation: boolean; }): Promise<void> {
        const tabOpenedEventPayload = this.eventRecorderService.createTabOpenedEventPayload(subEntityId, this.rankingNotActiveInLearnerOrganization);
        await this.eventRecorderService.recordTabOpenedEvent(this.learnerId, TabEventType.TabRankingOpened, tabOpenedEventPayload);
    }

    private async recordTabRankingChangedEvent(params: ParamMap): Promise<void> {
        const activePeriodInfo = params.get('period') as TabRankingChangedPeriodType;
        await this.eventRecorderService.recordTabRankingChangedEvent(this.learnerId, TabEventType.TabRankingChanged, activePeriodInfo);
    }

    public ngOnDestroy(): void {
        this.loggerService.logFrontendInfoToBackend('ProfileScoreboard destroyed');
        this.paramsSubscription.unsubscribe();
    }

    public periodSelected(period: RankingPeriod): void {
        const queryParams: Params = this.learnerId === undefined ? {period} : {learnerid: this.learnerId, period};
        void this.router.navigate(['rangliste'], {queryParams});
    }
}
