<h3 class="mm-question-preview-answers-title" *ngIf="type === 'full'">{{'questionPreview.answerTitle' | translate}}</h3>
<div class="mm-answer mm-answer-correct"
     [innerHTML]="questionPreview.answer | markdown">
</div>
<ng-container *ngIf="questionPreview.answers !== undefined && questionPreview.answers.length > 0">
    <div *ngFor="let answer of getAnswers()" class="mm-answer mm-answer-correct"
         [innerHTML]="answer | markdown">
    </div>
</ng-container>

<app-explanation-question-preview
    *ngIf="questionPreviewService.shouldShowExplanation(questionPreview, type)"
    [explanation]="questionPreview.explanation"></app-explanation-question-preview>
<app-source-question-preview *ngIf="type === 'full' && questionPreview.sourceUrl !== undefined && questionPreview.sourceUrl.length > 0"
                             [sourceUrl]="questionPreview.sourceUrl"
                             [sourceLabel]="questionPreview.sourceLabel"></app-source-question-preview>
