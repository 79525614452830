import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BotatoChatManagerService, ImmediatelyRenderedMessageComponent} from 'botato-angular-lib';
import {ResponseItemDataTextService} from '../../response-item-data-text.service';

import {Subscription} from 'rxjs';
import {DeactivateMessageService} from '../../deactivate-message.service';
import {KnowledgeQuestionAnswerResponseItemData} from './KnowledgeQuestionAnswerResponseItemData';
import {KnowledgeQuestionAnswerResponseItemText} from './KnowledgeQuestionAnswerResponseItemText';
import {KnowledgeQuestionAnswerSources} from './KnowledgeQuestionAnswerSources';
import {TAB_NAVIGATION_SERVICE, TabNavigationService} from '../../../../services/TabNavigationService';
import {ResourceToDisplay} from '../components/quiz-source/resource-to-display';
import {ResourceOpenedFromType} from '../../../../services/event/ResourceOpenEventPayload';

@Component({
    selector: 'mm-knowledge-question-answer-card',
    templateUrl: './knowledge-question-answer-card.component.html',
    styleUrls: ['./knowledge-question-answer-card.component.scss']
})
export class KnowledgeQuestionAnswerCardComponent extends ImmediatelyRenderedMessageComponent implements OnInit, OnDestroy {

    public static TYPE = 'KnowledgeQuestionAnswer';
    public responseItemData: KnowledgeQuestionAnswerResponseItemData;
    public text: KnowledgeQuestionAnswerResponseItemText;
    public isDeactivated: boolean;
    public sources: KnowledgeQuestionAnswerSources[];
    public selectedDocument: string;
    public isRunningInWidget: boolean;

    private subscription: Subscription;
    private tabNavigationService: TabNavigationService;


    constructor(private responseItemDataTextService: ResponseItemDataTextService,
                injector: Injector,
                private botatoChatManagerService: BotatoChatManagerService,
                private deactivateMessageService: DeactivateMessageService) {
        super();

        this.tabNavigationService = injector.get(TAB_NAVIGATION_SERVICE);
        this.isRunningInWidget = this.tabNavigationService.isRunningInWidget();

    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        this.subscription = this.deactivateMessageService.deactivateOldCustomMessage(this.message.data);
        // data in message is can be any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        this.isDeactivated = this.message.data.deactivated !== undefined ? this.message.data.deactivated : false;
        // data in message is can be any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.responseItemData = this.message.data.responseItemData as KnowledgeQuestionAnswerResponseItemData;
        this.text = this.responseItemDataTextService.create<KnowledgeQuestionAnswerResponseItemText>(this.responseItemData.text);
        this.sources = this.responseItemData.sources;
        this.selectedDocument = this.sources[0].resourceName;
    }

    public getResource(): string {
        const resource = this.sources[0];
        return `${this.text.source}: [${resource.resourceName}](${resource.resourceUrl})`;
    }

    public getResourcesList(): string {
        const resourcesText = this.sources.map(source => `[${source.resourceName}](${source.resourceUrl})`).join(', ');
        return `${this.text.sources}: ${resourcesText}`;
    }

    public sendDocumentKey(): void {
        void this.botatoChatManagerService.sendUserMessage(this.selectedDocument, 'quickreply');
    }

    public override onAnyMessageSent(): void {
        if (!this.isDeactivated) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.message.data.deactivated = true;
        }
    }

    public showResource(source: KnowledgeQuestionAnswerSources): void {
        const resourceToDisplay: ResourceToDisplay = {
            resourceId: source.resourceId,
            selectionId: source.selectionId,
            openedFrom: ResourceOpenedFromType.QA_SOURCE
        };
        this.tabNavigationService.showResource(resourceToDisplay);
    }
}
