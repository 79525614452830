import {InjectionToken} from '@angular/core';
import {CreateFeedbackRequestDto} from './rest/api-types/CreateFeedbackRequestDto';
import {Observable} from 'rxjs';
import {FeedbackAllowRequestDto} from './rest/api-types/FeedbackAllowRequestDto';
import {Quiz} from '../components/question-preview/types/quiz';

export const FEEDBACK_RESOURCE_TOKEN = new InjectionToken<FeedbackService>('FEEDBACK_RESOURCE_TOKEN');

export interface FeedbackService {
    checkIfFeedbackAllow(feedbackAllowRequestDto: FeedbackAllowRequestDto): Observable<string>;

    sendFeedback(createFeedbackRequestDto: CreateFeedbackRequestDto): Observable<string>;

    getQuestionPreview(learningPackageId: string, questionId: string): Observable<Quiz>;

    closeFeedback(): void;
}
