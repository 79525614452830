import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UILocalizationService {

    constructor(private translationService: TranslateService) {
    }

    public async setLanguage(lang: string): Promise<void> {
        return new Promise<void>((resolve) => {
            if (this.translationService.currentLang === lang) {
                resolve();
                return;
            }

            const subscription = this.translationService.onLangChange.subscribe((event: LangChangeEvent) => {
                if (event.lang === lang) {
                    resolve();
                    subscription.unsubscribe();
                }
            });

            this.translationService.use(lang);
        });
    }

}
