import {Injectable} from '@angular/core';
import {EventRessource} from '../rest/event-ressource.service';
import {ResourceEventType, TabEventType} from './TabEventType';
import {TabOpenedEventPayload} from './TabOpenedEventPayload';
import {TabRankingChangedEventPayload} from './TabRankingChangedEventPayload';
import {TabRankingChangedPeriodType} from './TabRankingChangedPeriodType';
import {ResourceOpenedFromType, ResourceOpenEventPayload} from './ResourceOpenEventPayload';
import {EventData} from './EventData';

@Injectable({
    providedIn: 'root'
})
export class EventRecorderService {

    constructor(private eventService: EventRessource) {
    }

    public createTabOpenedEventPayload(subEntityId: string | { openInConversation: boolean; }, isRankingOpen?: boolean): TabOpenedEventPayload {
        const openInConversation = subEntityId === '' ? false : (subEntityId as { openInConversation: boolean; }).openInConversation;
        const tabOpenedEventPayload = new TabOpenedEventPayload(openInConversation);
        if (isRankingOpen !== undefined) {
            tabOpenedEventPayload.isRankingOpen = isRankingOpen;
        }
        return tabOpenedEventPayload;
    }

    public async recordTabOpenedEvent(msteamsId: string, eventType: TabEventType, tabOpenedEventPayload: TabOpenedEventPayload): Promise<string> {
        const tabEventData = new EventData(eventType, tabOpenedEventPayload);
        return await this.eventService.recordEvent(msteamsId, tabEventData);
    }

    public async recordTabOpenedEventForLearn(learnerId: string, zitadelId: string, eventType: TabEventType, tabOpenEventPayload: TabOpenedEventPayload): Promise<string> {
        if (learnerId === undefined) {
            const tabEventData = new EventData(eventType, tabOpenEventPayload);
            return await this.eventService.recordEventForLearn(zitadelId, tabEventData);
        }

        return undefined;
    }

    public createTabOpenEventPayloadForLearn(openInConversation: boolean, isRankingOpen?: boolean): TabOpenedEventPayload {
        const tabOpenedEventPayload = new TabOpenedEventPayload(openInConversation);
        if (isRankingOpen !== undefined) {
            tabOpenedEventPayload.isRankingOpen = isRankingOpen;
        }
        return tabOpenedEventPayload;
    }

    public async recordTabRankingChangedEvent(msteamsId: string, eventType: TabEventType, period: TabRankingChangedPeriodType): Promise<string> {
        const tabRankingChangedPayload = new TabRankingChangedEventPayload(period);
        const tabEventData = new EventData(eventType, tabRankingChangedPayload);
        return await this.eventService.recordEvent(msteamsId, tabEventData);
    }

    public async recordTabRankingChangedEventForLearn(learnerId: string, zitadelId: string, eventType: TabEventType, period: TabRankingChangedPeriodType): Promise<string> {
        if (learnerId === undefined) {
            const tabRankingChangedPayload = new TabRankingChangedEventPayload(period);
            const tabEventData = new EventData(eventType, tabRankingChangedPayload);
            return await this.eventService.recordEventForLearn(zitadelId, tabEventData);
        }

        return undefined;
    }

    public async recordResourceViewerOpen(zitadelId: string, openedFrom: ResourceOpenedFromType, resourceId: string, selectionId: string): Promise<string> {
        const tabEventData = new EventData(ResourceEventType.ResourceOpened, new ResourceOpenEventPayload(openedFrom, resourceId, selectionId));
        return await this.eventService.recordEventForLearn(zitadelId, tabEventData);
    }
}
