<h3 class="mm-question-preview-answers-title" *ngIf="type === 'full'">{{'questionPreview.answerTitle' | translate}}</h3>
<div *ngFor="let answer of getAnswers()" class="mm-answer"
     [class.mm-answer-correct]="answer.isCorrectAnswer"
     [class.mm-answer-wrong]="!answer.isCorrectAnswer"
     [innerHTML]="answer.answerText | markdown">
</div>
<app-explanation-question-preview
    *ngIf="questionPreviewService.shouldShowExplanation(questionPreview, type)"
    [explanation]="questionPreview.explanation"></app-explanation-question-preview>
<app-source-question-preview *ngIf="type === 'full' && questionPreview.sourceUrl !== undefined && questionPreview.sourceUrl.length > 0"
                             [sourceUrl]="questionPreview.sourceUrl"
                             [sourceLabel]="questionPreview.sourceLabel"></app-source-question-preview>
