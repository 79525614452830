export class LearnerIdQueryPrams {
    public static create(learnerId: string, zitadelId: string): string {
        if (learnerId !== undefined) {
            return `learnerid=${learnerId}`;
        }
        if (zitadelId !== undefined) {
            return `zitadelid=${zitadelId}`;
        }

        throw new Error('Cannot create request because msteamsid and learnerid are undefined');
    }
}
