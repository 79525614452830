<div class="pa-message-content micromate-message-content">
    <img [attr.src]="responseItemData.picture" class="pa-image micromate-badge-image" (load)="badgeImageLoaded()">
    <div *ngIf="responseItemData.badgeType !== 'noLevel'"
         [innerHTML]="text.levelInfo | emoji" class="micromate-badge-level"></div>
    <div [innerHTML]="text.badgeMessage | emoji" class="micromate-badge-info"></div>
    <div (click)="navigateToBadges()" class="micromate-profile-link">
        <app-icon [icon]="'medal-light'" class="micromate-profile-link-icon"></app-icon>
        {{createProfileText(text.badgeTabButton)}}
    </div>
    <div (click)="sendNextQuestion()"
         *ngIf="!isDeactivated && responseItemData.showNextQuizButton" class="micromate-question-button">
        {{text.nextQuizButtonText}}
    </div>

</div>
